define('sags-front-bo/helpers/submenu-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.testHelper = testHelper;
  function testHelper(params) {
    if (params[0] != undefined) {
      return true;
    }
    return false;
  }

  exports.default = Ember.Helper.helper(testHelper);
});