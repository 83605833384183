define('sags-front-bo/helpers/read-dictionary', ['exports', 'sags-front-bo/utils/dictionary'], function (exports, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.readDictionary = readDictionary;
  function readDictionary(params) {
    return _dictionary.default[params[0]][params[1]];
  }

  exports.default = Ember.Helper.helper(readDictionary);
});