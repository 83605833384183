define('sags-front-bo/controllers/teams/team/edit', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    columnsFilteredAgents: [{
      key: 'isChecked',
      displayName: '',
      sortable: false,
      customComponent: 'checkbox-element'
    }, {
      key: 'fullName',
      displayName: 'Agent'
    }, {
      key: 'team.identification',
      displayName: 'Equipe'
    }, {
      key: 'team.manager.fullName',
      displayName: 'Chef d\'équipe'
    }, {
      key: 'cities',
      displayName: 'Villes',
      transform: function transform(cities) {
        return cities.join(', ');
      }
    }],
    columnsSelectedAgents: [{
      key: 'isChecked',
      displayName: '',
      sortable: false,
      customComponent: 'checkbox-element'
    }, {
      key: 'fullName',
      displayName: 'Agent'
    }, {
      key: 'cities',
      displayName: 'Villes',
      transform: function transform(cities) {
        return cities.join(', ');
      }

    }, {
      key: '',
      displayName: 'Chef d\'équipe',
      sortable: false,
      customComponent: 'radiobutton-element'
    }],

    filters: {
      fullName: {
        comparator: 'contains',
        value: ''
      }
    },

    setupLineColor: function setupLineColor(agent, elementId) {
      agent.set('computedClass_' + elementId, Ember.computed('isChecked', function () {
        if (agent.get('isChecked')) {
          return 'info';
        }
        return '';
      }));
    },


    filteredAgents: Ember.computed('withoutTeam', 'allAgents', function () {
      var agents = this.get('allAgents');
      if (this.get('withoutTeam')) {
        return agents.filterBy('team.id', undefined);
      }
      return agents;
    }),

    selectedAgents: [],

    allAgents: Ember.computed.filter('agents', function (item) {
      // les users qui apparaissent sont les agents et manager de l'équipe actuelle (édition) et les agents non fermés
      return item.get('isChecked') === true || item.get('role') === 'AGENT' && item.get('status') !== 'CLOSED';
    }),

    supervisors: Ember.computed.filterBy('agents', 'role', 'SUPERVISOR'),

    actions: {
      selectSupervisor: function selectSupervisor(supervisor) {
        this.get('model').set('supervisor', supervisor);
      },
      removeAgentsFromList: function removeAgentsFromList() {
        var _this = this;

        var backAgents = this.get('selectedAgents').filterBy('isChecked', true);
        this.get('filteredAgents').addObjects(backAgents);

        backAgents.forEach(function (agent) {
          if (!_lodash.default.isNil(_this.get('selectedManager')) && _this.get('selectedManager').get('identification') === agent.get('identification')) {
            _this.set('selectedManager', null);
          }
          agent.set('isChecked', false);
          _this.get('selectedAgents').removeObject(agent);
        });
      },
      addAgentsToList: function addAgentsToList() {
        var _this2 = this;

        var pushedAgents = this.get('filteredAgents').filterBy('isChecked', true);
        this.get('selectedAgents').addObjects(pushedAgents);

        pushedAgents.forEach(function (agent) {
          agent.set('isChecked', false);
          _this2.get('filteredAgents').removeObject(agent);
        });
      }
    }
  });
});