define('sags-front-bo/components/rapo/rapo-attachments', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/utils/dictionary'], function (exports, _environment, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    pdfIcon: _environment.default.rootURL + 'assets/images/pdf.png',

    // Définitions de la table des pièces jointes
    tableColumnsAttachments: [{
      'key': 'name',
      'displayName': 'Nom pièce jointe'
    }, {
      'key': 'type',
      'displayName': 'Type pièce jointe',
      transform: function transform(type) {
        return _dictionary.default.rapoAttachmentTypes[type];
      }
    }],

    actions: {
      showAttachment: function showAttachment(isImage, img, attachment) {
        this.set('attachmentShown', attachment);
        this.set('attachmentBase64', img);
        this.set('isAttachmentModalVisible', true);
        this.set('isAttachmentImage', isImage);
      },
      closeAttachmentModal: function closeAttachmentModal() {
        this.set('attachmentShown', null);
        this.set('attachmentBase64', null);
        this.set('isAttachmentModalVisible', false);
      }
    }
  });
});