define('sags-front-bo/components/ccsp-claim/action-events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    columnsNames: [{
      'key': 'formattedDate',
      'displayName': 'Date'
    }, {
      'key': 'agent.fullName',
      'displayName': 'Acteur'
    }, {
      'key': 'actionLabel',
      'displayName': 'Action'
    }]
  });
});