define('sags-front-bo/controllers/rapo/detail', ['exports', 'lodash', 'moment', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment'], function (exports, _lodash, _moment, _dictionary, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    init: function init() {
      // pour éviter d'avoir la même instance de instructions sur toutes les instances des controlleurs
      this._super();
      this.set('instructions', []);
    },

    rapo: Ember.computed.alias('model.rapo'),

    sharedVariable: {},

    resetDecisionInstructionFields: false,

    // Définitions de la table des actions
    tableColumnsActions: [{
      'key': 'dateTime',
      'displayName': 'Date',
      transform: function transform(date) {
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm');
      }
    }, {
      'key': 'agent.fullName',
      'displayName': 'Acteur'
    }, {
      'key': 'action',
      'displayName': 'Action',
      transform: function transform(event) {
        return _dictionary.default.rapoEvent[event];
      }
    }],

    initializeNewBuyer: function initializeNewBuyer(that) {
      if (_lodash.default.isNil(that.get('rapo.newBuyer'))) {
        that.set('rapo.newBuyer', {});
      }
    },


    //Un rappo issu d'un FPS annulé par la ville sur le serveur de FPS ne peut pas être rejeté
    canValidateInstructions: true,

    // problème sur le serveur de FPS si le FPS a été chargé mais que hasRapo = false
    hasFpsServerProblem: Ember.computed('rapo.fullFps', 'rapo.fullFps.hasRapo', function () {
      return !_lodash.default.isNil(this.get('rapo.fullFps')) && !this.get('rapo.fullFps.hasRapo');
    }),

    isInstructionsEditable: Ember.computed('model.currentAgent.identification', 'rapo.status', function () {
      if (this.get('rapo.status') === 'INSTRUCTING' && this.get('rapo.lockedByAgent.identification') === this.get('model.currentAgent.identification') || this.get('rapo.status') === 'REINSTRUCTING' && this.get('instructedByAgent.identification') === this.get('model.currentAgent.identification') || this.get('rapo.status') === 'TO_RESPOND') return true;
      return false;
    }),

    isAgentLockedBy: Ember.computed('model.currentAgent.identification', 'rapo.lockedByAgent.identification', function () {
      return this.get('rapo.lockedByAgent.identification') === this.get('model.currentAgent.identification');
    }),

    isEstablishedBy: Ember.computed('model.currentAgent.authorizedNumber', 'rapo.fullFps.agent.authorizedNumber', function () {
      return this.get('model.currentAgent.authorizedNumber') === this.get('rapo.fullFps.agent.authorizedNumber');
    }),

    isInstructor: Ember.computed('model.currentAgent.identification', 'instructedByAgent', function () {
      return this.get('instructedByAgent.identification') === this.get('model.currentAgent.identification');
    }),

    isSupervisor: Ember.computed('model.currentAgent.role', function () {
      return this.get('model.currentAgent.role') === 'SUPERVISOR';
    }),

    isAgentAuthorized: Ember.computed('model.currentAgent.authorizedNumber', function () {
      return this.get('model.currentAgent.authorizedNumber') !== null;
    }),

    isMandatary: Ember.computed('rapo.mandataryCivility', function () {
      return !_lodash.default.isNil(this.get('rapo.mandataryCivility'));
    }),

    instructedByAgent: Ember.computed('rapo.actionEvents.@each', function () {
      var agent = null;
      this.get('rapo.actionEvents').sortBy('dateTime').forEach(function (a) {
        if (a.get('action') === 'START_INSTRUCTION') {
          agent = a.get('agent');
        }
      });
      return agent;
    }),

    inInstruction: Ember.computed('rapo.status', 'isAgentLockedBy', 'isInstructor', function () {
      return (this.get('rapo.status') === 'INSTRUCTING' || this.get('rapo.status') === 'REINSTRUCTING') && (this.get('isAgentLockedBy') || this.get('isInstructor'));
    }),

    isEnableValidateOrRejectInstruction: Ember.computed('rapo.status', 'isEstablishedBy', 'isInstructor', 'isAgentAuthorized', 'doubleValidationEnabled', function () {
      if (this.get('doubleValidationEnabled')) {
        return this.get('rapo.status') === 'TO_VALIDATE' && this.get('isAgentAuthorized') && !this.get('isInstructor') && !this.get('isEstlablishedBy');
      }
      return this.get('rapo.status') === 'TO_VALIDATE' && this.get('isAgentAuthorized') && !this.get('isEstablishedBy');
    }),

    isDisableAgentComment: Ember.computed('rapo.status', 'isAgentLockedBy', 'isInstructor', 'isEnableValidateOrRejectInstruction', function () {
      var status = this.get('rapo.status');
      return !((status === 'INSTRUCTING' || status === 'REINSTRUCTING') && (this.get('isAgentLockedBy') || this.get('isInstructor')) || this.get('isEnableValidateOrRejectInstruction'));
    }),

    actions: {
      saveRapo: function saveRapo(action, status) {
        var _this = this;

        var newEvent = void 0;
        if (action !== null) {
          newEvent = this.store.createRecord('rapo-action-event', {
            'action': action,
            'dateTime': new Date().toISOString(),
            'agent': this.get('currentUser.user'),
            'rapo': this.get('rapo')
          });
          this.set('rapo.lockedByAgent', action === 'START_INSTRUCTION' ? this.get('model.currentAgent') : null);
          this.set('rapo.status', status);
          this.get('rapo.actionEvents').pushObject(newEvent);
        }

        this.get('rapo').save().then(function () {
          if (action !== null) {
            newEvent.rollbackAttributes();
          }
          _this.get('notifications').success('RAPO mis à jour');
          _this.set('loadedCorrectiveFps', true);
        }).catch(function () {
          _this.get('rapo').rollbackAttributes();
          _this.get('notifications').error('Erreur de mise à jour du RAPO');
        }).finally(function () {
          _this.set('isLoadingRapo', false);
          _this.set('isLoadingAcceptRapo', false);
          _this.set('isLoadingRejectRapo', false);
          _this.set('isLoadingValidateInstruction', false);
          _this.set('isLoadingDeclineInstruction', false);
        });
      },
      startInstruction: function startInstruction(newRapoStatus) {
        var _this2 = this;

        var workflowProcessId = this.get('rapo.workflowProcessId');

        if (_lodash.default.isNil(workflowProcessId)) {
          this.set('instructions', []);
          this.set('isLoadingNextInstructionTask', true);

          // Aucun instruction n'existe pour ce RAPO. On en initie un nouveau, puis met à jour le statut du RAPO si tout s'est bien passé
          this.get('ajax').request(_environment.default.APP.host + '/rapo/api/workflow/start', {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
              City: this.get('currentUser.city')
            },
            contentType: 'application/json'
          }).then(function (response) {
            _this2.set('rapo.workflowProcessId', response.processInstanceId);
            _this2.get('instructions').pushObject(response.nextTask);

            _this2.send('saveRapo', 'START_INSTRUCTION', newRapoStatus);
            _this2.set('resetDecisionInstructionFields', true);

            window.scrollTo(0, document.body.scrollHeight);
          }).catch(function () {
            _this2.get('notifications').error('Erreur lors du chargement du questionnaire d\'instruction');
          }).finally(function () {
            _this2.set('isLoadingNextInstructionTask', false);
          });
        } else {
          // Une instruction est déjà en cours, on met juste à jour le statut du RAPO
          this.send('saveRapo', 'START_INSTRUCTION', newRapoStatus);
        }
      },
      openCcspClaimForm: function openCcspClaimForm(fpsLegalId) {
        this.transitionToRoute('ccsp-claim.create', { queryParams: { legalId: fpsLegalId } });
      }
    }
  });
});