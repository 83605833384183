define('sags-front-bo/routes/ccsp-claim/detail', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/dictionary', 'lodash'], function (exports, _accessController, _dictionary, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),

    findOrReload: function findOrReload(id) {
      var peeked = this.store.peekRecord('ccsp-claim', id);
      return peeked ? peeked.reload().then(function (claim) {
        return Ember.RSVP.hash({
          claim: claim,
          actions: claim.get('actions').filter(function (action) {
            return !action.get('isNew');
          }),
          comments: claim.get('comments').filter(function (comment) {
            return !comment.get('isNew');
          })
        }).then(function (data) {
          data.claim.set('actions', data.actions);
          data.claim.set('comments', data.comments);
          return data.claim;
        });
      }) : this.store.findRecord('ccsp-claim', id);
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        ccspClaim: this.findOrReload(params.id),
        fps: Ember.Object.create({})
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      // On récupère le fps initial
      this.store.queryRecord('fps', { externalId: model.ccspClaim.get('fpsExternalId') }).then(function (fps) {
        return model.fps.set('fullFps', fps);
      }).catch(function () {
        model.fps.set('fullFps', null);
        _this.get('notifications').error('Erreur de chargement du FPS lié au recours CCSP');
      });

      // On récupère les settings (zones tarifaires et secteurs résident)
      _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        controller.set('zonesFps', _dictionary.default.convertToArray(settings.tariffZonesFps));
        controller.set('sectors', _dictionary.default.convertToArray(settings.residentialSectors));
        controller.set('ccspWebSiteUrl', settings.ccspWebSiteUrl);
      });

      controller.set('defenseMemoryAttachment', _lodash.default.filter(model.ccspClaim.get('attachments'), { type: 'DEFENSE_MEMORY' }));
      controller.set('decisionAttachments', _lodash.default.filter(model.ccspClaim.get('attachments'), { type: 'DECISION' }));
      controller.set('otherAttachemnts', _lodash.default.filter(model.ccspClaim.get('attachments'), { type: 'ANNEX_DOCUMENT' }));
    }
  });
});