define('sags-front-bo/routes/rapo/instruction', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/dictionary', 'sags-front-bo/constants/message', 'sags-front-bo/config/environment', 'lodash', 'ember-ajax/errors'], function (exports, _accessController, _dictionary, _message, _environment, _lodash, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {
      var _this = this;

      this.set('isLoadingRapo', true);
      var currentUser = this.get('currentUser.user');
      var url = _environment.default.APP.host + '/rapo/api/rapos/oldestRapoInstructing';
      return this.get('ajax').request(url, {
        method: 'GET',
        data: {
          agentId: currentUser.get('id'),
          agentAuthorizedNumber: currentUser.get('authorizedNumber')
        },
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city')
        },
        contentType: 'application/json'
      }).catch(function (reason) {
        return _this.set('globalErrorMessage', reason);
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      // On recupère les setting pour la double validation
      _dictionary.default.fetchSettingsFromRapo(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        controller.set('doubleValidationEnabled', settings.doubleValidationEnabled);
      });

      // Il y a eu une erreur serveur
      if ((0, _errors.isConflictError)(model)) {
        this.set('isLoadingRapo', false);
        controller.set('globalErrorMessage', _message.default.ERROR_SERVER);
      }
      if ((0, _errors.isServerError)(model)) {
        if (_lodash.default.get(model, 'payload.message') === 'error.external.api') {
          this.set('isLoadingRapo', false);
          this.set('globalErrorMessage', _message.default.ERROR_API_EXERNAL);
        } else {
          this.set('isLoadingRapo', false);
          controller.set('globalErrorMessage', _message.default.ERROR_SERVER);
        }
      }

      if (_lodash.default.isNil(model) || (0, _errors.isNotFoundError)(model)) {
        this.get('notifications').success('Aucun RAPO à instruire');
        this.set('isLoadingRapo', false);
        return;
      }
      if (!_lodash.default.isNil(model.lockedByAgent)) {
        controller.transitionToRoute('rapo.detail', model.number);
      }
    }
  });
});