define('sags-front-bo/controllers/rapo/rattrap', ['exports', 'lodash', 'sags-front-bo/config/environment', 'sags-front-bo/utils/dictionary'], function (exports, _lodash, _environment, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    currentUser: Ember.inject.service('current-user'),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    //Liste des variables
    filters: {
      number: {
        comparator: 'contains',
        value: ''
      }
    },

    btn: {
      active: {
        comparator: "equals",
        value: false
      },
      activeapp: {
        comparator: "equals",
        value: false
      }
    },

    condition: {
      type: {
        comparator: 'equals',
        value: 0
      }
    },

    buyer: {
      gender: {
        comparator: 'equals',
        value: ''
      },
      givenName: {
        comparator: 'equals',
        value: ''
      },
      familyName: {
        comparator: 'equals',
        value: ''
      },
      address: {
        streetNumber: {
          comparator: 'equals',
          value: ''
        },
        streetNumberBis: {
          comparator: 'equals',
          value: ''
        },
        streetType: {
          comparator: 'equals',
          value: ''
        },
        streetName: {
          comparator: 'equals',
          value: ''
        },
        postalCode: {
          comparator: 'equals',
          value: ''
        },
        addressLocality: {
          comparator: 'equals',
          value: ''
        },
        addressCountry: {
          comparator: 'equals',
          value: ''
        }
      }
    },

    save: {
      rapoNumber: {
        comparator: 'equals',
        value: ''
      }
    },
    div: {
      hidden: {
        comparator: 'equals',
        value: 'display: none;' //inline-block pour afficher
      }
    },
    div2: {
      hidden: {
        comparator: 'equals',
        value: 'display: none;' //inline-block pour afficher
      }
    },
    div3: {
      hidden: {
        comparator: 'equals',
        value: 'display: none;' //inline-block pour afficher
      }
    },
    popInfo: {
      text: {
        comparator: 'equals',
        value: 'Erreur : Patch impossible. \nLes données renseignés ne correspondent pas à un problème connus.'
      }
    },

    rapoInfo: {
      numero: {
        comparator: 'contains',
        value: ''
      },
      id: {
        comparator: 'equals',
        value: ''
      },
      plaque: {
        comparator: 'contains',
        value: ''
      },
      date: {
        comparator: 'equals',
        value: '' //aaaa-mm-jj
      },
      datedTime: {
        comparator: 'equals',
        value: ''
      },
      issue: {
        comparator: 'equals',
        value: ''
      },
      status: {
        comparator: 'equals',
        value: ''
      }
    },

    fpsCorrectif: {
      statementAddress: {
        comparator: 'contains',
        value: ''
      },
      surcharge: {
        comparator: 'equals',
        value: 0
      },
      price: {
        comparator: 'equals',
        finePrice: 0,
        reducedFinePrice: 0
      },
      vehicle: {
        comparator: 'contains',
        value: ''
      },
      statementDatetime: {
        comparator: 'equals',
        value: ''
      },
      validityDatetime: {
        comparator: 'equals',
        value: ''
      },
      terminalId: {
        comparator: 'equals',
        value: ''
      },
      claims: {
        comparator: 'equals',
        value: ''
      },
      numero: {
        comparator: 'contains',
        value: ''
      },
      plaque: {
        comparator: 'contains',
        value: ''
      },
      date: {
        comparator: 'equals',
        value: ''
      },
      reason: {
        comparator: 'equals',
        value: ''
      },
      status: {
        comparator: 'equals',
        value: ''
      },
      type: {
        comparator: 'equals',
        value: ''
      },
      claimtype: {
        comparator: 'equals',
        value: ''
      },
      check: {
        comparator: 'equals',
        value: false
      }
    },
    fpsCorrectif2: {
      statementAddress: {
        comparator: 'contains',
        value: ''
      },
      surcharge: {
        comparator: 'equals',
        value: 0
      },
      price: {
        comparator: 'equals',
        finePrice: 0,
        reducedFinePrice: 0
      },
      vehicle: {
        comparator: 'contains',
        value: ''
      },
      statementDatetime: {
        comparator: 'equals',
        value: ''
      },
      validityDatetime: {
        comparator: 'equals',
        value: ''
      },
      terminalId: {
        comparator: 'equals',
        value: ''
      },
      claims: {
        comparator: 'equals',
        value: ''
      },
      numero: {
        comparator: 'contains',
        value: ''
      },
      plaque: {
        comparator: 'contains',
        value: ''
      },
      date: {
        comparator: 'equals',
        value: ''
      },
      reason: {
        comparator: 'equals',
        value: ''
      },
      status: {
        comparator: 'equals',
        value: ''
      },
      type: {
        comparator: 'equals',
        value: ''
      },
      claimtype: {
        comparator: 'equals',
        value: ''
      },
      check: {
        comparator: 'equals',
        value: false
      }
    },

    fpsInfo: {
      numero: {
        comparator: 'contains',
        value: ''
      },
      surcharge: {
        comparator: 'equals',
        value: 0
      },
      price: {
        comparator: 'equals',
        finePrice: 0,
        reducedFinePrice: 0
      },
      plaque: {
        comparator: 'contains',
        value: ''
      },
      date: {
        comparator: 'equals',
        value: '',
        datedTime: ''
      },
      datedTime: {
        comparator: 'equals',
        value: ''
      },
      reason: {
        comparator: 'equals',
        value: ''
      },
      status: {
        comparator: 'equals',
        value: ''
      },
      type: {
        comparator: 'equals',
        value: ''
      },
      claimtype: {
        comparator: 'equals',
        value: ''
      },
      check: {
        comparator: 'equals',
        value: false
      }
    },

    /**
    * gestion des notifications d'erreur
    **/
    traitementErreur: function traitementErreur(erreurMSG) {
      var errors = erreurMSG.toString().split(" ");
      if (errors[8] != null) {
        this.get('notifications').error(_dictionary.default.httpError[errors[8].substring(0, 3)]);
      } else if (erreurMSG == "Error: Request was rejected due to server error") {
        this.get('notifications').error("Une erreur du serveur est survenu");
      } else if (erreurMSG == "Error: Resource was not found.") {
        this.get('notifications').error("Donnée inexistante ou le contenu de votre recherche n'est pas bon");
      } else {
        this.get('notifications').error(erreurMSG);
      }
    },
    loadFPS: function loadFPS(response) {
      var _this = this;

      //On récupère le/les FPS
      var fpsLidPresta = response.fpsLegalId;

      this.get('ajax').request(_environment.default.APP.host + '/control/api/fps/getAllFps/?rootlegalidfps=' + fpsLidPresta.toString(), {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city')
        },
        contentType: 'application/json'
      }).then(function (data) {
        if (data.length == 1) {
          _this.set('fpsInfo.numero.value', data[0].fineLegalId);
          var date2 = data[0].dateModified.substring(0, 10);
          _this.set('fpsInfo.date.value', date2);
          _this.set('fpsInfo.datedTime.value', data[0].statementDatetime);
          _this.set('fpsInfo.plaque.value', data[0].licensePlate.plate);
          if (data[0].claims && data[0].claims.length != 0) {
            _this.set('fpsInfo.status.value', data[0].claims[0].claimStatus);
            _this.set('fpsInfo.reason.value', data[0].claims[0].claimReason);
            _this.set('fpsInfo.claimtype.value', data[0].claims[0].claimType);
          } else {
            _this.set('fpsInfo.status.value', "pas de claims");
            _this.set('fpsInfo.reason.value', "pas de claims");
            _this.set('fpsInfo.claimtype.value', "pas de claims");
          }
          _this.set('fpsInfo.type.value', data[0].type);
          _this.set('fpsInfo.price.finePrice', data[0].finePrice);
          _this.set('fpsInfo.price.reducesFinePrice', data[0].reducedFinePrice);
          _this.set('fpsInfo.surcharge.value', data[0].surcharge);
          if (response.correctiveFps != null) {
            _this.set('fpsInfo.check.value', true);
          } else {
            _this.set('fpsInfo.check.value', false);
          }
        } else if (data.length == 2) {
          _this.set('fpsInfo.numero.value', data[0].fineLegalId);
          var _date = data[0].dateModified.substring(0, 10);
          _this.set('fpsInfo.date.value', _date);
          _this.set('fpsInfo.datedTime.value', data[0].statementDatetime);
          _this.set('fpsInfo.plaque.value', data[0].licensePlate.plate);
          if (data[0].claims && data[0].claims.length != 0) {
            _this.set('fpsInfo.claimtype.value', data[0].claims[0].claimType);
            _this.set('fpsInfo.status.value', data[0].claims[0].claimStatus);
            _this.set('fpsInfo.reason.value', data[0].claims[0].claimReason);
          } else {
            _this.set('fpsInfo.status.value', "pas de claims");
            _this.set('fpsInfo.reason.value', "pas de claims");
            _this.set('fpsInfo.claimtype.value', "pas de claims");
          }
          _this.set('fpsInfo.type.value', data[0].type);
          _this.set('fpsInfo.price.finePrice', data[0].finePrice);
          _this.set('fpsInfo.price.reducesFinePrice', data[0].reducedFinePrice);
          _this.set('fpsInfo.surcharge.value', data[0].surcharge);

          _this.set('div2.hidden.value', 'display: inline-solid;');
          _this.set('fpsCorrectif.numero.value', data[1].fineLegalId);
          var date3 = data[1].dateModified.substring(0, 10);
          _this.set('fpsCorrectif.date.value', date3);
          _this.set('fpsCorrectif.plaque.value', data[1].licensePlate.plate);
          if (data[1].claims && data[1].claims.length != 0) {
            _this.set('fpsCorrectif.claimtype.value', data[1].claims[0].claimType);
            _this.set('fpsCorrectif.status.value', data[1].claims[0].claimStatus);
            _this.set('fpsCorrectif.reason.value', data[1].claims[0].claimReason);
          } else {
            _this.set('fpsCorrectif.status.value', "pas de claims");
            _this.set('fpsCorrectif.reason.value', "pas de claims");
            _this.set('fpsCorrectif.claimtype.value', "pas de claims");
          }
          _this.set('fpsCorrectif.type.value', data[1].type);
          _this.set('fpsCorrectif.price.finePrice', data[1].finePrice);
          _this.set('fpsCorrectif.price.reducesFinePrice', data[1].reducedFinePrice);
          _this.set('fpsCorrectif.surcharge.value', data[1].surcharge);
          if (response.correctiveFps != null) {
            _this.set('fpsCorrectif.check.value', true);
            _this.set('fpsInfo.check.value', false);
          } else {
            _this.set('fpsInfo.check.value', false);
            _this.set('fpsCorrectif.check.value', false);
          }
        } else {
          _this.set('fpsInfo.numero.value', data[0].fineLegalId);
          var _date2 = data[0].dateModified.substring(0, 10);
          _this.set('fpsInfo.date.value', _date2);
          _this.set('fpsInfo.datedTime.value', data[0].statementDatetime);
          _this.set('fpsInfo.plaque.value', data[0].licensePlate.plate);
          if (data[0].claims && data[0].claims.length != 0) {
            _this.set('fpsInfo.claimtype.value', data[0].claims[0].claimType);
            _this.set('fpsInfo.status.value', data[0].claims[0].claimStatus);
            _this.set('fpsInfo.reason.value', data[0].claims[0].claimReason);
          } else {
            _this.set('fpsInfo.status.value', "pas de claims");
            _this.set('fpsInfo.reason.value', "pas de claims");
            _this.set('fpsInfo.claimtype.value', "pas de claims");
          }
          _this.set('fpsInfo.type.value', data[0].type);
          _this.set('fpsInfo.price.finePrice', data[0].finePrice);
          _this.set('fpsInfo.price.reducesFinePrice', data[0].reducedFinePrice);
          _this.set('fpsInfo.surcharge.value', data[0].surcharge);

          _this.set('div2.hidden.value', 'display: inline-solid;');
          _this.set('fpsCorrectif.numero.value', data[1].fineLegalId);
          var _date3 = data[1].dateModified.substring(0, 10);
          _this.set('fpsCorrectif.date.value', _date3);
          _this.set('fpsCorrectif.plaque.value', data[1].licensePlate.plate);
          if (data[1].claims && data[1].claims.length != 0) {
            _this.set('fpsCorrectif.claimtype.value', data[1].claims[0].claimType);
            _this.set('fpsCorrectif.status.value', data[1].claims[0].claimStatus);
            _this.set('fpsCorrectif.reason.value', data[1].claims[0].claimReason);
          } else {
            _this.set('fpsCorrectif.status.value', "pas de claims");
            _this.set('fpsCorrectif.reason.value', "pas de claims");
            _this.set('fpsCorrectif.claimtype.value', "pas de claims");
          }
          _this.set('fpsCorrectif.type.value', data[1].type);
          _this.set('fpsCorrectif.price.finePrice', data[1].finePrice);
          _this.set('fpsCorrectif.price.reducesFinePrice', data[1].reducedFinePrice);
          _this.set('fpsCorrectif.surcharge.value', data[1].surcharge);

          if (data[2].claims[0] != null) {
            _this.set('div3.hidden.value', 'display: inline-solid;');
            _this.set('fpsCorrectif2.numero.value', data[2].fineLegalId);
            var date4 = data[2].dateModified.substring(0, 10);
            _this.set('fpsCorrectif2.date.value', date4);
            _this.set('fpsCorrectif2.plaque.value', data[2].licensePlate.plate);
            if (data[2].claims && data[2].claims.length != 0) {
              _this.set('fpsCorrectif2.claimtype.value', data[2].claims[0].claimType);
              _this.set('fpsCorrectif2.status.value', data[2].claims[0].claimStatus);
              _this.set('fpsCorrectif2.reason.value', data[2].claims[0].claimReason);
            } else {
              _this.set('fpsCorrectif2.status.value', "pas de claims");
              _this.set('fpsCorrectif2.reason.value', "pas de claims");
              _this.set('fpsCorrectif2.claimtype.value', "pas de claims");
            }
            _this.set('fpsCorrectif2.type.value', data[2].type);
            _this.set('fpsCorrectif2.price.finePrice', data[2].finePrice);
            _this.set('fpsCorrectif2.price.reducesFinePrice', data[2].reducedFinePrice);
            _this.set('fpsCorrectif2.surcharge.value', data[2].surcharge);
          } else if (data[3] != null) {
            _this.set('div3.hidden.value', 'display: inline-solid;');
            _this.set('fpsCorrectif2.numero.value', data[3].fineLegalId);
            var _date4 = data[3].dateModified.substring(0, 10);
            _this.set('fpsCorrectif2.date.value', _date4);
            _this.set('fpsCorrectif2.plaque.value', data[3].licensePlate.plate);
            if (data[3].claims && data[3].claims.length != 0) {
              _this.set('fpsCorrectif2.claimtype.value', data[3].claims[0].claimType);
              _this.set('fpsCorrectif2.status.value', data[3].claims[0].claimStatus);
              _this.set('fpsCorrectif2.reason.value', data[3].claims[0].claimReason);
            } else {
              _this.set('fpsCorrectif2.status.value', "pas de claims");
              _this.set('fpsCorrectif2.reason.value', "pas de claims");
              _this.set('fpsCorrectif2.claimtype.value', "pas de claims");
            }
            _this.set('fpsCorrectif2.type.value', data[3].type);
            _this.set('fpsCorrectif2.price.finePrice', data[3].finePrice);
            _this.set('fpsCorrectif2.price.reducesFinePrice', data[3].reducedFinePrice);
            _this.set('fpsCorrectif2.surcharge.value', data[3].surcharge);
          }

          if (response.correctiveFps != null) {
            _this.set('fpsCorrectif.check.value', true);
            _this.set('fpsCorrectif2.check.value', true);
            _this.set('fpsInfo.check.value', false);
          } else {
            _this.set('fpsInfo.check.value', false);
            _this.set('fpsCorrectif.check.value', false);
            _this.set('fpsCorrectif2.check.value', false);
          }
        }
        _this.set('isLoadingRapo', false);
      }).catch(function (errorMSG) {
        _this.set('isLoadingRapo', false);
        _this.traitementErreur(errorMSG);
      });
      this.set('div.hidden.value', 'display: inline-solid;');
    },
    loadRapo: function loadRapo() {
      var _this2 = this;

      this.set('isLoadingRapo', true);
      var numeroRAPO = this.get('filters.number.value'); //récupère le numéro du Rapo
      this.set('save.rapoNumber.value', numeroRAPO);

      // on utilise la méthode getRapoByNumber() pour récupéré un rapoDTO et utiliser ses données pour remplir le formulaire
      this.get('ajax').request(_environment.default.APP.host + '/rapo/api/rapos/?number=' + numeroRAPO, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city')
        },
        contentType: 'application/json'
      }).then(function (response) {
        _this2.set('rapoInfo.numero.value', response.fpsLegalId);
        var date = response.date.substring(0, 10);
        _this2.set('rapoInfo.datedTime.value', response.date);
        _this2.set('rapoInfo.date.value', date);
        _this2.set('rapoInfo.plaque.value', response.vehiclePlate);
        _this2.set('rapoInfo.id.value', response.id);

        if (response.issue != null) {
          _this2.set('rapoInfo.issue.value', response.issue);
        } else {
          _this2.set('rapoInfo.issue.value', 'Pas d\'issue');
        }

        _this2.set('rapoInfo.status.value', response.status);

        if (response.newBuyer != null) {
          _this2.set('buyer.givenName.value', response.newBuyer.firstName);
          _this2.set('buyer.familyName.value', response.newBuyer.lastName);
          _this2.set('buyer.address.streetNumber.value', response.newBuyer.streetNumber);
          _this2.set('buyer.address.streetNumberBis.value', response.newBuyer.streetNumberBis);
          _this2.set('buyer.address.streetType.value', response.newBuyer.streetType);
          _this2.set('buyer.address.streetName.value', response.newBuyer.streetName);
          _this2.set('buyer.address.postalCode.value', response.newBuyer.zipCode);
          _this2.set('buyer.address.addressLocality.value', response.newBuyer.city);
          _this2.set('buyer.address.addressCountry.value', response.newBuyer.country);
          var gender = '';
          if (response.claimerCivility != null) {
            if (response.newBuyer.civility == 'COM') {
              gender = 'Société';
            } else if (response.newBuyer.civility == 'MR') {
              gender = 'MALE';
            } else if (response.newBuyer.civility == 'MRS') {
              gender = 'FEMALE';
            }
          } else {
            gender = null;
          }
          _this2.set('buyer.gender.value', gender);
        }
        _this2.loadFPS(response);
      }).catch(function (errorMSG) {
        _this2.set('isLoadingRapo', false);
        _this2.traitementErreur(errorMSG);
      });
    },


    /**
    * Patch le FPS avec les données présentes sur le RAPO (requête 1 de rattrapage RAPO)
    **/
    patchFPS: function patchFPS() {
      var _this3 = this;

      this.set('isLoadingRapo', true);
      var claimreas = this.get('fpsInfo.reason.value');
      var date = this.get('fpsInfo.datedTime.value');
      var lid = this.get('fpsInfo.numero.value');
      this.get('ajax').request(_environment.default.APP.host + '/control/api/fps/patch/?claimR=' + claimreas + '&dateC=' + date + '&legalid=' + lid, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city')
        },
        contentType: 'application/json'
      }).then(function (response) {
        _this3.set('fpsInfo.numero.value', response.fineLegalId);
        var date2 = response.dateModified.substring(0, 10);
        _this3.set('fpsInfo.date.value', date2);
        _this3.set('fpsInfo.plaque.value', response.licensePlate.plate);
        _this3.set('fpsInfo.claimtype.value', response.claims[0].claimType);
        _this3.set('fpsInfo.type.value', response.type);
        _this3.set('fpsInfo.status.value', response.claims[0].claimStatus);
        _this3.set('fpsInfo.reason.value', response.claims[0].claimReason);
        if (response.correctiveFps != null) {
          _this3.set('fpsInfo.check.value', true);
        } else {
          _this3.set('fpsInfo.check.value', false);
        }
        _this3.set("btn.active.value", false);
        _this3.get('notifications').success('Le Rattrapage a bien été effectué !!');
        _this3.loadRapo();
      }).catch(function (errorMSG) {
        _this3.set("btn.active.value", false);
        _this3.traitementErreur(errorMSG);
        _this3.loadRapo();
      });
    },


    /**
    * Mise à jour du RAPO dans la base de données en récupérant les informations de l'ancien fps correctif pour créer le nouveau (requête 2 du rattrapage RAPO)
    **/
    majRAPO: function majRAPO() {
      var _this4 = this;

      this.set('isLoadingRapo', true);

      var indexNumber = null;
      this.get('currentUser.user.userCities').forEach(function (userCity) {
        if (userCity.city.name === _this4.get('currentUser.city')) {
          indexNumber = userCity.indexNumber;
        }
      });

      this.get('ajax').request(_environment.default.APP.host + '/control/api/fps/createCorrectiveFps/amount/noPrice/?legalId=' + this.get('fpsInfo.numero.value') + '&rapoId=' + this.get('rapoInfo.id.value'), { //lien vers la requete de patch
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city'),
          stat: this.get('payload.status')
        },
        data: {
          agentId: this.get('currentUser.user.identification'),
          agentIndex: indexNumber,
          agentName: this.get('currentUser.user.fullName'),
          agentAutId: this.get('currentUser.user.authorizedNumber'),
          rapoIssue: this.get('rapoInfo.issue.value')
        }, contentType: 'application/json'
      }).then(function (rep) {
        var numberRep = _this4.get('save.rapoNumber.value');
        var exteridRep = rep.correctiveFps.externalId;

        var legalIdRep = rep.correctiveFps.legalId;
        var VehiculePlateRep = rep.correctiveFps.vehiclePlate;
        var VehiclePlateCountryRep = rep.correctiveFps.vehiclePlateCountry;
        var VehicleBrandRep = rep.correctiveFps.vehicleBrand;
        var ValidityDateRep = rep.correctiveFps.validityDate;
        var AmountRep = rep.correctiveFps.amount;
        var reducedAmountRep = rep.newBuyerFps.reducedAmount;
        var DeducRep = rep.correctiveFps.deductionTotal;
        var StreetNameRep = rep.correctiveFps.streetName;

        var VehicleModelRep = rep.correctiveFps.vehicleModel;
        var agentIdRep = rep.correctiveFps.agent.identification;
        var agentNameRep = rep.correctiveFps.agent.name;
        var agentAutIdRep = rep.correctiveFps.agent.authorizedNumber;
        var agentIdNRep = rep.correctiveFps.agent.indexNumber;
        var residSecRep = rep.correctiveFps.residentialSector;
        var tariffZoneRep = rep.correctiveFps.tariffZone;
        var streetNRep = rep.correctiveFps.streetNumber;
        var streetNBisRep = rep.correctiveFps.streetNumberBis;
        var streetTypeRep = rep.correctiveFps.streetType;

        _this4.get('ajax').request(_environment.default.APP.host + '/rapo/api/majRAPO', { //lien vers la requete de patch
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + _this4.get('session.session.authenticated.id_token'),
            City: _this4.get('currentUser.city')
          },
          dataType: "json",
          data: JSON.stringify({
            "number": numberRep.toString(),
            "exterid": exteridRep.toString(),
            "legalid": legalIdRep.toString(),
            "VehiculePlate": VehiculePlateRep.toString(),
            "VehiclePlateCountry": VehiclePlateCountryRep.toString(),
            "VehicleBrand": VehicleBrandRep.toString(),
            "ValidityDate": ValidityDateRep.toString(),
            "Amount": AmountRep.toString(),
            "reducedAmount": reducedAmountRep.toString(),
            "Deduc": DeducRep.toString(),
            "StreetName": StreetNameRep.toString(),
            "VehicleModel": VehicleModelRep.toString(),
            "agentId": agentIdRep.toString(),
            "agentName": agentNameRep.toString(),
            "agentAutId": agentAutIdRep.toString(),
            "agentIdN": agentIdNRep.toString(),
            "residSec": residSecRep.toString(),
            "tariffZone": tariffZoneRep.toString(),
            "streetN": streetNRep.toString(),
            "streetNBis": streetNBisRep.toString(),
            "streetType": streetTypeRep.toString()
          }),
          contentType: 'application/json'
        }).then(function () {
          _this4.set("btn.active.value", false);
          _this4.get('notifications').success('Le Rattrapage a bien été effectué !!');
          _this4.loadRapo();
        }).catch(function (errorMSG) {
          _this4.set("btn.active.value", false);
          _this4.traitementErreur(errorMSG);
          _this4.loadRapo();
        });
      }).catch(function (errorMSG) {
        _this4.set("btn.active.value", false);
        _this4.traitementErreur(errorMSG);
        _this4.loadRapo();
      });
    },


    /**
    * Cas de cession de véhicule (véhicule vendu ou volé).
    * Créé un fps de 0€ pour l'ancien propriétaire et créé un fps avec le prix (variant selon la zone) pour le nouveau propriétaire du véhicule
    **/
    cessionVehicule: function cessionVehicule() {
      var _this5 = this;

      this.set('isLoadingRapo', true);
      var indexNumber = null;
      this.get('currentUser.user.userCities').forEach(function (userCity) {
        if (userCity.city.name === _this5.get('currentUser.city')) {
          indexNumber = userCity.indexNumber;
        }
      });
      //Créé le fps correctif de 0€
      this.get('ajax').request(_environment.default.APP.host + '/control/api/fps/createCorrectiveFps/amount/noPrice/?legalId=' + this.get('fpsInfo.numero.value') + '&rapoId=' + this.get('rapoInfo.id.value'), { //lien vers la requete de patch
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city'),
          stat: this.get('payload.status')
        }, data: {
          agentId: this.get('currentUser.user.identification'),
          agentIndex: indexNumber,
          agentName: this.get('currentUser.user.fullName'),
          agentAutId: this.get('currentUser.user.authorizedNumber'),
          rapoIssue: this.get('rapoInfo.issue.value')
        }, contentType: 'application/json'
      }).then(function (rep) {
        var numberRep = _this5.get('save.rapoNumber.value');
        var exteridRep = rep.correctiveFps.externalId;

        var legalIdRep = rep.correctiveFps.legalId;
        var VehiculePlateRep = rep.correctiveFps.vehiclePlate;
        var VehiclePlateCountryRep = rep.correctiveFps.vehiclePlateCountry;
        var VehicleBrandRep = rep.correctiveFps.vehicleBrand;
        var ValidityDateRep = rep.correctiveFps.validityDate;
        var AmountRep = rep.correctiveFps.amount;
        var reducedAmountRep = rep.newBuyerFps.reducedAmount;
        var DeducRep = rep.correctiveFps.deductionTotal;
        var StreetNameRep = rep.correctiveFps.streetName;

        var VehicleModelRep = rep.correctiveFps.vehicleModel;
        var agentIdRep = rep.correctiveFps.agent.identification;
        var agentNameRep = rep.correctiveFps.agent.name;
        var agentAutIdRep = rep.correctiveFps.agent.authorizedNumber;
        var agentIdNRep = rep.correctiveFps.agent.indexNumber;
        var residSecRep = rep.correctiveFps.residentialSector;
        var tariffZoneRep = rep.correctiveFps.tariffZone;
        var streetNRep = rep.correctiveFps.streetNumber;
        var streetNBisRep = rep.correctiveFps.streetNumberBis;
        var streetTypeRep = rep.correctiveFps.streetType;
        //Ajoute le Fps correctif au RAPO
        _this5.get('ajax').request(_environment.default.APP.host + '/rapo/api/majRAPO', { //lien vers la requete de patch
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + _this5.get('session.session.authenticated.id_token'),
            City: _this5.get('currentUser.city')
          }, contentType: 'application/json',
          dataType: "json",
          data: JSON.stringify({
            "number": numberRep.toString(),
            "exterid": exteridRep.toString(),
            "legalid": legalIdRep.toString(),
            "VehiculePlate": VehiculePlateRep.toString(),
            "VehiclePlateCountry": VehiclePlateCountryRep.toString(),
            "VehicleBrand": VehicleBrandRep.toString(),
            "ValidityDate": ValidityDateRep.toString(),
            "Amount": AmountRep.toString(),
            "reducedAmount": reducedAmountRep.toString(),
            "Deduc": DeducRep.toString(),
            "StreetName": StreetNameRep.toString(),
            "VehicleModel": VehicleModelRep.toString(),
            "agentId": agentIdRep.toString(),
            "agentName": agentNameRep.toString(),
            "agentAutId": agentAutIdRep.toString(),
            "agentIdN": agentIdNRep.toString(),
            "residSec": residSecRep.toString(),
            "tariffZone": tariffZoneRep.toString(),
            "streetN": streetNRep.toString(),
            "streetNBis": streetNBisRep.toString(),
            "streetType": streetTypeRep.toString()
          })
        }).then(function () {
          //Créé le fps correctif pour le nouvel acquéreur
          _this5.get('ajax').request(_environment.default.APP.host + '/control/api/fps/createCorrectiveFps/?legalId=' + _this5.get('fpsInfo.numero.value') + '&rapoId=' + _this5.get('rapoInfo.id.value'), { //lien vers la requete de patch
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + _this5.get('session.session.authenticated.id_token'),
              City: _this5.get('currentUser.city'),
              stat: _this5.get('payload.status')
            }, data: {
              agentId: _this5.get('currentUser.user.identification'),
              agentIndex: indexNumber,
              agentName: _this5.get('currentUser.user.fullName'),
              agentAutId: _this5.get('currentUser.user.authorizedNumber'),
              rapoIssue: _this5.get('rapoInfo.issue.value')
            }, contentType: 'application/json'
          }).then(function (respo) {
            exteridRep = respo.correctiveFps.externalId;

            legalIdRep = respo.correctiveFps.legalId;
            VehiculePlateRep = respo.correctiveFps.vehiclePlate;
            VehiclePlateCountryRep = respo.correctiveFps.vehiclePlateCountry;
            VehicleBrandRep = respo.correctiveFps.vehicleBrand;
            ValidityDateRep = respo.correctiveFps.validityDate;
            DeducRep = respo.correctiveFps.deductionTotal;
            StreetNameRep = respo.correctiveFps.streetName;

            VehicleModelRep = respo.correctiveFps.vehicleModel;
            agentIdRep = respo.correctiveFps.agent.identification;
            agentNameRep = respo.correctiveFps.agent.name;
            agentAutIdRep = respo.correctiveFps.agent.authorizedNumber;
            agentIdNRep = respo.correctiveFps.agent.indexNumber;
            residSecRep = respo.correctiveFps.residentialSector;
            tariffZoneRep = respo.correctiveFps.tariffZone;
            streetNRep = respo.correctiveFps.streetNumber;
            streetNBisRep = respo.correctiveFps.streetNumberBis;
            streetTypeRep = respo.correctiveFps.streetType;

            AmountRep = respo.newBuyerFps.amount;
            reducedAmountRep = respo.newBuyerFps.reducedAmount;

            var vgender = _this5.get('buyer.gender.value', '');
            var vgivenName = _this5.get('buyer.givenName.value', '');
            var vfamilyName = _this5.get('buyer.familyName.value', '');
            var vstreetNumber = _this5.get('buyer.address.streetNumber.value', '');
            var vstreetNumberBis = _this5.get('buyer.address.streetNumberBis.value', '');
            var vstreetType = _this5.get('buyer.address.streetType.value', '');
            var vstreetName = _this5.get('buyer.address.streetName.value', '');
            var vpostalCode = _this5.get('buyer.address.postalCode.value', '');
            var vaddressLocality = _this5.get('buyer.address.addressLocality.value', '');
            var vaddressCountry = _this5.get('buyer.address.addressCountry.value', '');

            //Ajoute le Fps correctif au RAPO
            _this5.get('ajax').request(_environment.default.APP.host + '/rapo/api/majRAPO', { //lien vers la requete de patch
              method: 'PUT',
              headers: {
                Authorization: 'Bearer ' + _this5.get('session.session.authenticated.id_token'),
                City: _this5.get('currentUser.city')
              }, contentType: 'application/json',
              dataType: "json",
              data: JSON.stringify({
                "number": numberRep.toString(),
                "exterid": exteridRep.toString(),
                "legalid": legalIdRep.toString(),
                "VehiculePlate": VehiculePlateRep.toString(),
                "VehiclePlateCountry": VehiclePlateCountryRep.toString(),
                "VehicleBrand": VehicleBrandRep.toString(),
                "ValidityDate": ValidityDateRep.toString(),
                "Amount": AmountRep.toString(),
                "reducedAmount": reducedAmountRep.toString(),
                "Deduc": DeducRep.toString(),
                "StreetName": StreetNameRep.toString(),
                "VehicleModel": VehicleModelRep.toString(),
                "agentId": agentIdRep.toString(),
                "agentName": agentNameRep.toString(),
                "agentAutId": agentAutIdRep.toString(),
                "agentIdN": agentIdNRep.toString(),
                "residSec": residSecRep.toString(),
                "tariffZone": tariffZoneRep.toString(),
                "streetN": streetNRep.toString(),
                "streetNBis": streetNBisRep.toString(),
                "streetType": streetTypeRep.toString(),
                "bgender": vgender.toString(),
                "bgivenName": vgivenName.toString(),
                "bfamilyName": vfamilyName.toString(),
                "bstreetNumber": vstreetNumber.toString(),
                "bstreetNumberBis": vstreetNumberBis.toString(),
                "bstreetType": vstreetType.toString(),
                "bstreetName": vstreetName.toString(),
                "bpostalCode": vpostalCode.toString(),
                "badressLocality": vaddressLocality.toString(),
                "badressCountry": vaddressCountry.toString()
              })
            }).then(function () {
              _this5.set("btn.active.value", false);
              _this5.get('notifications').success('Le Rattrapage a bien été effectué !!');
              _this5.loadRapo();
            }).catch(function (errorMSG) {
              _this5.set("btn.active.value", false);
              _this5.traitementErreur(errorMSG);
              _this5.loadRapo();
            });
          }).catch(function (errorMSG) {
            _this5.set("btn.active.value", false);
            _this5.traitementErreur(errorMSG);
            _this5.loadRapo();
          });
        }).catch(function (errorMSG) {
          _this5.set("btn.active.value", false);
          _this5.traitementErreur(errorMSG);
          _this5.loadRapo();
        });
      }).catch(function (errorMSG) {
        _this5.set("btn.active.value", false);
        _this5.traitementErreur(errorMSG);
        _this5.loadRapo();
      });
    },


    /**
    * remet toutes les données enregistrés à 0€
    **/
    refreshData: function refreshData() {
      this.set('fpsInfo.numero.value', '');
      this.set('fpsInfo.date.value', '');
      this.set('fpsInfo.datedTime.value', '');
      this.set('fpsInfo.plaque.value', '');
      this.set('fpsInfo.claimtype.value', '');
      this.set('fpsInfo.type.value', '');
      this.set('fpsInfo.status.value', '');
      this.set('fpsInfo.reason.value', '');
      this.set('fpsInfo.check.value', false);
      this.set('fpsInfo.price.finePrice', 0);
      this.set('fpsInfo.price.reducesFinePrice', 0);
      this.set('div.hidden.value', 'display: none;');
      this.set('fpsInfo.surcharge.value', '');

      this.set('fpsCorrectif.numero.value', '');
      this.set('fpsCorrectif.date.value', '');
      this.set('fpsCorrectif.plaque.value', '');
      this.set('fpsCorrectif.claimtype.value', '');
      this.set('fpsCorrectif.type.value', '');
      this.set('fpsCorrectif.status.value', '');
      this.set('fpsCorrectif.reason.value', '');
      this.set('div2.hidden.value', 'display: none;');
      this.set('fpsCorrectif.price.finePrice', 0);
      this.set('fpsCorrectif.price.reducesFinePrice', 0);
      this.set('fpsCorrectif.surcharge.value', 0);
      this.set('fpsCorrectif.check.value', false);

      this.set('fpsCorrectif2.numero.value', '');
      this.set('fpsCorrectif2.date.value', '');
      this.set('fpsCorrectif2.plaque.value', '');
      this.set('fpsCorrectif2.claimtype.value', '');
      this.set('fpsCorrectif2.type.value', '');
      this.set('fpsCorrectif2.status.value', '');
      this.set('fpsCorrectif2.reason.value', '');
      this.set('div3.hidden.value', 'display: none;');
      this.set('fpsCorrectif2.price.finePrice', 0);
      this.set('fpsCorrectif2.price.reducesFinePrice', 0);
      this.set('fpsCorrectif2.surcharge.value', 0);
      this.set('fpsCorrectif2.check.value', false);

      this.set('rapoInfo.numero.value', '');
      this.set('rapoInfo.datedTime.value', '');
      this.set('rapoInfo.date.value', '');
      this.set('rapoInfo.plaque.value', '');
      this.set('rapoInfo.issue.value', '');
      this.set('rapoInfo.status.value', '');
      this.set('rapoInfo.id.value', '');

      this.set('buyer.gender.value', '');
      this.set('buyer.givenName.value', '');
      this.set('buyer.familyName.value', '');
      this.set('buyer.address.streetNumber.value', '');
      this.set('buyer.address.streetNumberBis.value', '');
      this.set('buyer.address.streetType.value', '');
      this.set('buyer.address.streetName.value', '');
      this.set('buyer.address.postalCode.value', '');
      this.set('buyer.address.addressLocality.value', '');
      this.set('buyer.address.addressCountry.value', '');

      this.set('save.rapoNumber.value', '');
    },


    actions: {

      /**
      * Fonction choisissant la requête à exécuter et affichant un texte adéquat sur la fenêtre popup
      **/
      openPopUp: function openPopUp() {
        if (this.fpsInfo.status.value == 'REJECTED' && this.fpsInfo.type.value == 'INITIAL' && this.rapoInfo.status.value == 'TO_VALIDATE' && this.rapoInfo.issue.value == 'REJECTION') {
          //Créer notre patch dans le Client correspondant a la variable du prestataire
          //le patch prend en parametre les differentes variables présents dans notre js
          this.set('popInfo.text.value', 'Le fps va être patché. les données présentes sur le RAPO vont être déposé dans le fps  \n Voulez vous continuer ?');
          this.set('isValide', true);
          this.set('condition.type.value', 1);
          this.set('btn.activeapp.value', false);
        } else if (this.fpsInfo.status.value == "ACCEPTED" && (this.fpsInfo.type.value == "CANCELLED" || this.fpsInfo.type.value == "CORRECTION") && this.rapoInfo.status.value == "TO_VALIDATE" && (this.rapoInfo.issue.value == "ACCEPTANCE" || this.rapoInfo.issue.value == "CORRECTION")) {
          //Créér le FPS rectificatif ou d'annulation sur le serveur de FPS si celui-ci n'existe pas sur le serveur
          //de FPS. S'il existe déjà, passer directement à l'insertion dans notre base de données.
          this.set('popInfo.text.value', 'Un FPS d\'annulation sur le serveur va être créé si il n\'existe pas déja. \nSinon nous allons insérer les données présente sur le RAPO dans le FPS  \nVoulez vous continuer ?');
          this.set('isValide', true);
          this.set('condition.type.value', 2);
          this.set('btn.activeapp.value', false);
        } else if (this.fpsInfo.status.value.includes("TRANSFERRED") && this.rapoInfo.status.value == "TO_VALIDATE" && this.rapoInfo.issue.value == "ACCEPTANCE") {
          //Procédure macro du cas de cession de véhicule : à adapter selon les différents états possibles (existance ou non existance des différents fps correctifs sur notre serveur ou le serveur partenaire)

          //Ici il s'agit d'un cas particulier : le FPS a été émis à l'ancien propriétaire du véhicule, qui l'a cédé ou vendu.
          //L'objectif est donc, dans le cas où le RAPO a été accepté, de créer dans un premier temps un FPS correctif de valeur 0 pour l'ancien
          //propriétaire qui ne doit rien payer, et de créer un second FPS correctif cette fois-ci à
          //destination de l'acquéreur du véhicule, qui lui doit payer l'amende.
          this.set('popInfo.text.value', 'Le FPS a été émis à l\'ancien propriétaire. Un FPS rectificatif de 0€ sera créé pour l\'ancien propriétaire du véhicule et un second FPS a destination de l\'acquéreur du véhicule actuel sera aussi créé. \n Voulez-vous continuer ?');
          this.set('isValide', true);
          this.set('condition.type.value', 3);
          this.set('btn.activeapp.value', false);
        } else {
          this.set('btn.activeapp.value', true);
          this.set('isValide', true);
          this.set('condition.type.value', 0);
          this.set('popInfo.text.value', 'Erreur : Patch impossible. \nLes données renseignés ne correspondent pas à un problème connus.');
        }
      },


      /**
      * quitter la fenêtre de popup
      **/
      leavePopUp: function leavePopUp() {
        this.set('isValide', false);
      },


      /**
      * Fonction exécutant les requêtes nécessaire pour faire les rattrapages rapo
      **/
      applyRAPO: function applyRAPO() {
        //On enlève la fenêtre et on désactive le bouton pour faire le rattrapage
        this.set("btn.active.value", true);
        this.set("isValide", false);

        if (this.get('fpsInfo.numero.value') != '') {
          //CAS 1
          if (this.get('condition.type.value') == 1) {

            this.patchFPS();

            //CAS 2
          } else if (this.get('condition.type.value') == 2) {

            this.majRAPO();

            //CAS 3
          } else if (this.get('condition.type.value') == 3) {

            this.cessionVehicule();
          }
        } else {
          this.traitementErreur("Veuillez remplir le formulaire");
          this.set("btn.active.value", false);
        }
        this.loadRapo();
      },


      /**
      * Fonction de recherche du RAPO et du/des FPS
      **/
      loadData: function loadData() {
        //Remet toutes les données à 0 (problème si on enchainait les requêtes)
        this.refreshData();

        this.loadRapo();
      }
    }
  });
});