define('sags-front-bo/models/team', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    identification: _emberData.default.attr('string'),
    supervisor: _emberData.default.belongsTo('user', { inverse: null }),
    manager: _emberData.default.belongsTo('user', { inverse: null }),
    agents: _emberData.default.hasMany('user', { inverse: 'team' })
  });
});