define('sags-front-bo/routes/controls/vehicle-plates', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/config/environment'], function (exports, _accessController, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('hashValue', null);
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.set('vehiclePlate', null);
    },


    actions: {
      getHash: function getHash() {
        var _this = this;

        this.get('ajax').request(_environment.default.APP.host + '/control/api/vehicle-plate/hash', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json',
          data: {
            vehiclePlate: this.get('controller.vehiclePlate')
          } }).then(function (response) {
          _this.controller.set('hashValue', response.vehiclePlate);
        });
      }
    }
  });
});