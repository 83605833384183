define('sags-front-bo/controllers/controls/annulation', ['exports', 'lodash', 'sags-front-bo/config/environment', 'sags-front-bo/utils/dictionary'], function (exports, _lodash, _environment, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    currentUser: Ember.inject.service('current-user'),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    //Liste des variables
    filters: {
      number: {
        comparator: 'contains',
        value: ''
      }
    },

    save: {
      legalId: {
        comparator: 'equals',
        value: ''
      }
    },

    cancel: {
      etag: {
        comparator: 'equals',
        value: ''
      },
      externalid: {
        comparator: 'equals',
        value: ''
      }
    },

    btn: {
      disabled: {
        comparator: 'equals',
        value: true
      },
      valid: {
        comparator: 'equals',
        value: ''
      }
    },

    data: {
      comparator: 'equals',
      value: ''
    },
    fpsInfo: {
      legalid: {
        comparator: 'contains',
        value: ''
      },
      type: {
        comparator: 'equals',
        value: ''
      },
      status: {
        comparator: 'equals',
        value: ''
      },
      agentName: {
        comparator: 'contains',
        value: ''
      },
      agentId: {
        comparator: 'equals',
        value: '',
        datedTime: ''
      },
      agentOrganisme: {
        comparator: 'equals',
        value: ''
      },
      plaque: {
        comparator: 'equals',
        value: ''
      },
      vehiculeModel: {
        comparator: 'equals',
        value: ''
      },
      vehiculeBrand: {
        comparator: 'equals',
        value: ''
      },
      statementAdresse: {
        comparator: 'equals',
        value: ''
      },
      statementDate: {
        comparator: 'equals',
        value: ''
      }
    },

    formatAdresse: function formatAdresse(adresse) {
      var resultat = '';
      if (adresse.streetNumber) {
        resultat += adresse.streetNumber + ' ';
      }
      if (adresse.streetNumberBis) {
        resultat += adresse.streetNumberBis + ' ';
      }
      if (adresse.streetType) {
        resultat += adresse.streetType + ' ';
      }
      if (adresse.streetName) {
        resultat += adresse.streetName + ', ';
      }if (adresse.postalCode) {
        resultat += adresse.postalCode + ', ';
      }if (adresse.addressLocality) {
        resultat += adresse.addressLocality + ' ';
      }if (adresse.addressCountry) {
        resultat += adresse.addressCountry;
      }

      if (resultat == '') {
        resultat = 'AUCUNE DONNEE';
      }
      return resultat;
    },
    findfps: function findfps() {
      var _this = this;

      var numerofps = this.get('filters.number.value');
      this.set('save.legalId.value', numerofps);

      if (numerofps) {
        this.set('isLoading', true);
        this.set('isFind', false);

        this.get('ajax').request(_environment.default.APP.host + '/control/api/fps/getFps/?legalidfps=' + numerofps, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json'
        }).then(function (data) {
          if (data) {
            _this.set('fpsInfo.legalid.value', !data.fineLegalId ? 'AUCUNE DONNEE' : data.fineLegalId);
            _this.set('fpsInfo.type.value', !data.type || data.type == "" ? 'AUCUNE DONNEE' : data.type);
            _this.set('fpsInfo.agentName.value', !data.agent.name || data.agent.name == "" ? 'AUCUNE DONNEE' : data.agent.name);
            _this.set('fpsInfo.agentId.value', !data.agent.agentId || data.agent.agentId == "" ? 'AUCUNE DONNEE' : data.agent.agentId);
            _this.set('fpsInfo.agentOrganisme.value', !data.agent || !data.agent.worksFor || !data.agent.worksFor.organizationId || data.agent.worksFor.organizationId == "" ? 'AUCUNE DONNEE' : data.agent.worksFor.organizationId);
            _this.set('fpsInfo.plaque.value', !data.licensePlate || !data.licensePlate.plate || data.licensePlate.plate == "" ? 'AUCUNE DONNEE' : data.licensePlate.plate);
            _this.set('fpsInfo.vehiculeBrand.value', !data.vehicle || !data.vehicle.brand || data.vehicle.brand == "" ? 'AUCUNE DONNEE' : data.vehicle.brand);
            _this.set('fpsInfo.vehiculeModel.value', !data.vehicle || !data.vehicle.model || data.vehicle.model == "" ? 'AUCUNE DONNEE' : data.vehicle.model);
            _this.set('fpsInfo.statementDate.value', !data.statementDatetime || data.statementDatetime == "" ? 'AUCUNE DONNEE' : data.statementDatetime);
            _this.set('fpsInfo.statementAdresse.value', !data.statementAddress ? 'AUCUNE DONNEE' : _this.formatAdresse(data.statementAddress));
            _this.set('fpsInfo.status.value', data.claims || data.claims.length > 0 ? data.claims[data.claims.length - 1].claimStatus : 'Aucun Claims');
          }

          _this.get('ajax').request(_environment.default.APP.host + '/control/api/fps/?legalId=' + numerofps, {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
              City: _this.get('currentUser.city')
            },
            contentType: 'application/json'
          }).then(function (data) {
            _this.set('data.value', data);
            _this.set('cancel.etag.value', data.etag);
            _this.set('cancel.externalid.value', data.externalId);
            _this.set('isLoading', false);
            _this.set('isFind', true);
            _this.set('btn.disabled.value', false);
            _this.get('notifications').success("FPS trouvé");
          }).catch(function () {
            _this.get('notifications').error("Certaines informations pour l'annulation ne sont pas présentes.");
          });
        }).catch(function (error) {
          console.log(error);
          _this.set('isLoading', false);
          _this.set('btn.disabled.value', true);
          _this.get('notifications').error("Donnée inexistante ou le contenu de votre recherche n'est pas bon");
        });
      }
    },


    actions: {

      /**
      * Fonction choisissant la requête à exécuter et affichant un texte adéquat sur la fenêtre popup
      **/
      openPopUp: function openPopUp() {
        this.set('isOpen', true);
      },


      /**
      * quitter la fenêtre de popup
      **/
      leavePopUp: function leavePopUp() {
        this.set('isOpen', false);
      },


      /**
      *
      **/
      rechercheFPS: function rechercheFPS() {
        this.findfps();
      },


      /**
      *
      **/
      annulationFPS: function annulationFPS() {
        var _this2 = this;

        this.get('ajax').request(_environment.default.APP.host + '/control/api/fps/cancel', {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          }, data: JSON.stringify({
            id: this.get('data.value').id,
            externalId: this.get('data.value').externalId,
            type: this.get('data.value').type,
            terminalId: this.get('data.value').terminalId,
            vehiclePlate: this.get('data.value').vehiclePlate,
            vehiclePlateCountry: this.get('data.value').vehiclePlateCountry,
            vehicleBrand: this.get('data.value').vehicleBrand,
            vehicleModel: this.get('data.value').vehicleModel,
            creationDate: this.get('data.value').creationDate,
            validityDate: this.get('data.value').validityDate,
            reducedDate: this.get('data.value').reducedDate,
            modificationDate: this.get('data.value').modificationDate,
            amount: this.get('data.value').amount,
            reducedAmount: this.get('data.value').reducedAmount,
            paymentStatus: this.get('data.value').paymentStatus,
            legalId: this.get('data.value').legalId,
            hasRapo: this.get('data.value').hasRapo,
            hasCcspClaim: this.get('data.value').hasCcspClaim,
            rapoMotivations: this.get('data.value').rapoMotivations,
            receiptCivility: this.get('data.value').receiptCivility,
            receiptEmail: this.get('data.value').receiptEmail,
            significantParkingTickets: this.get('data.value').significantParkingTickets,
            latitude: this.get('data.value').latitude,
            longitude: this.get('data.value').longitude,
            zipCode: this.get('data.value').zipCode,
            city: this.get('data.value').city,
            agent: this.get('data.value').agent,
            etag: this.get('data.value').etag,
            tariffZone: this.get('data.value').tariffZone,
            residentialSector: this.get('data.value').residentialSector,
            streetNumber: this.get('data.value').streetNumber,
            streetNumberBis: this.get('data.value').streetNumberBis,
            streetType: this.get('data.value').streetType,
            streetName: this.get('data.value').streetName,
            cancelDatetime: this.get('data.value').cancelDatetime,
            cancelReason: this.get('data.value').cancelReason,
            cancelComment: this.get('data.value').cancelComment,
            cancelledByAgentId: this.get('data.value').cancelledByAgentId,
            authTransfertDatetime: this.get('data.value').authTransfertDatetime,
            controlComment: this.get('data.value').controlComment,
            buyerFirstName: this.get('data.value').buyerFirstName,
            buyerLastName: this.get('data.value').buyerLastName,
            buyerStreetNumber: this.get('data.value').buyerStreetNumber,
            buyerStreetNumberBis: this.get('data.value').buyerStreetNumberBis,
            buyerStreetType: this.get('data.value').buyerStreetType,
            buyerStreetName: this.get('data.value').buyerStreetName,
            buyerAdditionalAddress: this.get('data.value').buyerAdditionalAddress,
            buyerZipCode: this.get('data.value').buyerZipCode,
            buyerCity: this.get('data.value').buyerCity,
            buyerCivility: this.get('data.value').buyerCivility,
            buyerEmail: this.get('data.value').buyerEmail,
            buyerCountry: this.get('data.value').buyerCountry,
            surcharge: this.get('data.value').surcharge

          }),
          contentType: 'application/json'
        }).then(function (data) {
          _this2.findfps();
          _this2.set('isOpen', false);
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  });
});