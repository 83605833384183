define('sags-front-bo/models/black-list', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    insertionDate: _emberData.default.attr('date'),
    agent: _emberData.default.belongsTo('user', { inverse: null }),
    vehiclePlate: _emberData.default.attr('string'),
    code: _emberData.default.attr('string')
  });
});