define('sags-front-bo/routes/reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // liste exhaustive des routes sur lesquelles il est possible de retourner après un changement de ville
  var returnableRoutes = ['users', 'users.create', // Gestion des utilisateurs
  'teams', 'teams.create', // Gestion des équipes
  'teams.planning', // Planning
  'users.tour', // Tournées

  'controls.activities', // Suivi de l'activité
  'controls', // Liste des contrôles
  'controls.vao', // Traitement VAO
  'controls.annulation', // annulation fps
  'fps', // Liste des FPS
  'observations', // Liste des observations
  'controls.white-list-edition', // Liste des plaques présentes sur la liste blanche

  'rapo', // Liste des RAPO
  'rapo.create', // Nouveau RAPO
  // N/A : instruction RAPO
  'rapo.edition', // Editique
  'rapo.rattrap', 'devices', 'devices.create', // Gestion des terminaux
  'cars', 'cars.create', // Gestion des véhicules

  'gis.file-management', // Gestion des données
  'gis.frequency-management', // Définition des priorités
  'sectors.create', // Définition des secteurs
  'sectors.assignment', // Affectation des secteurs
  'rules' // Modification des règles de passage
  ];

  // route spécifique qui remet à zéro Ember Data et qui redirige vers la page d'accueil.
  exports.default = Ember.Route.extend({

    currentUser: Ember.inject.service(),

    renderTemplate: function renderTemplate() {
      var _this = this;

      // pour les tests, s'assurer de toujours attendre que le callback ait été appelé
      var transitionDone = false;
      if (Ember.testing) {
        Ember.Test.registerWaiter(function () {
          return transitionDone;
        });
      }

      setTimeout(function () {
        return Ember.run(function () {
          // purger le datastore Ember Data pour "oublier" les données de l'ancienne ville
          _this.store.unloadAll();

          // recharger l'utilisateur connecté, puis retourner à la page d'accueil
          _this.get('currentUser').load().then(function () {
            var routeToGoBackTo = _this.get('controller.originalRoute');
            if (routeToGoBackTo === null) {
              routeToGoBackTo = '';
            }

            // si la route actuelle ne fait pas partie de la liste blanche, essayer de remonter d'un niveau
            while (!returnableRoutes.includes(routeToGoBackTo) && routeToGoBackTo.lastIndexOf('.') !== -1) {
              routeToGoBackTo = routeToGoBackTo.substring(0, routeToGoBackTo.lastIndexOf('.'));
            }

            if (returnableRoutes.includes(routeToGoBackTo)) {
              // liste blanche => retour vers la route
              _this.transitionTo(routeToGoBackTo);
            } else {
              // pas en liste blanche => retour vers la page d'accueil
              _this.transitionTo('index');
            }

            transitionDone = true;
          });
        });
      }, 0);
    }
  });
});