define('sags-front-bo/routes/rapo/detail', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment', 'lodash', 'sags-front-bo/utils/pictures'], function (exports, _accessController, _dictionary, _environment, _lodash, _pictures) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      return this.store.queryRecord('rapo', { number: params.number }).then(function (rapo) {
        var currentAgent = _this.get('currentUser.user');
        return Ember.RSVP.hash({
          rapo: rapo,
          currentAgent: currentAgent
        });
      });
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      this._super.apply(this, arguments);

      var fpsPromise = this.store.queryRecord('fps', { externalId: model.rapo.get('fpsExternalId') }).then(function (fps) {
        return model.rapo.set('fullFps', fps);
      }).catch(function () {
        model.rapo.set('fullFps', null);
        _this2.get('notifications').error('Erreur de chargement du FPS lié au RAPO');
      });

      var savedPromises = [];
      // Récupération du contenu des PJ
      model.rapo.get('attachments').forEach(function (attachment) {
        savedPromises.push(_this2.get('ajax').request(_environment.default.APP.host + '/rapo/api/rapos/' + attachment.rapo + '/attachments?fileName=' + attachment.internalFileName, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + _this2.get('session.session.authenticated.id_token'),
            City: _this2.get('currentUser.city')
          },
          contentType: 'application/json'
        }));
      });

      var instructionsVisible = Ember.Object.create();
      Object.keys(_dictionary.default.rapoInstruction).forEach(function (k) {
        // pour INVALID_TICKET on continue si "non" a été coché, pour les autres on continue si "oui" a été coché
        if (k === 'INVALID_TICKET') {
          instructionsVisible.set(k, model.rapo.get('instructionResponses.' + k) === false);
        } else {
          instructionsVisible.set(k, model.rapo.get('instructionResponses.' + k));
        }
      });
      controller.set('instructionsVisible', instructionsVisible);

      controller.set('attachments', model.rapo.get('attachments').map(function (a) {
        return Ember.Object.create(a);
      }));

      // On récupère les pays
      var countryPromise = this.store.findAll('country').then(function (countries) {
        controller.set('countries', countries.sortBy('name').map(function (country) {
          return {
            key: country.get('isoCode'),
            value: country.get('name')
          };
        }));
        controller.set('unsortedCountries', countries.map(function (country) {
          return {
            key: country.get('isoCode'),
            value: country.get('name')
          };
        }));
      });

      // On récupère les settings (zones tarifaires et secteurs résident)
      var tariffZonePromise = _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        controller.set('zones', _dictionary.default.convertToArray(settings.tariffZones));
        controller.set('zonesFps', _dictionary.default.convertToArray(settings.tariffZonesFps));
        controller.set('sectors', _dictionary.default.convertToArray(settings.residentialSectors));

        // fusionner les 3 listes pour pouvoir afficher la liste des tickets avec dynamic-table + tariff-zone-element
        var zonesAndSectors = {};
        Object.keys(settings.tariffZones).forEach(function (key) {
          return zonesAndSectors[key] = settings.tariffZones[key];
        });
        Object.keys(settings.tariffZonesFps).forEach(function (key) {
          return zonesAndSectors[key] = settings.tariffZonesFps[key];
        });
        Object.keys(settings.residentialSectors).forEach(function (key) {
          return zonesAndSectors[key] = settings.residentialSectors[key];
        });
        controller.set('sharedVariable.tariffZones', zonesAndSectors);
      });

      // On recupère le setting pour la double validation
      _dictionary.default.fetchSettingsFromRapo(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        controller.set('doubleValidationEnabled', settings.doubleValidationEnabled);
      });

      var externalId = model.rapo.get('fpsExternalId');
      var url = _environment.default.APP.host + '/control/api/fps/' + externalId + '/pictures';
      var picturesFpsPromise = this.get('ajax').request(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
          City: this.get('currentUser.city')
        },
        contentType: 'application/json'
      });

      Ember.RSVP.all(savedPromises).then(function (attachmentsPromises) {
        for (var i = 0; i < attachmentsPromises.length; i++) {
          if (!attachmentsPromises[i].fileName.toLowerCase().endsWith('.pdf')) {
            controller.get('attachments').get(i).set('base64', 'data:image/png;base64,' + attachmentsPromises[i].base64);
            controller.get('attachments').get(i).set('isImage', true);
          } else {
            controller.get('attachments').get(i).set('base64', attachmentsPromises[i].base64);
            controller.get('attachments').get(i).set('isImage', false);
          }
        }
      });

      // On récupère les marques de voiture
      var brandPromise = this.store.findAll('vehicle-brand').then(function (brands) {
        controller.set('brands', brands.sortBy('name'));
      });

      // Récupérations des types d'envoi pour la réponse RAPO
      controller.set('responseFormats', _dictionary.default.convertToArray(_dictionary.default.responseFormats));
      // retirer l'option "Envoi par e-mail" si l'adresse mail est vide :
      // si pas de mandataire, celle du demandeur, sinon celle du mandataire
      if (!_lodash.default.isNil(model.rapo.get('mandataryCivility')) && (_lodash.default.isNil(model.rapo.get('mandataryEmail')) || _lodash.default.isEmpty(model.rapo.get('mandataryEmail'))) || _lodash.default.isNil(model.rapo.get('claimerEmail')) || _lodash.default.isEmpty(model.rapo.get('claimerEmail'))) {
        controller.set('responseFormats', controller.get('responseFormats').filter(function (format) {
          return format.key !== 'ELECTRONIC';
        }));
      }

      controller.set('responseFormat', controller.get('responseFormats').filter(function (format) {
        return format.key === model.rapo.get('responseFormat');
      })[0]);

      // Indicateur de visibilité de la modal pour afficher les PJs
      controller.set('isAttachmentModalVisible', false);

      // Indicateur de visibilité de la modal pour créer un nouveau ticket
      controller.set('isNewTicketModalVisible', false);

      // Indicateur de visibilité des photos de contexte FPS
      controller.set('isFpsPicturesVisible', false);

      // Checkbox du nouvel acquéreur
      controller.set('hasBuyer', !_lodash.default.isEmpty(controller.get('model.rapo.newBuyer')));

      // Champs du nouveau ticket du FPS rectificatif
      controller.set('ticketStartDate', null);
      controller.set('ticketEndDate', null);
      controller.set('ticketZone', null);
      controller.set('ticketSector', null);
      controller.set('ticketType', null);
      controller.set('ticketAuthorization', null);
      controller.set('ticketAmount', null);

      // Indicateurs d'exécution des requêtes
      controller.set('isLoadingReinitCorrectiveFps', false);
      controller.set('isLoadingModifyCorrectiveFps', false);
      controller.set('isLoadingRapo', false);
      controller.set('isLoadingAcceptRapo', false);
      controller.set('isLoadingRejectRapo', false);
      controller.set('isLoadingValidateInstruction', false);
      controller.set('isLoadingDeclineInstruction', false);
      controller.set('isLoadingGenerateDefence', false);
      controller.set('isGenerateDefenceLoaded', false);
      controller.set('isLoadingNextInstructionTask', false);
      controller.set('isLoadingNextInstructionTask', false);

      // Initialiser le FPS rectificatif avec sa valeur actuelle
      Ember.RSVP.all([countryPromise, brandPromise, tariffZonePromise, fpsPromise]).then(function () {
        controller.set('loadedCorrectiveFps', true);
        controller.get('initializeNewBuyer')(controller);
      });

      // Initialisation des photos contexte du FPS
      picturesFpsPromise.then(function (data) {
        controller.set('pictures', (0, _pictures.default)(data));

        // Indicateur de visibilité des photos de contexte FPS
        controller.set('isFpsPicturesVisible', false);
      });
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.set('attachments', null);
      controller.set('picturePlate', null);
      controller.set('picturePlateZoom', null);
      controller.set('pictureOverview', null);
      controller.set('processInstanceId', null);
      controller.set('loadedCorrectiveFps', false);
      controller.set('instructions', []);
    }
  });
});