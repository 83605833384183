define('sags-front-bo/components/sags-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    globalErrorMessage: null,

    isMessageNotNull: Ember.computed('globalErrorMessage', function () {
      return this.get('globalErrorMessage') !== null;
    })

  });
});