define('sags-front-bo/components/dynamic-table-paginator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      goToPage: function goToPage(page) {
        this.sendAction('goToPage', page);
      },
      goToPreviousPage: function goToPreviousPage() {
        this.sendAction('goToPage', this.get('pageNumber') - 1);
      },
      goToNextPage: function goToNextPage() {
        this.sendAction('goToPage', this.get('pageNumber') + 1);
      }
    }
  });
});