define('sags-front-bo/adapters/rapo', ['exports', 'sags-front-bo/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'rapo/api',

    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var model = snapshot.record;
      var url = this._super.apply(this, arguments);

      // utiliser l'API publique : modification de la base URL et ajout de paramètres
      url = url.replace('rapo/api', 'rapo/api/public');
      return url + '?claimerLastName=' + encodeURIComponent(model.get('claimerLastName')) + ('&number=' + model.get('number') + '&fpsLegalId=' + model.get('fpsLegalId'));
    }
  });
});