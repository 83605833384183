define('sags-front-bo/components/planning-scheduler', ['exports', 'lodash', 'sags-front-bo/utils/dictionary', 'sags-front-bo/constants/message', 'moment', 'sags-front-bo/config/environment'], function (exports, _lodash, _dictionary, _message, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),

    // Messase d'erreur
    globalErrorMessage: null,

    // Regexp qui permet de valider n'importe quelle heure entre 07:00 et 20:00 par crénau de 00:15
    regexpTime: /(((0[789])|(1[0-9])):(([03]0)|([14]5)))|(20:00)/,

    plannings_by_agent: [],

    // Permet d'activer le bouton valider si tous les champs obligatoires sont remplis
    isValidateDisabled: Ember.computed('isClicked', 'startHour', 'endHour', 'mission', 'isEditionPossible', function () {
      return this.get('isClicked') || !(this.get('mission') && this.get('regexpTime').test(this.get('startHour')) && this.get('regexpTime').test(this.get('endHour')) && this.get('isEditionPossible'));
    }),

    clearContext: function clearContext() {
      // Evénement à mettre à jour dans le cas d'une édition d'événement déjà existant
      this.set('eventHasToBeUpdated', null);

      // Heures de début et fin pour l'événement à créer via la popup
      this.set('startHour', null);
      this.set('endHour', null);

      // Dates exactes de début et fin pour l'événement à créer via la popup
      this.set('start', null);
      this.set('end', null);
      this.set('startInitial', null);
      this.set('endInitial', null);

      // Agent auquel rattacher l'événement à créer
      this.set('resourceId', null);

      // Type de mission sélectionné via la popup
      this.set('mission', null);

      // Indicateurs d'erreurs pour les heures de début et fin
      this.set('startHourError', false);
      this.set('endHourError', false);

      // Indicateurs pour la duplication en semaine
      this.set('monday', false);
      this.set('tuesday', false);
      this.set('wednesday', false);
      this.set('thursday', false);
      this.set('friday', false);
      this.set('saturday', false);

      // Indicateurs pour activer les checkbox des jours (duplication)
      this.set('isMonday', false);
      this.set('isTuesday', false);
      this.set('isWednesday', false);
      this.set('isThursday', false);
      this.set('isFriday', false);
      this.set('isSaturday', false);

      // Indicateur pour déterminer si l'édition d'un événement est possible
      // par rapport à ses voisins
      this.set('isEditionPossible', true);

      // Indicateur de drop d'un événement externe
      this.set('isNewExternalEvent', false);

      // Indicateur pour mettre à jour la charge hebdo
      this.set('isRendered', true);

      // Indicateur de progression suite à une requête API
      this.set('isOk', true);

      // Indicateur pour afficher la popup
      this.set('isConfirmVisible', false);

      // Message d'erreur
      this.set('globalErrorMessage', null);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentOccupancy', 0);
      this.set('isAgendaDay', false);
      this.set('events', _dictionary.default.convertToArray(_dictionary.default.events));
      this.clearContext();
    },
    updateResources: function updateResources() {
      this.set('agents', []);

      // Mode agent seul
      if (this.get('editable')) {
        // Intégration de l'agent au composant
        this.set('agents', [{
          id: this.get('team.id'),
          title: this.get('team.firstName') + ' ' + this.get('team.lastName'),
          contractedWorkingHoursPerWeek: this.get('team.contractedWorkingHoursPerWeek'),
          currentOccupancy: '0'
        }]);
        // Initialisation des événements
        this.$('#external-events .fc-event').each(function () {
          $(this).data('event', {
            title: $.trim($(this).text()),
            stick: true
          });

          $(this).draggable({
            zIndex: 999,
            revert: true,
            revertDuration: 0
          });
        });
        this.set('isRendered', true);
        // Mode team
      } else {
        // Intégration des agents au composant
        for (var i = 0; i < this.get('team.length'); i++) {
          this.get('agents').push({
            id: this.get('team').objectAt(i).get('id'),
            title: this.get('team').objectAt(i).get('firstName') + ' ' + this.get('team').objectAt(i).get('lastName'),
            contractedWorkingHoursPerWeek: this.get('team').objectAt(i).get('contractedWorkingHoursPerWeek'),
            currentOccupancy: '0'
          });
        }
      }
    },
    isDateHasEvent: function isDateHasEvent(start, end, resourceId) {
      var allEvents = [];
      // Récupération des événement de l'agent sélectionné
      if (this.get('eventHasToBeUpdated')) {
        var startInitial = this.get('startInitial');
        var endInitial = this.get('endInitial');
        allEvents = this.$('#calendar').fullCalendar('clientEvents', function (e) {
          return e.resourceId === resourceId && +e.start !== +startInitial && +e.end !== +endInitial;
        });
      } else {
        allEvents = this.$('#calendar').fullCalendar('clientEvents', function (e) {
          return e.resourceId === resourceId;
        });
      }
      // Check si une colision existe sur un autre jour (pour la duplication) avec le nouvel événement à créer
      var event = $.grep(allEvents, function (e) {
        e.start = (0, _moment.default)(e.start.format());
        e.end = (0, _moment.default)(e.end.format());
        // On suppose l'événement de 08:00 à 09:00 présent sur le planning
        return +e.start <= +start && +start < +e.end || // start: [08:00 ; 09:00[,  end: [09:00 ; 20:00]
        +e.end >= +end && +end > +e.start || // start: [07:00 ; end[,    end: ]08:00 ; 09:00]
        +e.start < +start && +end < +e.end || // start: ]08:00 ; end[,    end: ]start ; 09:00[
        +start < +e.start && +e.end < +end // start: [07:00 ; 08:00[,  end: ]09:00 ; 20:00]
        ;
      });
      return event.length > 0;
    },
    setDays: function setDays(start, end, resourceId) {
      var _this = this;

      this.set('isEditionPossible', true);
      // Check des autres jours pour savoir si les plages sont déjà prises
      var begin = this.$('#calendar').fullCalendar('getDate').startOf('week');
      var finish = this.$('#calendar').fullCalendar('getDate').startOf('week');

      begin.add(start.get('hours'), 'hours');
      begin.add(start.get('minutes'), 'minutes');
      begin.add(start.get('seconds'), 'seconds');

      finish.add(end.get('hours'), 'hours');
      finish.add(end.get('minutes'), 'minutes');
      finish.add(end.get('seconds'), 'seconds');

      ['isMonday', 'isTuesday', 'isWednesday', 'isThursday', 'isFriday', 'isSaturday'].forEach(function (isDayOfWeek) {
        if (_this.isDateHasEvent(begin, finish, resourceId)) {
          _this.set(isDayOfWeek, true);
          if (start.format() === begin.format() && end.format() === finish.format()) {
            _this.set('isEditionPossible', false);
            _this.set('globalErrorMessage', _message.default.ERROR_COLLISION);
          }
        } else {
          _this.set(isDayOfWeek, false);
        }
        begin.add(1, 'days');
        finish.add(1, 'days');
      });

      // Pré-sélection du jour courant
      switch (start.get('day')) {
        case 1:
          this.set('monday', !this.get('isMonday') && this.get('eventHasToBeUpdated') === null);
          if (this.get('eventHasToBeUpdated') !== null) {
            this.set('isMonday', true);
          }
          break;
        case 2:
          this.set('tuesday', !this.get('isTuesday') && this.get('eventHasToBeUpdated') === null);
          if (this.get('eventHasToBeUpdated') !== null) {
            this.set('isTuesday', true);
          }
          break;
        case 3:
          this.set('wednesday', !this.get('isWednesday') && this.get('eventHasToBeUpdated') === null);
          if (this.get('eventHasToBeUpdated') !== null) {
            this.set('isWednesday', true);
          }
          break;
        case 4:
          this.set('thursday', !this.get('isThursday') && this.get('eventHasToBeUpdated') === null);
          if (this.get('eventHasToBeUpdated') !== null) {
            this.set('isThursday', true);
          }
          break;
        case 5:
          this.set('friday', !this.get('isFriday') && this.get('eventHasToBeUpdated') === null);
          if (this.get('eventHasToBeUpdated') !== null) {
            this.set('isFriday', true);
          }
          break;
        case 6:
          this.set('saturday', !this.get('isSaturday') && this.get('eventHasToBeUpdated') === null);
          if (this.get('eventHasToBeUpdated') !== null) {
            this.set('isSaturday', true);
          }
          break;
        default:
          break;
      }
    },
    calculateCurrentOccupancy: function calculateCurrentOccupancy() {
      var begin = this.$('#calendar').fullCalendar('getDate').startOf('week');
      var finish = this.$('#calendar').fullCalendar('getDate').endOf('week');
      var resourceId = this.get('agents')[0].id;

      // Obligation de travailler avec les values et non les keys car chaque événement stocke la value
      var eventsWithoutImpact = ['Congés sans solde', 'Absence injustifiée', 'Absence diverse'];

      var allEvents = [];
      allEvents = this.$('#calendar').fullCalendar('clientEvents', function (e) {
        return e.resourceId === resourceId && +begin <= +e.start && +e.end <= +finish && !eventsWithoutImpact.includes(e.title);
      });
      var result = 0;
      allEvents.forEach(function (e) {
        result += Math.round((e.end.get('hours') - e.start.get('hours') + (e.end.get('minutes') - e.start.get('minutes')) / 60) * 100) / 100;
      });
      this.set('currentOccupancy', result);
    },
    setTimezone: function setTimezone(date) {
      return date.toDate();
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.set('isOk', false);
      this.updateResources();
      this.get('store').query('planning', {
        agentsId: this.get('agents').map(function (agent) {
          return agent.id;
        }).join(',')
      }).then(function (plannings_by_agent) {
        var plannings = [];
        plannings_by_agent.forEach(function (p) {
          plannings.push({
            id: p.get('id'),
            resourceId: p.get('resourceId'),
            start: p.get('start'),
            end: p.get('end'),
            title: _dictionary.default.events[p.get('title')]
          });
        });
        _this2.set('plannings_by_agent', plannings);

        _this2.$('#calendar').fullCalendar({
          // License FullCalendar
          schedulerLicenseKey: _environment.default.APP.fullCalendar.key,

          // Indicateur de modification du calendrier
          editable: _this2.get('editable'),
          // Permet la sélection d'une plage d'horaire pour la création d'événement
          selectable: _this2.get('editable'),
          // Aide visuelle pour la sélection d'une plage horaire
          selectHelper: _this2.get('editable'),
          // Permet de déposer un événement extérieur
          droppable: true,
          // Interdit la superposition d'événement
          eventOverlap: false,
          // Interdit l'édition de la durée d'une événement via le calendrier. Il faut passer par la popup d'édition
          eventDurationEditable: false,

          // Ratio hauteur / longueur du calendrier
          aspectRatio: 1.8,

          // Interdiction de l'utilisation de "jour entier" pour prévenir les bugs hors horaires autorisés
          allDaySlot: false,
          // Format des heures affichées dans le calendrier
          slotLabelFormat: 'H:mm',
          // Heure minimum de création d'événement
          minTime: '07:00:00',
          // Heure maximum de création d'événement
          maxTime: '20:00:00',
          // Durée minimum d'un événement
          slotDuration: '00:15:00',
          // Force l'initialisation du champ 'end' d'un événement
          forceEventDuration: true,
          // Durée par défaut lors d'un drop d'événement externe au calendrier
          defaultTimedEventDuration: '02:00:00',
          // Force la timezone pour prévenir toute erreur de date
          timezone: 'local',

          // Cache 'Dimanche'
          hiddenDays: [0],
          // Contraintes sur la sélection de plage horaire :
          //  - de Lundi à Samedi
          //  - de 07:00 à 20:00
          selectConstraint: {
            start: '07:00',
            end: '20:00',
            dow: [1, 2, 3, 4, 5, 6]
          },

          // Contraintes sur les événements :
          //  - peuvent être présent de Lundi à Samedi
          //  - de 07:00 à 20:00
          eventConstraint: {
            start: '07:00',
            end: '20:00',
            dow: [1, 2, 3, 4, 5, 6]
          },

          // Boutons liés au calendrier
          header: {
            left: 'today prev,next',
            center: 'title',
            right: 'agendaDay, agendaWeek, close'
          },

          // Vue par défaut : journalière
          defaultView: 'agendaDay',

          // Permet de split par agents
          views: {
            agenda: {
              groupByResource: true
            }
          },

          resourceColumns: [{
            labelText: 'Agents',
            field: 'title'
          }, {
            labelText: 'Charge contactuelle',
            field: 'contractedWorkingHoursPerWeek'
          }, {
            labelText: 'Charge réelle',
            field: 'currentOccupancy'
          }],

          // Les agents (colonnes)
          resources: _lodash.default.bind(function (callback) {
            callback(this.get('agents'));
          }, _this2),

          // Les différents plannings
          events: _this2.get('plannings_by_agent'),

          select: _lodash.default.bind(function (start, end, jsEvent, view, resource) {
            if (this.get('editable')) {
              // Formattage de l'heure de début et de l'heure de fin
              this.set('startHour', (start.get('hour') < 10 ? '0' : '') + start.get('hour') + ':' + (start.get('minute') < 10 ? '0' : '') + start.get('minute'));
              this.set('endHour', (end.get('hour') < 10 ? '0' : '') + end.get('hour') + ':' + (end.get('minute') < 10 ? '0' : '') + end.get('minute'));

              // Sauvegarde du contexte
              this.set('start', start);
              this.set('end', end);
              this.set('startInitial', (0, _moment.default)(start));
              this.set('endInitial', (0, _moment.default)(end));
              this.set('resourceId', resource.id);

              // Check des jours possible pour la duplication
              this.setDays(start, end, resource.id);

              // Affichage de la popup
              this.set('isConfirmVisible', true);
              this.set('isClicked', false);
            }
          }, _this2),

          eventClick: _lodash.default.bind(function (event) {
            if (this.get('editable')) {
              var start = event.start;
              var end = event.end;
              // Formattage de l'heure de début et de l'heure de fin
              this.set('startHour', (start.get('hour') < 10 ? '0' : '') + start.get('hour') + ':' + (start.get('minute') < 10 ? '0' : '') + start.get('minute'));
              this.set('endHour', (end.get('hour') < 10 ? '0' : '') + end.get('hour') + ':' + (end.get('minute') < 10 ? '0' : '') + end.get('minute'));

              // Sauvegarde du contexte
              this.set('start', start);
              this.set('end', end);
              this.set('startInitial', (0, _moment.default)(start));
              this.set('endInitial', (0, _moment.default)(end));
              this.set('resourceId', event.resourceId);
              this.set('mission', new Object());
              this.set('mission.value', event.title);

              // Sauvegarde de l'événement à mettre à jour
              this.set('eventHasToBeUpdated', event);

              // Check des jours possible pour la duplication
              this.setDays(start, end, event.resourceId);

              // Affichage de la popup
              this.set('isConfirmVisible', true);
              this.set('isClicked', false);
            }
          }, _this2),

          // Callback pour le déplacement d'un événement déjà présent sur le planning
          eventDrop: _lodash.default.bind(function (event) {
            if (this.get('editable')) {
              this.set('eventHasToBeUpdated', event);
              this.set('start', event.start);
              this.set('end', event.end);
              this.set('mission', new Object());
              this.set('mission.value', event.title);
              this.send('saveEvent');
            }
          }, _this2),

          // Callback appelé après chaque mise à jour d'événement
          eventAfterRender: _lodash.default.bind(function () {
            // Mise à jour de la charge hebdomadaire
            this.calculateCurrentOccupancy();
          }, _this2),

          viewRender: _lodash.default.bind(function () {
            this.calculateCurrentOccupancy();
          }, _this2),

          // Callback appelé après le drop d'un événement externe
          eventReceive: _lodash.default.bind(function (event) {
            this.set('eventHasToBeUpdated', event);
            this.set('isNewExternalEvent', true);
            this.set('start', event.start);
            this.set('end', event.end);
            this.set('mission', new Object());
            this.set('mission.value', event.title);
            this.send('saveEvent');
          }, _this2)
        });
        // Mise à jour de la charge hebdomadaire
        _this2.set('isRendered', true);
      }).catch(function (reason) {
        _this2.set('globalErrorMessage', reason);
      }).finally(function () {
        return _this2.set('isOk', true);
      });
    },
    didRender: function didRender() {
      if (this.get('isOk')) {
        this.updateResources();
        var calendarOptions = this.$('#calendar').fullCalendar('getView').options;
        calendarOptions.resources = this.get('agents');
        this.$('#calendar').fullCalendar(calendarOptions);
        this.$('#calendar').fullCalendar('render');
        this.$('#calendar').fullCalendar('refetchResources');

        var beginOfWeek = this.$('#calendar').fullCalendar('getDate').startOf('week');
        var endOfWeek = this.$('#calendar').fullCalendar('getDate').endOf('week');
        this.set('beginOfWeek', beginOfWeek.format('DD/MM'));
        this.set('endOfWeek', endOfWeek.format('DD/MM'));
        if (this.get('editable') && this.get('isRendered')) {
          this.calculateCurrentOccupancy();
          this.set('isRendered', false);
        }
      }
    },


    actions: {

      // Ferme la popup proprement
      closeModal: function closeModal() {
        this.clearContext();
      },


      // Action qui change la mission pour la select-box
      changeMission: function changeMission(mission) {
        this.set('mission', mission);
      },


      // Sauvegarde l'événement créé depuis la popup
      saveEvent: function saveEvent() {
        var _this3 = this;

        this.set('isClicked', true);
        // Mise à jour de l'événement sélectionné s'il existe et s'il ne déborde pas sur un autre
        if (this.get('eventHasToBeUpdated') && this.get('isEditionPossible')) {
          this.set('eventHasToBeUpdated.start', this.get('start'));
          this.set('eventHasToBeUpdated.end', this.get('end'));
          this.set('eventHasToBeUpdated.title', this.get('mission.value'));

          var eventSaved = this.get('eventHasToBeUpdated');
          var mission = this.get('events').filter(function (e) {
            return e.value === eventSaved.title;
          });
          this.set('mission', mission[0]);
          if (this.get('isNewExternalEvent')) {
            var newEventEmber = this.get('store').createRecord('planning', {
              resourceId: eventSaved.resourceId,
              start: this.setTimezone(eventSaved.start),
              end: this.setTimezone(eventSaved.end),
              title: mission[0].key
            });
            newEventEmber.save().then(function (event) {
              eventSaved.id = event.id;
              _this3.$('#calendar').fullCalendar('updateEvent', eventSaved);
              _this3.$('#calendar').fullCalendar('refetchEvents');
            }).finally(function () {
              _this3.set('isClicked', false);
            });
          } else {
            this.get('store').findRecord('planning', eventSaved.id).then(function (planning) {
              planning.set('start', _this3.setTimezone(eventSaved.start));
              planning.set('end', _this3.setTimezone(eventSaved.end));
              planning.set('title', mission[0].key);
              planning.save();
              _this3.$('#calendar').fullCalendar('updateEvent', eventSaved);
              _this3.$('#calendar').fullCalendar('refetchEvents');
            }).catch(function (reason) {
              _this3.set('globalErrorMessage', reason);
            }).finally(function () {
              _this3.set('isClicked', false);
            });
          }
        }

        // Initialisation du contexte dans au cas ou une duplication est demandée. On commence par Lundi et on fini par Samedi
        var begin = this.$('#calendar').fullCalendar('getDate').startOf('week');
        var finish = this.$('#calendar').fullCalendar('getDate').startOf('week');

        begin.add(this.get('start').get('hours'), 'hours');
        begin.add(this.get('start').get('minutes'), 'minutes');
        begin.add(this.get('start').get('seconds'), 'seconds');

        finish.add(this.get('end').get('hours'), 'hours');
        finish.add(this.get('end').get('minutes'), 'minutes');
        finish.add(this.get('end').get('seconds'), 'seconds');

        /*
        * resource : sauvegardé lors du 'select' -> id de l'agent courant
        * start : sauvegardé lors du 'select' -> début de l'événement
        * end : sauvegardé lors du 'select' -> fin de l'événement
        * title : choisi sur la popup -> type de mission
        */
        var newEvents = [];
        var savePromises = [];
        ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].forEach(function (day) {
          if (_this3.get(day)) {
            var newEvent = {
              resourceId: _this3.get('resourceId'),
              start: _this3.setTimezone(begin),
              end: _this3.setTimezone(finish),
              title: _this3.get('mission.value'),
              missionKey: _this3.get('mission.key')
            };
            var _newEventEmber = _this3.get('store').createRecord('planning', {
              resourceId: _this3.get('resourceId'),
              start: _this3.setTimezone(begin),
              end: _this3.setTimezone(finish),
              title: _this3.get('mission.key')
            });
            var savePromise = _newEventEmber.save().then(function (event) {
              newEvent.id = event.id;
              newEvents.push(newEvent);
            }).finally(function () {
              _this3.set('isClicked', false);
            });
            savePromises.push(savePromise);
          }

          begin.add(1, 'days');
          finish.add(1, 'days');
        });
        Ember.RSVP.all(savePromises).then(function () {
          _this3.$('#calendar').fullCalendar('addEventSource', newEvents);
          _this3.$('#calendar').fullCalendar('refetchEvents');

          // Mise à jour de la charge hebdomadaire
          _this3.set('isRendered', true);
          _this3.send('closeModal');
        });
      },
      deleteEvent: function deleteEvent() {
        var _this4 = this;

        // Suppression de l'événement choisi
        var eventHasToBeDeleted = this.get('eventHasToBeUpdated');
        this.$('#calendar').fullCalendar('removeEvents', eventHasToBeDeleted.id);

        this.get('store').findRecord('planning', eventHasToBeDeleted.id, { backgroundReload: false }).then(function (planning) {
          planning.destroyRecord();
        }).catch(function (reason) {
          return _this4.set('globalErrorMessage', reason);
        });

        // Mise à jour de la charge hebdomadaire
        this.set('isRendered', true);
        this.send('closeModal');
      },


      // Règle d'acceptance pour l'heure de début de l'événement
      changeStartHour: function changeStartHour() {
        this.set('globalErrorMessage', null);
        if (this.get('regexpTime').test(this.get('startHour')) && this.get('startHour') < this.get('endHour')) {
          this.set('startHourError', false);
          var start = this.get('startHour').split(':');
          this.set('start', this.get('start').add(start[0] - this.get('start').get('hours'), 'hours'));
          this.set('start', this.get('start').add(start[1] - this.get('start').get('minutes'), 'minutes'));

          // Revérification de l'heure de fin
          if (this.get('regexpTime').test(this.get('endHour')) && this.get('startHour') < this.get('endHour')) {
            this.set('endHourError', false);
            var end = this.get('endHour').split(':');
            this.set('end', this.get('end').add(end[0] - this.get('end').get('hours'), 'hours'));
            this.set('end', this.get('end').add(end[1] - this.get('end').get('minutes'), 'minutes'));
          }
          this.setDays(this.get('start'), this.get('end'), this.get('resourceId'));
        } else {
          this.set('startHourError', true);
          this.set('globalErrorMessage', _message.default.ERROR_START_HOUR);
        }
      },


      // Règle d'acceptance pour l'heure de fin de l'événement
      changeEndHour: function changeEndHour() {
        this.set('globalErrorMessage', null);
        if (this.get('regexpTime').test(this.get('endHour')) && this.get('startHour') < this.get('endHour')) {
          this.set('endHourError', false);
          var end = this.get('endHour').split(':');
          this.set('end', this.get('end').add(end[0] - this.get('end').get('hours'), 'hours'));
          this.set('end', this.get('end').add(end[1] - this.get('end').get('minutes'), 'minutes'));

          // Revérification de l'heure de début
          if (this.get('regexpTime').test(this.get('startHour')) && this.get('startHour') < this.get('endHour')) {
            this.set('startHourError', false);
            var start = this.get('startHour').split(':');
            this.set('start', this.get('start').add(start[0] - this.get('start').get('hours'), 'hours'));
            this.set('start', this.get('start').add(start[1] - this.get('start').get('minutes'), 'minutes'));
          }
          this.setDays(this.get('start'), this.get('end'), this.get('resourceId'));
        } else {
          this.set('endHourError', true);
          this.set('globalErrorMessage', _message.default.ERROR_END_HOUR);
        }
      }
    }

  });
});