define('sags-front-bo/routes/controls/activities', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/config/environment', 'sags-front-bo/utils/dictionary'], function (exports, _accessController, _environment, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Cette méthode est utile pour tester en faisant "comme si" on était dans le passé
  var getTodayAtMidnight = function getTodayAtMidnight() {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        return Ember.RSVP.hash({
          agents: _this.get('ajax').request(_environment.default.APP.host + '/api/activeAgentsByCity', {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
              City: _this.get('currentUser.city')
            },
            contentType: 'application/json'
          }).then(function (agents) {
            return agents.sortBy('firstName', 'lastName', 'role');
          }),
          agent: null,
          agentsPositions: [],
          startDatetime: getTodayAtMidnight(),
          tours: [],
          tour: null,
          aggregatedSections: [],
          statistics: null,
          tariffZones: settings.tariffZones,
          tariffZonesFps: settings.tariffZonesFps,
          tariffZonesColor: settings.tariffZonesColor,
          tariffZonesColorsFps: settings.tariffZonesColorsFps,
          isRefreshing: true,
          tourStats: {
            controlledPlaces: 0,
            remainingPlaces: 0
          }
        });
      }).then(function (data) {
        _this.set('globalErrorMessage', null);
        return data;
      }).catch(function (reason) {
        return _this.set('globalErrorMessage', reason);
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.get('refreshAll')(controller);
    }
  });
});