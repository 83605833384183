define('sags-front-bo/utils/file-input-to-model', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (_ref, model, fieldName, fileNameField) {
    var target = _ref.target;

    var reader = new FileReader();
    var file = target.files[0];
    var imageData = void 0;

    // on a dé-sélectionné l'image
    if (_lodash.default.isNil(target.value) || _lodash.default.isEmpty(target.value)) {
      model.set(fieldName, null);
      model.set(fileNameField, null);
      return;
    }

    // récupérer le nom du fichier après le dernier \ ou /
    var fileName = target.value.split(/(\\|\/)/g).pop();
    model.set(fileNameField, fileName);

    // action lorsque la lecture du fichier est terminée.
    reader.onload = function () {
      imageData = reader.result;
      // tronquer après la partie "data:image/jpeg;base64," de l'URL, pour obtenir la base 64
      imageData = imageData.substring(imageData.indexOf('base64,') + 7);
      model.set(fieldName, imageData);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
});