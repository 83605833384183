define('sags-front-bo/routes/ccsp-claim/create', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/dictionary', 'lodash'], function (exports, _accessController, _dictionary, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model() {
      return this.store.createRecord('ccsp-claim');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        return controller.set('siret', settings.siret);
      }).then(function () {
        if (!_lodash.default.isNil(controller.get('legalId'))) {
          controller.set('fpsLegalIdEnd', _lodash.default.replace(controller.get('legalId'), controller.get('siret'), ''));
          controller.set('disableFpsNumberInput', true);
        }
      });
    },
    resetController: function resetController(controller) {
      controller.set('medLimitProductionDate', undefined);
      controller.set('fpsLegalIdEnd', null);
      controller.set('tags', []);
      controller.set('legalId', null);
      controller.set('disableFpsNumberInput', false);
    }
  });
});