define('sags-front-bo/mixins/access-controller', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'lodash', 'sags-front-bo/helpers/control-access'], function (exports, _authenticatedRouteMixin, _lodash, _controlAccess) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!_lodash.default.isNil(this.get('currentUser.user'))) {
        if (!_controlAccess.default.compute([this.get('routeName'), this.get('currentUser')])) {
          this.transitionTo('index');
        }
      }

      this._super.apply(this, arguments);
    }
  });
});