define('sags-front-bo/controllers/ccsp-claim/index', ['exports', 'moment', 'sags-front-bo/utils/dictionary', 'lodash'], function (exports, _moment, _dictionary, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getOnlyTagsName(tags) {
    var tagsName = [];
    _lodash.default.forEach(tags, function (e) {
      tagsName.push(e.name);
    });
    return tagsName;
  }

  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service('current-user'),

    ccspClaimTags: _dictionary.default.convertToPowerSelectOptions(_dictionary.default.ccspClaimTags),
    tableColumns: [{
      'key': 'number',
      'displayName': 'Référence du recours',
      'customComponent': 'link-to-detail'
    }, {
      'key': 'fpsLegalId',
      'displayName': 'Référence du FPS'
    }, {
      'key': 'claimerName',
      'displayName': 'Nom du réclamant'
    }, {
      'key': 'status',
      'displayName': 'Statut',
      transform: function transform(status) {
        return _dictionary.default.ccspClaimStatus[status];
      }
    }, {
      'key': 'ccspClaimRecourseDate',
      'displayName': 'Date de saisie du recours',
      transform: function transform(ccspClaimRecourseDate) {
        return (0, _moment.default)(ccspClaimRecourseDate).format('DD/MM/YYYY');
      }
    }, {
      'key': 'transmissionDateToCity',
      'displayName': 'Date de transmission du recours à la ville',
      transform: function transform(transmissionDateToCity) {
        return (0, _moment.default)(transmissionDateToCity).format('DD/MM/YYYY');
      }
    }, {
      'key': 'transmissionDateFromCity',
      'displayName': 'Date de transmission du recours par la ville',
      transform: function transform(transmissionDateFromCity) {
        return (0, _moment.default)(transmissionDateFromCity).format('DD/MM/YYYY');
      }
    }, {
      'key': 'medLimitProductionDate',
      'displayName': 'Date limite de production du mémoire en défense',
      transform: function transform(medLimitProductionDate) {
        return (0, _moment.default)(medLimitProductionDate).format('DD/MM/YYYY');
      }
    }, {
      'key': 'tag',
      'displayName': 'Motif',
      transform: function transform(tag) {
        return _dictionary.default.ccspClaimTags[tag];
      }
    }],
    // filtres actifs sur la liste
    filters: {
      number: {
        comparator: 'contains',
        value: ''
      },
      fpsLegalId: {
        comparator: 'contains',
        value: ''
      },
      claimerName: {
        comparator: 'contains',
        value: ''
      },
      status: {
        comparator: 'equals',
        value: 'CREATED'
      }
    },

    serverSideFilters: {
      ccspClaimRecourseStartDate: {
        comparator: 'dateEquals'
      },
      ccspClaimRecourseEndDate: {
        comparator: 'dateEquals'
      },
      transmissionStartDateFromCity: {
        comparator: 'dateEquals'
      },
      transmissionEndDateFromCity: {
        comparator: 'dateEquals'
      }
    },

    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller, store) {
      var ccspClaimRecourseStartDate = controller.get('serverSideFilters.ccspClaimRecourseStartDate.value');
      var ccspClaimRecourseEndDate = controller.get('serverSideFilters.ccspClaimRecourseEndDate.value');
      var transmissionStartDateFromCity = controller.get('serverSideFilters.transmissionStartDateFromCity.value');
      var transmissionEndDateFromCity = controller.get('serverSideFilters.transmissionEndDateFromCity.value');
      var tags = getOnlyTagsName(controller.get('tags'));

      var requestBody = {};
      requestBody.tags = tags;

      if (!_lodash.default.isNil(ccspClaimRecourseStartDate) && !_lodash.default.isNil(ccspClaimRecourseEndDate)) {
        ccspClaimRecourseStartDate.setHours(0, 0, 0, 0);
        ccspClaimRecourseEndDate.setHours(23, 59, 59);
        requestBody.ccspClaimRecourseStartDate = ccspClaimRecourseStartDate.toISOString();
        requestBody.ccspClaimRecourseEndDate = ccspClaimRecourseEndDate.toISOString();
      }
      if (!_lodash.default.isNil(transmissionStartDateFromCity) && !_lodash.default.isNil(transmissionEndDateFromCity)) {
        transmissionStartDateFromCity.setHours(0, 0, 0, 0);
        transmissionEndDateFromCity.setHours(23, 59, 59);
        requestBody.transmissionStartDateFromCity = transmissionStartDateFromCity.toISOString();
        requestBody.transmissionEndDateFromCity = transmissionEndDateFromCity.toISOString();
      }

      controller.set('content', store.query('ccsp-claim', requestBody));
    },


    possibleStatuses: _dictionary.default.convertToArray(_dictionary.default.ccspClaimStatus, [{ key: '*', value: 'Tous' }]),
    possibleTags: _dictionary.default.convertToArray(_dictionary.default.ccspClaimTags),
    linkComponentConfig: {
      targetRoute: 'ccsp-claim.detail',
      targetItem: 'id'
    },
    sortByDate: ['ccspClaimRecourseDate:asc'],
    sortedRapos: Ember.computed.sort('model', 'sortByDate'),
    actions: {
      changeDate: function changeDate() {
        this.refreshWithServerSideFilter(this, this.store);
      },
      selectTags: function selectTags(tags) {
        this.set('tags', tags);
        this.refreshWithServerSideFilter(this, this.get('store'));
      }
    }
  });
});