define('sags-front-bo/controllers/white-list-bis/create', ['exports', 'sags-front-bo/config/environment', 'ember-ajax/errors', 'sweetalert', 'sags-front-bo/utils/dictionary'], function (exports, _environment, _errors, _sweetalert, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    codification: _dictionary.default.convertToArray(_dictionary.default.codifications)[1].value,
    actions: {
      createWhiteListBisEntry: function createWhiteListBisEntry() {
        var _this = this;

        var vehiclePlate = this.get('vehiclePlate');
        if (!vehiclePlate) {
          this.get('notifications').error('Veuillez saisir une valeur pour la plaque d\'immatriculation');
          return;
        }
        this.set('isLoading', true);
        var notificationService = this.get('notifications');
        Ember.run(function () {
          _this.get('ajax').request(_environment.default.APP.host + '/control/api/white-list-bis?vehicle-plate=' + vehiclePlate + '&codification=' + _this.codification, {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
              City: _this.get('currentUser.city')
            } }).then(function (response) {
            if (response) {
              notificationService.success('Cette plaque a bien été ajoutée à la liste');
            } else {
              notificationService.warning('Cette plaque a déjà été ajoutée à la liste');
            }
          }).catch(function (error) {
            notificationService.error('Une erreur est survenue lors de l\'ajout de cette plaque à la liste');
          }).finally(function () {
            return _this.set('isLoading', false);
          });
        });
      }
    }
  });
});