define('sags-front-bo/routes/observations/detail', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/mixins/access-controller', 'lodash'], function (exports, _environment, _accessController, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('observation', params.id);
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.findAll('country').then(function (countries) {
        controller.set('countries', countries.map(function (country) {
          return {
            key: country.get('isoCode'),
            value: country.get('name')
          };
        }));
      });

      if (_lodash.default.isNil(model.get('picture'))) {
        controller.set('observationPicture', null);
      } else {
        var url = _environment.default.APP.host + '/control/api/observations/picture';
        this.get('ajax').request(url, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          data: {
            picture: model.get('picture')
          },
          contentType: 'application/json',
          dataType: 'text'
        }).then(function (base64) {
          controller.set('observationPicture', 'data:image/png;base64,' + base64);
        }).catch(function () {
          _this.get('notifications').error('La photo de l\'observation n\'a pas été trouvée');
          controller.set('observationPicture', null);
        });
      }
    }
  });
});