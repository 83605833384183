define('sags-front-bo/controllers/gis/file-management', ['exports', 'sags-front-bo/config/environment', 'ember-ajax/errors', 'sweetalert', 'sags-front-bo/utils/download-file'], function (exports, _environment, _errors, _sweetalert, _downloadFile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    actions: {
      importGeoJsonFile: function importGeoJsonFile() {
        var _this = this;

        (0, _sweetalert.default)({
          title: 'Confirmation',
          html: 'L\'importation du fichier GeoJSON des tronçons va supprimer tous les tronçons de cette ville en base ainsi que les règles liées aux tronçons.<br/> Voulez vous continuer ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          _this.set('isUploading', true);
          var formData = new FormData();
          formData.append('file', $('#section-file')[0].files[0]);
          _this.get('ajax').request(_environment.default.APP.host + '/gis/api/import-geojson-file', {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
              City: _this.get('currentUser.city')
            },
            data: formData,
            dataType: 'text',
            contentType: false,
            processData: false
          }).then(function () {
            _this.get('notifications').success('Le fichier GeoJSON des tronçons a été importé avec succès');
          }).catch(function () {
            _this.get('notifications').error('Une erreur est survenue lors de l\'import du fichier GeoJSON des tronçons');
          }).finally(function () {
            _this.set('isUploading', false);
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      },
      exportSectionFile: function exportSectionFile() {
        var _this2 = this;

        this.set('isDownloading', true);
        this.get('ajax').request(_environment.default.APP.host + '/gis/api/export-geojson-file', {
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          }
        }).then(function (geojson) {
          (0, _downloadFile.default)(geojson.fileName, geojson.base64, 'application/json');
        }).catch(function (error) {
          if ((0, _errors.isNotFoundError)(error)) {
            _this2.get('notifications').error('Aucun fichier GeoJSON n\'a été trouvé.');
          } else {
            _this2.get('notifications').error('Impossible de télécharger le fichier.');
          }
        }).finally(function () {
          _this2.set('isDownloading', false);
        });
      },
      importControlRulesFile: function importControlRulesFile() {
        var _this3 = this;

        (0, _sweetalert.default)({
          title: 'Confirmation',
          html: 'L\'importation du fichier CSV des règles de contrôle va supprimer toutes les règles liées aux tronçons de la ville.<br/> Voulez vous continuer ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          _this3.set('isControlRulesUploading', true);
          var formData = new FormData();
          formData.append('file', $('#control-rules-file')[0].files[0]);
          _this3.get('ajax').request(_environment.default.APP.host + '/gis/api/import-control-rules-file', {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + _this3.get('session.session.authenticated.id_token'),
              City: _this3.get('currentUser.city')
            },
            data: formData,
            dataType: 'text',
            contentType: false,
            processData: false
          }).then(function () {
            _this3.get('notifications').success('Le fichier CSV des règles de contrôle a été importé avec succès');
          }).catch(function () {
            _this3.get('notifications').error('Une erreur est survenue lors de l\'import du fichier CSV des règles de contrôle');
          }).finally(function () {
            _this3.set('isControlRulesUploading', false);
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      },
      exportReportSectionFile: function exportReportSectionFile() {
        var _this4 = this;

        this.set('isDownloading', true);
        this.get('ajax').request(_environment.default.APP.host + '/gis/api/export-report-geojson', {
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          }
        }).then(function (geojson) {
          (0, _downloadFile.default)(geojson.fileName, geojson.base64, 'application/json');
        }).catch(function (error) {
          if ((0, _errors.isNotFoundError)(error)) {
            _this4.get('notifications').error('Aucun rapport n\'a été trouvé.');
          } else {
            _this4.get('notifications').error('Impossible de télécharger le fichier.');
          }
        }).finally(function () {
          _this4.set('isDownloading', false);
        });
      },
      exportReportRulesFile: function exportReportRulesFile() {
        var _this5 = this;

        this.set('isDownloading', true);
        this.get('ajax').request(_environment.default.APP.host + '/gis/api/export-report-rules', {
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          }
        }).then(function (geojson) {
          (0, _downloadFile.default)(geojson.fileName, geojson.base64, 'application/json');
        }).catch(function (error) {
          if ((0, _errors.isNotFoundError)(error)) {
            _this5.get('notifications').error('Aucun rapport n\'a été trouvé.');
          } else {
            _this5.get('notifications').error('Impossible de télécharger le fichier.');
          }
        }).finally(function () {
          _this5.set('isDownloading', false);
        });
      }
    }
  });
});