define('sags-front-bo/controllers/observations/detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      backToObservations: function backToObservations() {
        this.transitionToRoute('observations');
      }
    }
  });
});