define('sags-front-bo/controllers/fps/detail', ['exports', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment', 'lodash'], function (exports, _dictionary, _environment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tariffZones: [],
    isCancelFpsVisible: false,
    isLoadingCancelFps: false,
    fpsCancellationReasons: _dictionary.default.convertToPowerSelectOptions(_dictionary.default.fpsCancellationReason),

    hasComment: Ember.computed('model.controlComment', function () {
      return !_lodash.default.isEmpty(this.get('model.controlComment'));
    }),
    hasCancelComment: Ember.computed('model.controlComment', function () {
      return !_lodash.default.isEmpty(this.get('model.cancelComment'));
    }),

    actions: {
      showFpsPictures: function showFpsPictures() {
        this.set('isFpsPicturesVisible', true);
      },
      showAttachment: function showAttachment(isImage, img, attachment) {
        this.set('attachmentShown', attachment);
        this.set('attachmentBase64', img);
        this.set('isAttachmentModalVisible', true);
        this.set('isAttachmentImage', isImage);
      },
      closeAttachmentModal: function closeAttachmentModal() {
        this.set('attachmentShown', null);
        this.set('attachmentBase64', null);
        this.set('isAttachmentModalVisible', false);
      },
      showCancelFpsModal: function showCancelFpsModal() {
        this.set('isCancelFpsVisible', true);
      },
      closeCancelFpsModal: function closeCancelFpsModal() {
        this.set('model.cancelReason', null);
        this.set('model.cancelComment', null);
        this.set('selectedReason', null);
        this.set('isCancelFpsVisible', false);
      },
      selectReason: function selectReason(reason) {
        this.set('selectedReason', this.get('fpsCancellationReasons').findBy('name', reason.name));
        this.set('model.cancelReason', reason.name);
      },
      cancelFps: function cancelFps() {
        var _this = this;

        this.set('isLoadingCancelFps', true);
        this.set('model.cancelledByAgentId', this.get('currentUser.user'));

        this.get('ajax').request(_environment.default.APP.host + '/control/api/fps/cancel', {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          data: this.get('model'),
          contentType: 'application/json'
        }).then(function (fps) {
          _this.set('model', fps);
          _this.get('notifications').success('Le FPS a été annulé avec succès');
        }).catch(function () {
          _this.get('notifications').error('Erreur d\'annulation du FPS');
        }).finally(function () {
          _this.set('model.cancelReason', null);
          _this.set('model.cancelComment', null);
          _this.set('selectedReason', null);
          _this.set('isCancelFpsVisible', false);
          _this.set('isLoadingCancelFps', false);
        });
      }
    }
  });
});