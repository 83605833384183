define('sags-front-bo/helpers/find-by-key', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.findByKey = findByKey;
  function findByKey(params) {
    var map = params[0];
    var key = params[1];

    if (!_lodash.default.isObject(map)) {
      return null;
    }

    var filtered = map.filterBy('key', key);
    if (!_lodash.default.isEmpty(filtered)) {
      return filtered[0].value;
    }
    return null;
  }

  exports.default = Ember.Helper.helper(findByKey);
});