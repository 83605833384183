define('sags-front-bo/models/rule', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    ruleType: _emberData.default.attr('string'),
    beginDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    beginTime: _emberData.default.attr('string'),
    endTime: _emberData.default.attr('string'),
    dayOfWeek: _emberData.default.attr('string'),
    weekOfMonth: _emberData.default.attr('string'),
    dayOfMonth: _emberData.default.attr('string'),
    agentVisitFrequency: _emberData.default.belongsTo('visit-frequency'),
    lapiVisitFrequency: _emberData.default.belongsTo('visit-frequency'),
    aggregatedSectionId: _emberData.default.belongsTo('aggregated-section')

  });
});