define('sags-front-bo/controllers/gis/frequency-management', ['exports', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment'], function (exports, _dictionary, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    columns: [{
      'key': 'publicId',
      'displayName': 'Priorité'
    }, {
      'key': 'numberOfVisits',
      'displayName': 'Nombre de visites',
      'customComponent': 'textfield-element',
      'sortable': false
    }, {
      'key': 'frequency',
      'displayName': 'Fréquence',
      'customComponent': 'power-select-element',
      'sortable': false
    }],

    lineConfig: {
      inputType: 'number',
      inputMin: 1,
      inputRequired: true,
      powerSelects: {
        numberOfVisits: [{ key: 1, value: 'Un' }, { key: 2, value: 'Deux' }, { key: 3, value: 'Trois' }],
        frequency: _dictionary.default.convertToArray(_dictionary.default.visitFrequencies)
      }
    },

    actions: {
      saveNewFrequencies: function saveNewFrequencies() {
        var _this = this;

        // construire le corps de la requête
        var allFrequencies = [];
        allFrequencies.pushObjects(this.get('frequenciesAgent').map(function (item) {
          return item.toJSON({ includeId: true });
        }));
        allFrequencies.pushObjects(this.get('frequenciesLapi').map(function (item) {
          return item.toJSON({ includeId: true });
        }));

        this.set('isLoading', true);
        this.get('ajax').request(_environment.default.APP.host + '/gis/api/visit-frequencies', {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json',
          data: JSON.stringify(allFrequencies)
        }).then(function () {
          _this.get('notifications').success('Les priorités de contrôles ont été mises à jour');
        }).catch(function () {
          _this.get('notifications').error('Erreur lors de la mise à jour des priorités de contrôles');
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      }
    }
  });
});