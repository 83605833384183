define('sags-front-bo/components/rapo/instruction/instructions-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      onClick: function onClick(question, property) {
        this.onChoice(question, property);
      }
    }
  });
});