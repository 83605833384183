define('sags-front-bo/utils/file-input-to-model-array', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (_ref, fileList, fileType) {
    var target = _ref.target;

    var files = target.files;

    _lodash.default.forEach(files, function (file) {
      // récupérer le nom du fichier après le dernier \ ou /
      var fileName = file.name.split(/(\\|\/)/g).pop();

      var thisFile = {
        type: fileType,
        name: fileName,
        base64: null
      };
      fileList.push(thisFile);

      // action lorsque la lecture du fichier est terminée.
      var reader = new FileReader();
      reader.onload = function () {
        var imageData = reader.result;
        // tronquer après la partie "data:image/jpeg;base64," de l'URL, pour obtenir la base 64
        imageData = imageData.substring(imageData.indexOf('base64,') + 7);

        thisFile.base64 = imageData;
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    });
  };
});