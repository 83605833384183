define('sags-front-bo/models/ccsp-claim-comment', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    date: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    agent: _emberData.default.belongsTo('user'),
    ccspClaim: _emberData.default.belongsTo('ccsp-claim'),
    formattedDate: Ember.computed('date', function () {
      return (0, _moment.default)(this.get('date')).format('DD/MM/YYYY HH:mm');
    })
  });
});