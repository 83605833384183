define('sags-front-bo/components/ccsp-claim/decision', ['exports', 'lodash', 'sags-front-bo/config/environment', 'sags-front-bo/utils/dictionary', 'sags-front-bo/utils/download-file', 'sags-front-bo/helpers/file-extension-validator', 'moment'], function (exports, _lodash, _environment, _dictionary, _downloadFile2, _fileExtensionValidator, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),

    columnsNames: [{ 'key': 'name', 'displayName': 'Nom du fichier' }],

    actionIcons: [{ icon: 'download', tooltip: 'Télécharger' }],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('decisions', _dictionary.default.convertToArray(_dictionary.default.ccspClaimDecision));
      if (!_lodash.default.isNil(this.get('decision')) && this.get('status') === 'CLOSED') {
        this.set('decision', _lodash.default.filter(this.get('decisions'), { key: this.get('decision') })[0]);
      }
    },


    showAmountInput: Ember.computed('decision', function () {
      return this.get('decision.key') === 'PARTIAL_CANCELLATION';
    }),

    ccspClaimStatusIsClosed: Ember.computed('status', function () {
      return this.get('status') === 'CLOSED';
    }),

    statusIsDifferentFromCreated: Ember.computed('status', function () {
      return this.get('status') !== 'CREATED';
    }),

    actions: {
      submitDecision: function submitDecision() {
        var _this = this;

        var ccspClaimId = this.get('ccspClaimId');
        var decision = this.get('decision').key;
        var legalId = this.get('legalId');
        var currentUser = this.get('currentUser').get('user');
        var currentCity = this.get('currentUser').get('city');
        var agentInfo = currentUser.get('data');
        var agentIdentification = agentInfo.identification;
        var amount = $('#amount').val() * 100;
        var agentIndexNumber = _lodash.default.filter(agentInfo.userCities, { 'city': { name: currentCity } })[0].indexNumber;

        this.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/decision', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          data: {
            'ccspClaimId': ccspClaimId,
            'legalId': legalId,
            'agentIdentification': agentIdentification,
            'agentIndexNumber': agentIndexNumber,
            'decision': decision,
            'amount': amount
          },
          contentType: 'application/json'
        }).then(function () {
          _this.get('notifications').success('Décision soumise avec succès');
          _this.set('status', 'CLOSED');
          var action = _this.get('store').createRecord('ccsp-claim-action-event', {
            'action': 'DECISION_SAVE',
            'dateTime': (0, _moment.default)(),
            'agent': _this.get('currentUser.user'),
            'ccspClaim': _this.get('ccspClaim')
          });
          _this.get('updateActionEvents')(action);
        }).catch(function () {
          _this.get('notifications').error('Une erreur est survenue lors de la soumission de la décision');
        }).finally(function () {
          _this.set('isUploading', false);
        });
      },
      changeDecision: function changeDecision(decision) {
        this.set('decision', decision);
      },
      downloadFile: function downloadFile(attachment) {
        var _this2 = this;

        this.set('isLoadingPdfResponse', true);
        this.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/' + attachment.get('ccspClaim') + '/attachments?fileName=' + attachment.get('internalFileName'), {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          }
        }).then(function (file) {
          (0, _downloadFile2.default)(file.fileName, file.base64, 'application/pdf');
          var action = _this2.get('store').createRecord('ccsp-claim-action-event', {
            'action': 'FILE_DOWNLOAD',
            'dateTime': (0, _moment.default)(),
            'agent': _this2.get('currentUser.user'),
            'ccspClaim': _this2.get('ccspClaim'),
            'fileName': file.fileName
          });
          _this2.get('updateActionEvents')(action, 'décision');
        }).finally(function () {
          return _this2.set('isLoadingPdfResponse', false);
        });
      },
      performUpload: function performUpload() {
        var _this3 = this;

        var floatRegex = /^\d+(\.\d+)?$/;
        var file = $('#upload-decision-file')[0].files[0];
        if (_lodash.default.isNil(this.get('decision'))) {
          this.get('notifications').error('Veuillez sélectionner un type de décision');
          return;
        }
        if (this.get('decision.key') === 'PARTIAL_CANCELLATION' && !$('#amount').val().match(floatRegex)) {
          this.get('notifications').error('Seuls les caractères numériques sont autorisés pour le champ montant');
          return;
        }
        if (_lodash.default.isNil(file)) {
          this.get('notifications').error('Veuillez sélectionner un fichier');
        } else {
          var fileExtension = file.name.split('.').pop();
          if (!_fileExtensionValidator.default.compute(fileExtension)) {
            this.get('notifications').error('Ce type de fichier n\'est pas autorisé');
          } else {
            this.set('isUploading', true);
            var formData = new FormData();
            formData.append('file', $('#upload-decision-file')[0].files[0]);
            this.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/upload/attachments?fileName=' + file.name + '&type=DECISION&ccspClaimId=' + this.get('ccspClaimId'), {
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
                City: this.get('currentUser.city')
              },
              data: formData,
              dataType: 'text',
              contentType: false,
              processData: false
            }).then(function () {
              _this3.get('notifications').success('Le fichier a été importé avec succès');
              var action = _this3.get('store').createRecord('ccsp-claim-action-event', {
                'action': 'FILE_UPLOAD',
                'dateTime': (0, _moment.default)(),
                'agent': _this3.get('currentUser.user'),
                'ccspClaim': _this3.get('ccspClaim'),
                'fileName': file.name
              });
              _this3.get('updateActionEvents')(action, 'décision');
              _this3.send('submitDecision');
            }).catch(function () {
              _this3.get('notifications').error('Une erreur est survenue lors de l\'import du fichier');
            }).finally(function () {
              $('#upload-decision-file').val('');
              _this3.set('isUploading', false);
            });
          }
        }
      }
    }
  });
});