define('sags-front-bo/controllers/white-list-bis/delete', ['exports', 'sags-front-bo/config/environment', 'ember-ajax/errors', 'sweetalert'], function (exports, _environment, _errors, _sweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    actions: {
      deleteWhiteListBisEntry: function deleteWhiteListBisEntry() {
        var _this = this;

        var vehiclePlate = this.get('vehiclePlate');
        if (!vehiclePlate) {
          this.get('notifications').error('Veuillez saisir une valeur pour la plaque d\'immatriculation');
          return;
        }
        (0, _sweetalert.default)({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment retirer la plaque "' + vehiclePlate + '" de la liste ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          _this.set('isLoading', true);
          Ember.run(function () {
            _this.get('ajax').request(_environment.default.APP.host + '/control/api/white-list-bis?vehicle-plate=' + vehiclePlate, {
              method: 'DELETE',
              headers: {
                Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
                City: _this.get('currentUser.city')
              } }).then(function () {
              _this.get('notifications').success('Cette plaque a bien été retirée de la liste');
            }).catch(function (error) {
              var notificationService = _this.get('notifications');
              if ((0, _errors.isNotFoundError)(error)) {
                notificationService.error('Cette plaque n’est pas présente dans la liste');
                return;
              }
              if ((0, _errors.isBadRequestError)(error)) {
                notificationService.error('Cette plaque est invalide');
                return;
              }
              notificationService.error('Une erreur est survenue lors du retrait de cette plaque de la liste');
            }).finally(function () {
              return _this.set('isLoading', false);
            });
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      }
    }
  });
});