define('sags-front-bo/controllers/devices/index', ['exports', 'sags-front-bo/utils/dictionary'], function (exports, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    tableColumns: [{
      'key': 'imei',
      'displayName': 'Imei'
    }, {
      'key': 'voIP',
      'displayName': 'Identifiant VoIP'
    }, {
      'key': 'brand',
      'displayName': 'Marque'
    }, {
      'key': 'type',
      'displayName': 'Type',
      transform: function transform(typeId) {
        return _dictionary.default.deviceType[typeId];
      }
    }, {
      'key': 'state',
      'displayName': 'Statut',
      transform: function transform(typeId) {
        return _dictionary.default.deviceStatus[typeId];
      }
    }, {
      'key': 'assignedToId.fullName',
      'displayName': 'Agent'
    }],
    filters: {
      imei: {
        comparator: 'contains',
        value: ''
      },
      voIP: {
        comparator: 'contains',
        value: ''
      },
      type: {
        comparator: 'equals',
        value: '*'
      },
      state: {
        comparator: 'equals',
        value: '*'
      },
      brand: {
        comparator: 'contains',
        value: ''
      },
      assignedToId_fullName: {
        comparator: 'contains',
        value: ''
      }
    },
    possibleDeviceTypes: _dictionary.default.convertToArray(_dictionary.default.deviceType, [{ key: '*', value: 'Tous' }]),
    possibleDeviceStatuses: _dictionary.default.convertToArray(_dictionary.default.deviceStatus, [{ key: '*', value: 'Tous' }]),

    ButtonVisibility: function ButtonVisibility(one, elementId) {
      one.set('isIcon0Visible_' + elementId, Ember.computed('state', function () {
        return one.get('state') === 'WORKING';
      }));

      one.set('isIcon1Visible_' + elementId, Ember.computed('state', function () {

        return one.get('state') === 'BLOCKED';
      }));
    },


    actionIcons: [{ icon: 'unlock', tooltip: 'Bloquer' }, { icon: 'ban', tooltip: 'Débloquer' }, { icon: 'trash', tooltip: 'Supprimer' }]
  });
});