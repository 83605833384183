define('sags-front-bo/routes/sectors/assignment', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/coordinates-mapper', 'sweetalert'], function (exports, _environment, _accessController, _coordinatesMapper, _sweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        teams: this.get('currentUser.user.role') === 'SUPERVISOR' ? this.store.query('team', { supervisorId: this.get('currentUser.user.id') }) : this.store.findAll('team'),
        sectors: this.store.findAll('sector'),
        agents: null
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.store.findAll('aggregated-section', { reload: true }).then(function (aggregatedSections) {
        var coordinates = _coordinatesMapper.default.switchLatitudeLongitudeOfPolygones(aggregatedSections.mapBy('coordinates'));
        controller.set('aggregatedSectionsLocations', coordinates);
      });

      this.store.findAll('sector').then(function (sectors) {
        var sectorsCoordinatesAndName = _coordinatesMapper.default.switchLatitudeLongitudeOfPolygonesOfSectors(sectors);
        // la map est centrée sur l'ensemble des secteurs
        var bounds = [];
        sectorsCoordinatesAndName.forEach(function (sector) {
          sector.coordinates.forEach(function (coordinate) {
            bounds.push(L.latLng(coordinate[0], coordinate[1]));
          });
        });
        controller.set('bounds', bounds);
        var startZonesCoordinates = _coordinatesMapper.default.switchLatitudeLongitudeOfPoints(sectors.mapBy('startZone'));

        controller.set('sectors', sectorsCoordinatesAndName);
        controller.set('startZonesLocations', startZonesCoordinates);
      });

      // Indicateur d'exécution de requête
      controller.set('isLoadingSave', false);
    },


    actions: {
      save: function save() {
        var _this = this;

        this.set('controller.isLoadingSave', true);
        (0, _sweetalert.default)({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment valider ces affectations ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          var agents = _this.get('controller.model.agents').map(function (agent) {
            agent.set('sector', agent.get('sector.id'));
            return agent;
          });
          var url = _environment.default.APP.host + '/gis/api/agents/sectorAssignment';
          _this.get('ajax').request(url, {
            method: 'PUT',
            headers: {
              Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
              City: _this.get('currentUser.city')
            },
            data: agents,
            contentType: 'application/json'
          }).then(function () {
            _this.get('notifications').success('Les affectations ont bien été enregistrées');
            _this.refresh();
          }).catch(function () {
            _this.get('notifications').error('Les affectations n\'ont pas pu être enregistrées');
          });
        }).catch(function () {
          /* aucun traitement, mais on fait disparaître l'erreur dans la console */
        }).finally(function () {
          return _this.set('controller.isLoadingSave', false);
        });
      }
    }
  });
});