define('sags-front-bo/adapters/application', ['exports', 'ember-data', 'sags-front-bo/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    currentUser: Ember.inject.service('current-user'),

    host: _environment.default.APP.host,
    authorizer: 'authorizer:token',

    // par exemple, transformer "parkingTickets" en "parking-tickets"
    pathForType: function pathForType(type) {
      type = this._super(type);

      return Ember.String.dasherize(type);
    },

    headers: Ember.computed(function () {
      return {
        City: this.get('currentUser').get('city')
      };
    }).volatile()
  });
});