define('sags-front-bo/helpers/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = add;
  function add(params) {
    return params[0] + params[1];
  }

  exports.default = Ember.Helper.helper(add);
});