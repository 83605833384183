define('sags-front-bo/routes/rapo/edition', ['exports', 'sags-front-bo/utils/dictionary', 'sags-front-bo/mixins/access-controller'], function (exports, _dictionary, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('sendingTypes', _dictionary.default.convertToArray(_dictionary.default.responseSendingType));
      controller.set('serverSideFilters.creationDate.value', new Date());
      controller.set('filters.sendingType.value', '*');
      controller.get('refreshWithServerSideFilter')(controller, this.store);
    }
  });
});