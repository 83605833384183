define('sags-front-bo/utils/map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var textMarkupTemplate = '<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="40px"><path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".0"/><path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"/><path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="#30B208"/><text x="13" y="17" font-weight="bold" font-size="6pt" text-anchor="middle" fill="#fff">${text}</text></svg>';
  // construit un SVG écrit en blanc sur fond noir
  var textTemplate = '\n  <svg xmlns="http://www.w3.org/2000/svg" width="90px" height="40px">\n    <text x="0" y="25" stroke="black" font-size="16px" font-family="Arial">\n      label\n    </text>\n    <text x="0" y="25" fill="white" font-size="16px" font-family="Arial">\n      label\n    </text>\n  </svg>\n';
  function addTextMarker(map, position, lat, lon) {
    var markup = textMarkupTemplate.replace('${text}', position),
        icon = new H.map.Icon(markup, {
      size: new H.math.Size(70, 50)
    }),
        marker = new H.map.Marker({
      lat: lat,
      lng: lon
    }, {
      icon: icon
    });

    map.addObject(marker);

    return marker;
  }

  function getCentroid(polygonCoords) {
    // https://stackoverflow.com/questions/22796520/finding-the-center-of-leaflet-polygon
    var twoTimesSignedArea = 0;
    var cxTimes6SignedArea = 0;
    var cyTimes6SignedArea = 0;

    var length = polygonCoords.length;

    var x = function x(i) {
      return polygonCoords[i % length][0];
    };
    var y = function y(i) {
      return polygonCoords[i % length][1];
    };

    for (var i = 0; i < polygonCoords.length; i++) {
      var twoSA = x(i) * y(i + 1) - x(i + 1) * y(i);
      twoTimesSignedArea += twoSA;
      cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA;
      cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA;
    }

    var sixSignedArea = 3 * twoTimesSignedArea;
    return {
      lng: cxTimes6SignedArea / sixSignedArea,
      lat: cyTimes6SignedArea / sixSignedArea
    };
  }

  // retourne le point central d'une liste de coordonnees en une dimension
  function centerAggregatedSection(aggregatedSection) {
    // On supprime l'axe z qui vaut toujours 0
    var tmp = aggregatedSection.filter(function (n) {
      return n !== 0;
    });
    var sections2D = [];
    for (var i = 0; i < tmp.length; i += 2) {
      sections2D.push([tmp[i], tmp[i + 1]]);
    }

    // la latitude est à l'indexe 0 des sous tableaux, latitude 1
    var initialValue = 0;
    var latitude = sections2D.reduce(function (acc, value) {
      return value[0] + acc;
    }, initialValue) / sections2D.length;
    var longitude = sections2D.reduce(function (acc, value) {
      return value[1] + acc;
    }, initialValue) / sections2D.length;

    return [latitude, longitude];
  }

  function textSVG(label, lat, lon) {
    var markup = textTemplate.trim().replace(new RegExp('label', 'g'), label);
    var icon = new H.map.Icon(markup);

    return new H.map.Marker({ lat: lat, lng: lon }, { icon: icon });
  }

  exports.addTextMarker = addTextMarker;
  exports.getCentroid = getCentroid;
  exports.textSVG = textSVG;
  exports.centerAggregatedSection = centerAggregatedSection;
});