define('sags-front-bo/models/fps', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = Ember.Inflector.inflector;
  inflector.irregular('fps', 'fps');

  exports.default = _emberData.default.Model.extend({
    legalId: _emberData.default.attr('string'),
    externalId: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    vehiclePlate: _emberData.default.attr('string'),
    vehiclePlateCountry: _emberData.default.attr('string'),
    vehicleBrand: _emberData.default.attr('string'),
    vehicleModel: _emberData.default.attr('string'),
    agent: _emberData.default.attr(),
    creationDate: _emberData.default.attr('date'),
    validityDate: _emberData.default.attr('date'),
    modificationDate: _emberData.default.attr('date'),
    reducedDate: _emberData.default.attr('date'),
    paymentStatus: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    reducedAmount: _emberData.default.attr('number'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    parentLegalId: _emberData.default.attr('string'),
    significantParkingTickets: _emberData.default.attr(),
    streetNumber: _emberData.default.attr('string'),
    streetNumberBis: _emberData.default.attr('string'),
    streetType: _emberData.default.attr('string'),
    streetName: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('number'),
    city: _emberData.default.attr('string'),
    tariffZone: _emberData.default.attr('string'),
    etag: _emberData.default.attr('string'),
    hasRapo: _emberData.default.attr('boolean'),
    hasCcspClaim: _emberData.default.attr('boolean'),
    // rempli seulement à la création de RAPO
    rapoMotivations: _emberData.default.attr(),
    authTransfertDatetime: _emberData.default.attr('date'),
    cancelReason: _emberData.default.attr('string'),
    cancelComment: _emberData.default.attr('string'),
    cancelDatetime: _emberData.default.attr('date'),
    cancelledByAgentId: _emberData.default.belongsTo('user'), // agent qui a annulé le FPS
    controlComment: _emberData.default.attr('string'),
    surcharge: _emberData.default.attr('number'),
    terminalId: _emberData.default.attr('string')
  });
});