define('sags-front-bo/components/access-control-aware-menu', ['exports', 'sags-front-bo/helpers/control-access', 'lodash'], function (exports, _controlAccess, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hasSubmenu: false,
    isOpened: false,
    fa: Ember.computed('isOpened', function () {
      return this.get('isOpened') ? 'minus' : 'plus';
    }),

    hasAnythingToDisplay: Ember.computed('options.@each.target', 'currentUser.user.role', function () {
      var _this = this;

      if (!_lodash.default.isNil(this.get('currentUser.user.role'))) {
        var hasAnythingVisible = false;
        this.get('options').forEach(function (option) {
          if (_controlAccess.default.compute([option.target, _this.get('currentUser')])) {
            hasAnythingVisible = true;
          }
        });
        return hasAnythingVisible;
      }

      return false;
    }),

    actions: {
      showMenu: function showMenu() {
        this.toggleProperty('isOpened');
      }
    }
  });
});