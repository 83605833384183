define('sags-front-bo/serializers/team', ['exports', 'sags-front-bo/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      agents: {
        serialize: 'ids',
        deserialize: 'ids'
      }
    }
  });
});