define('sags-front-bo/serializers/vehicle-brand', ['exports', 'sags-front-bo/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var vehicleBrands = payload['vehicle-brand'].favoriteBrands;
      vehicleBrands.pushObjects(payload['vehicle-brand'].brands);
      vehicleBrands = vehicleBrands.map(function (brand) {
        return { name: brand };
      });

      return this._super(store, primaryModelClass, vehicleBrands, id, requestType);
    },


    primaryKey: 'name'
  });
});