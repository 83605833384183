define('sags-front-bo/models/ccsp-claim', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    claimerName: _emberData.default.attr('string'),
    agentId: _emberData.default.attr('number'),
    fpsLegalId: _emberData.default.attr('string'),
    fpsExternalId: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    ccspClaimRecourseDate: _emberData.default.attr('date'),
    transmissionDateToCity: _emberData.default.attr('date'),
    transmissionDateFromCity: _emberData.default.attr('date'),
    medLimitProductionDate: _emberData.default.attr('date'),
    decision: _emberData.default.attr('string'),
    actions: _emberData.default.hasMany('ccsp-claim-action-event'),
    tag: _emberData.default.attr('string'),
    comments: _emberData.default.hasMany('ccsp-claim-comment'),
    attachments: _emberData.default.attr('', { defaultValue: function defaultValue() {
        return [];
      } }),
    number: Ember.computed('fpsLegalId', function () {
      return 'CCSP_CLAIM_' + this.get('fpsLegalId');
    })
  });
});