define('sags-front-bo/models/rapo-action-event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    rapo: _emberData.default.belongsTo('rapo'),
    agent: _emberData.default.belongsTo('user'),
    dateTime: _emberData.default.attr('string'),
    action: _emberData.default.attr('string')
  });
});