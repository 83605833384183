define('sags-front-bo/controllers/rapo/index', ['exports', 'moment', 'sags-front-bo/utils/dictionary', 'lodash'], function (exports, _moment, _dictionary, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service('current-user'),
    tableColumns: [{
      'key': 'number',
      'displayName': 'Référence du RAPO',
      'customComponent': 'link-to-detail'
    }, {
      'key': 'status',
      'displayName': 'Statut',
      transform: function transform(statusId) {
        return _dictionary.default.rapoStatus[statusId];
      }
    }, {
      'key': 'date',
      'displayName': 'Date du recours',
      transform: function transform(date) {
        return (0, _moment.default)(date).format('DD/MM/YYYY');
      }
    }, {
      'key': 'fpsLegalId',
      'displayName': 'Référence du FPS'
    }, {
      'key': 'vehiclePlate',
      'displayName': 'Immat. du véhicule'
    }, {
      'key': 'motivations',
      'displayName': 'Motif(s) du RAPO',
      transform: function transform(motivationIds) {
        if (!_lodash.default.isArray(motivationIds)) {
          return '';
        }

        return motivationIds.map(function (motivationId) {
          return _dictionary.default.rapoMotivationShort[motivationId];
        }).join(', ');
      }
    }, {
      'key': 'lockedByAgent.fullName',
      'displayName': 'Réservé par'
    }, {
      'key': 'issue',
      'displayName': 'Issue du RAPO',
      transform: function transform(issue) {
        return _dictionary.default.rapoIssue[issue];
      }
    }, {
      'key': 'limitCompletionDate',
      'displayName': 'Date limite de complétude du dossier',
      transform: function transform(limitCompletionDate) {
        return _lodash.default.isNil(limitCompletionDate) ? null : (0, _moment.default)(limitCompletionDate).format('DD/MM/YYYY');
      }
    }],
    // filtres actifs sur la liste
    filters: {
      number: {
        comparator: 'contains',
        value: ''
      },
      fpsLegalId: {
        comparator: 'contains',
        value: ''
      },
      vehiclePlate: {
        comparator: 'contains',
        value: ''
      },
      status: {
        comparator: 'equals',
        value: 'TO_INSTRUCT'
      },
      motivations: {
        comparator: 'arrayContains',
        value: '*'
      }
    },

    serverSideFilters: {
      startDatetime: {
        comparator: 'dateEquals'
      },
      endDatetime: {
        comparator: 'dateEquals'
      }
    },

    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller, store) {
      var startDatetime = controller.get('serverSideFilters.startDatetime.value');
      var endDatetime = controller.get('serverSideFilters.endDatetime.value');
      if (_lodash.default.isNil(startDatetime) || _lodash.default.isNil(endDatetime)) {
        if (_lodash.default.isNil(startDatetime)) {
          var lastMonth = new Date();
          lastMonth.setDate(lastMonth.getDate() - 30);
          lastMonth.setHours(0, 0, 0, 0);
          controller.set('serverSideFilters.startDatetime.value', lastMonth);
        } else {
          var today = new Date();
          today.setHours(23, 59, 59, 999);
          controller.set('serverSideFilters.endDatetime.value', today);
        }
      } else {
        startDatetime.setHours(0, 0, 0, 0);
        endDatetime.setHours(23, 59, 59);
        controller.set('content', store.query('rapo', {
          startDatetime: startDatetime.toISOString(),
          endDatetime: endDatetime.toISOString()
        }));
      }
    },


    possibleStatuses: _dictionary.default.convertToArray(_dictionary.default.rapoStatus, [{ key: '*', value: 'Tous' }]),
    possibleMotivations: _dictionary.default.convertToArray(_dictionary.default.rapoMotivationShort, [{ key: '*', value: 'Tous' }]),
    computeBackgroundColor: function computeBackgroundColor(one, elementId, currentUser) {
      one.set('computedClass_' + elementId, Ember.computed('lockedByAgent.id', function () {
        var lockerAgentId = one.get('lockedByAgent.id');
        if (lockerAgentId === currentUser.get('user.id')) {
          return 'bg-info';
        } else if (!_lodash.default.isNil(lockerAgentId)) {
          return 'bg-danger';
        }

        return '';
      }));
    },

    linkComponentConfig: {
      targetRoute: 'rapo.detail',
      targetItem: 'number'
    },
    sortByDate: ['date:asc'],
    sortedRapos: Ember.computed.sort('model', 'sortByDate'),
    actions: {
      setStartDatetime: function setStartDatetime() {
        this.refreshWithServerSideFilter(this, this.store);
      },
      setEndDatetime: function setEndDatetime() {
        this.refreshWithServerSideFilter(this, this.store);
      }
    }

  });
});