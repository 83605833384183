define('sags-front-bo/models/device', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    imei: _emberData.default.attr('string'),
    voIP: _emberData.default.attr('string'),
    brand: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    state: _emberData.default.attr('string', { defaultValue: 'WORKING' }),
    cities: _emberData.default.attr({ defaultValue: function defaultValue() {
        return [];
      } }),
    assignedToId: _emberData.default.belongsTo('user', { inverse: null })
  });
});