define('sags-front-bo/components/aria-checkbox', ['exports', 'ember-aria-checkbox/components/aria-checkbox'], function (exports, _ariaCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ariaCheckbox.default;
    }
  });
});