define('sags-front-bo/models/tour', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    userType: _emberData.default.attr('string'), // tournée agent ou lapi
    tourType: _emberData.default.attr('string'), // type de la tournée
    status: _emberData.default.attr('string'), // statut de la tournée
    startDate: _emberData.default.attr('date'), // date de début de la tournée
    endDate: _emberData.default.attr('date'), // date de fin de la tournée
    effectiveEndDate: _emberData.default.attr('date'), // date de fin réelle de la tournée
    route: _emberData.default.attr(''), // itinéraire de la tournée
    aggregatedSections: _emberData.default.attr('') // tronçons agrégés de la tournée
  });
});