define('sags-front-bo/components/maps/here-map-point-sections', ['exports', 'sags-front-bo/config/environment', 'lodash'], function (exports, _environment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var platform = new H.service.Platform({
    app_id: _environment.default.APP.here.id,
    app_code: _environment.default.APP.here.code,
    useCIT: _environment.default.APP.here.cit,
    useHTTPS: _environment.default.APP.here.https,
    apikey: _environment.default.APP.here.keyApi
  });
  var defaultLayers = platform.createDefaultLayers();
  var defaultColor = '#829';

  exports.default = Ember.Component.extend({
    agentsPositions: null,
    aggregatedSections: null,
    zonesColor: null,
    selectedPolygon: null,

    attachTooltipToPoint: function attachTooltipToPoint(map, ui, point, text) {
      // Afficher une infobulle des informations du point au clic
      point.addEventListener('tap', function (evt) {
        var cursorPosition = map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
        var bubble = new H.ui.InfoBubble(cursorPosition, {
          content: text
        });

        // Supprimer toutes les infobulles
        ui.getBubbles().forEach(function (bubs) {
          ui.removeBubble(bubs);
        });

        // Afficher l'infobulle
        ui.addBubble(bubble);
      }, false);
    },
    didInsertElement: function didInsertElement() {
      // Afficher la carte
      var map = new H.Map(this.$('#map')[0], defaultLayers.vector.normal.map, {
        center: { lat: 47, lng: 5 },
        zoom: 5.6
      });

      // Gestion du zoom et interface par défaut
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      var ui = H.ui.UI.createDefault(map, defaultLayers);

      // Affichage de la position des agents
      var agentsPositions = this.get('agentsPositions');

      var iconName = 'default_marker.png';

      if (!_lodash.default.isNil(agentsPositions)) {
        for (var indexAgentPositions = 0; indexAgentPositions < agentsPositions.get('length'); indexAgentPositions++) {
          var agent = agentsPositions.objectAt(indexAgentPositions);
          var latitude = agent.get('latitude');
          var longitude = agent.get('longitude');
          var tooltip = agent.get('tooltip');
          if (!_lodash.default.isNil(latitude) && !_lodash.default.isNil(longitude)) {
            var userCoord = { lat: latitude, lng: longitude };
            var marker = this.addMarker(userCoord, _environment.default.rootURL + 'assets/images/' + iconName);
            if (!_lodash.default.isNil(tooltip)) {
              this.get('attachTooltipToPoint')(map, ui, marker, tooltip);
            }
            map.addObject(marker);
          }
        }
      }

      var aggregatedSections = this.get('aggregatedSections');
      if (!_lodash.default.isNil(aggregatedSections)) {
        for (var indexAggSection = 0; indexAggSection < aggregatedSections.get('length'); indexAggSection++) {
          this.send('addPolygonToMap', ui, map, aggregatedSections.objectAt(indexAggSection));
        }
      }

      this.set('map', map);
    },


    /**
     * Créer un marker de map
     *
     * @param {Object} coord - Coordonnées du marker à créer
     * @param {Object} iconPath - Chemin de l'icône du marker
     * @return {Object} Marker créé
     */
    addMarker: function addMarker(coord, iconPath) {
      var car = new H.map.Icon(iconPath);
      var newMarker = new H.map.Marker(coord, { icon: car });

      return newMarker;
    },


    // Fonction pour récupérer les coordonnées du tronçon agrégé
    getCoordinatesAggregatedSection: function getCoordinatesAggregatedSection(aggregatedSection) {
      var polygonCoordinates = [];
      var coordinates = aggregatedSection.get('coordinates');
      for (var indexCoordinate = 0; indexCoordinate < coordinates.length; indexCoordinate++) {
        var coordinate = coordinates.objectAt(indexCoordinate);
        polygonCoordinates.push(coordinate[1]);
        polygonCoordinates.push(coordinate[0]);
        polygonCoordinates.push(0);
      }
      return polygonCoordinates;
    },


    actions: {
      // Fonction pour dessiner un polygone sur une carte
      addPolygonToMap: function addPolygonToMap(ui, map, aggregatedSection) {
        var _this = this;

        var polygonCoordinates = this.get('getCoordinatesAggregatedSection')(aggregatedSection);
        var lineString = new H.geo.LineString(polygonCoordinates);
        var color = aggregatedSection.get('color');
        var strokeColor = aggregatedSection.get('strokeColor');
        if (_lodash.default.isNil(color)) {
          color = defaultColor;
        }
        if (_lodash.default.isNil(strokeColor)) {
          strokeColor = defaultColor;
        }
        var polygon = new H.map.Polygon(lineString, {
          style: {
            fillColor: color,
            strokeColor: strokeColor,
            lineWidth: 5
          }
        });

        if (this.get('isOnClickActivated')) {
          // Afficher les informations du tronçon agrégé au clic
          polygon.addEventListener('tap', function () {
            _this.sendAction('clickAggregatedSection', aggregatedSection);

            // on repasse l'ancien polygone sélectionné à la couleur par défaut
            if (!_lodash.default.isNil(_this.get('selectedPolygon'))) {
              _this.get('selectedPolygon').setStyle({
                fillColor: defaultColor,
                strokeColor: defaultColor
              });
            }

            polygon.setStyle({
              fillColor: '#d37be2',
              strokeColor: 'black'
            });
            _this.set('selectedPolygon', polygon);
          }, false);
        }

        map.addObject(polygon);
      }
    }
  });
});