define('sags-front-bo/adapters/fps', ['exports', 'sags-front-bo/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'control/api',

    urlForUpdateRecord: function urlForUpdateRecord() {
      var url = this._super.apply(this, arguments);

      // utiliser l'API publique de mise à jour de FPS
      // (utilisée seulement à la création d'un RAPO)
      return url.replace('control/api/fps', 'control/api/public/fps-rapo');
    }
  });
});