define('sags-front-bo/adapters/ccsp-claim', ['exports', 'sags-front-bo/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'control/api',
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      return [payload];
    }
  });
});