define('sags-front-bo/adapters/user', ['exports', 'sags-front-bo/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'api',

    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        // on veut obtenir l'utilisateur connecté
        delete query.me;
        return this._super.apply(this, arguments) + '/../account';
      }

      return this._super.apply(this, arguments);
    }
  });
});