define('sags-front-bo/components/ccsp-claim/tag', ['exports', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment', 'moment', 'lodash'], function (exports, _dictionary, _environment, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tags: _dictionary.default.convertToArray(_dictionary.default.ccspClaimTags),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var findByKey = function findByKey(options, key) {
        var keyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'key';

        var results = options.filterBy(keyName, key);
        if (!_lodash.default.isEmpty(results)) {
          return results[0];
        }
        results = options.filterBy('value', key);
        if (!_lodash.default.isEmpty(results) && results.length === 1) {
          return results[0];
        }
        return null;
      };
      this.set('tag', findByKey(this.get('tags'), this.get('ccspClaim.tag')));
    },


    actions: {
      selectTag: function selectTag(tag) {
        this.set('tag', tag);
      },
      updateTag: function updateTag(id) {
        var _this = this;

        this.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/tag', {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json',
          data: {
            ccspClaimId: id,
            tag: this.get('tag.key')
          }
        }).then(function () {
          var action = _this.get('store').createRecord('ccsp-claim-action-event', {
            'action': 'UPDATE_TAG',
            'dateTime': (0, _moment.default)(),
            'agent': _this.get('currentUser.user'),
            'ccspClaim': _this.get('ccspClaim')
          });
          _this.get('updateActionEvents')(action);
          _this.get('notifications').success('Le motif a été mis à jour avec succès');
        }).catch(function () {
          _this.get('notifications').error('Une erreur s\'est produite lors de la mise à jour du motif');
        });
      }
    }
  });
});