define('sags-front-bo/models/user', ['exports', 'ember-data', 'lodash'], function (exports, _emberData, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    identification: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    authorizedNumber: _emberData.default.attr('string'),
    supervisor: _emberData.default.belongsTo('user', { inverse: null }),
    photo: _emberData.default.attr('string'),
    photoBase64: _emberData.default.attr('string'), // présent seulement pour la création d'utilisateur
    drivingLicense: _emberData.default.attr('boolean', { defaultValue: false }),
    role: _emberData.default.attr('string'),
    voIP: _emberData.default.attr('string'),
    humanResourceId: _emberData.default.attr('string'),
    contractedWorkingHoursPerWeek: _emberData.default.attr('number'),
    cities: _emberData.default.attr({ defaultValue: function defaultValue() {
        return [];
      } }),
    status: _emberData.default.attr('string', { defaultValue: 'INITIALIZED' }),
    team: _emberData.default.belongsTo('team', { inverse: null }),
    userCities: _emberData.default.attr(),

    // spécifiques gateway
    imageUrl: _emberData.default.attr('string'),
    authorities: _emberData.default.attr(),

    fullName: Ember.computed('firstName', 'lastName', function () {
      if (_lodash.default.isNil(this.get('firstName')) || _lodash.default.isNil(this.get('lastName'))) {
        // chargement en cours
        return '...';
      }

      return this.get('firstName') + ' ' + this.get('lastName');
    })
  });
});