define('sags-front-bo/routes/rapo/index', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      // valeur par défaut du filtre
      var startDatetime = new Date();
      startDatetime.setDate(startDatetime.getDate() - 30);
      controller.set('serverSideFilters.startDatetime.value', startDatetime);
      controller.set('serverSideFilters.endDatetime.value', new Date());
      controller.get('refreshWithServerSideFilter')(controller, this.store);
    }
  });
});