define('sags-front-bo/adapters/aggregated-section', ['exports', 'sags-front-bo/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'gis/api',

    query: function query(store, type, _query) {
      // déplacer les query params "latitude" et "longitude" dans les headers
      var headers = {};
      headers.latitude = _query.latitude;
      headers.longitude = _query.longitude;
      delete _query.latitude;
      delete _query.longitude;

      var url = this.buildURL(type.modelName, null, null, 'query', _query);

      return this.ajax(url, 'GET', { data: _query, headers: headers });
    }
  });
});