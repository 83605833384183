define('sags-front-bo/serializers/application', ['exports', 'ember-data', 'lodash'], function (exports, _emberData, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var wrapPayload = function wrapPayload(payload, typeName) {
    var wrapped = {};
    wrapped[typeName] = payload;
    return wrapped;
  };

  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeResponse: function normalizeResponse(store, modelClass, payload, id, requestType) {
      var normalizedPayload = payload;
      // les pages et tableaux ne doivent pas être traitées ici
      if (payload.content === undefined && !_lodash.default.isArray(payload)) {
        normalizedPayload = wrapPayload(payload, modelClass.modelName);
      }
      return this._super(store, modelClass, normalizedPayload, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var metadata = void 0;
      if (payload.content) {
        // paged response
        metadata = _lodash.default.omit(payload, 'content');
        payload = payload.content;
      }

      var modelName = primaryModelClass.modelName;
      if (modelName !== 'fps') {
        modelName += 's';
      }

      var wrapped = wrapPayload(payload, modelName);
      if (metadata) {
        wrapped.meta = metadata.meta;
      }
      return this._super(store, primaryModelClass, wrapped, id, requestType);
    },


    // Suppression du json root à la serialisation
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.merge(hash, this.serialize(record, options));
    },

    // Gestion des suppression de manière à ce que le retour en 200 ne pose pas de problème
    normalizeDeleteRecordResponse: function normalizeDeleteRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var payload_ = {};
      payload_[primaryModelClass.modelName] = { id: id };
      return this._super(store, primaryModelClass, payload_, id, requestType);
    }
  });
});