define('sags-front-bo/controllers/observations/index', ['exports', 'moment', 'lodash'], function (exports, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    tableColumns: [{
      'key': 'id',
      'displayName': 'Numéro',
      'customComponent': 'link-to-detail',
      transform: function transform(id) {
        return 'Observation ' + id;
      }
    }, {
      'key': 'datetime',
      'displayName': 'Date de constatation',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm');
      }
    }, {
      'key': 'address',
      'displayName': 'Adresse'
    }, {
      'key': 'categoryName',
      'displayName': 'Catégorie'
    }, {
      'key': 'reason',
      'displayName': 'Motif'
    }],

    linkComponentConfig: {
      targetRoute: 'observations.detail'
    },

    filters: {
      reason: {
        comparator: 'equals',
        value: '*'
      },
      categoryName: {
        comparator: 'equals',
        value: '*'
      },
      address: {
        comparator: 'contains',
        value: ''
      }
    },

    serverSideFilters: {
      datetime: {
        comparator: 'dateEquals',
        value: new Date()
      }
    },

    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller, store) {
      var datetime = controller.get('serverSideFilters.datetime.value');
      if (_lodash.default.isNil(datetime)) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        controller.set('serverSideFilters.datetime.value', today);

        // Filtre par la date d'aujourd'hui
        controller.set('content', store.query('observation', {
          datetime: today.toISOString()
        }));
      } else {
        datetime.setHours(0, 0, 0, 0);
        controller.set('content', store.query('observation', {
          datetime: datetime.toISOString()
        }));
      }
    },


    actions: {
      changeDatetime: function changeDatetime() {
        this.refreshWithServerSideFilter(this, this.store);
      },
      changeCategory: function changeCategory(category) {
        this.set('isCategorySelected', _lodash.default.isNil(category));
        this.send('changeReason', null);

        this.set('category', category);
        if (_lodash.default.isNil(category)) {
          this.set('filters.categoryName.value', '*');
        } else {
          this.set('filters.categoryName.value', category.key);
          this.set('reasons', category.value);
        }
      },
      changeReason: function changeReason(reason) {
        this.set('reason', reason);
        if (_lodash.default.isNil(reason)) {
          this.set('filters.reason.value', '*');
        } else {
          this.set('filters.reason.value', reason.reason);
        }
      }
    }
  });
});