define('sags-front-bo/components/rapo/instruction/old-instructions-responses', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isCession: Ember.computed('rapo.motivations', function () {
      return this.get('rapo.motivations')[0] === '13'; // Cession véhicule
    })
  });
});