define('sags-front-bo/models/agent', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    identification: _emberData.default.attr('string'),
    sector: _emberData.default.belongsTo('sector', { inverse: null }),
    longitude: _emberData.default.attr('number'),
    latitude: _emberData.default.attr('number'),
    positionDate: _emberData.default.attr('date')
  });
});