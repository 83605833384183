define('sags-front-bo/utils/converter', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.base64toObjectUrl = undefined;


  function base64toObjectUrl(b64Data) {
    var contentType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var sliceSize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 512;

    if (!_lodash.default.isNil(b64Data)) {
      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return window.URL.createObjectURL(blob);
    }

    return b64Data;
  }

  exports.base64toObjectUrl = base64toObjectUrl;
});