define('sags-front-bo/controllers/rapo/create', ['exports', 'sags-front-bo/utils/file-input-to-model', 'lodash', 'sags-front-bo/utils/dictionary'], function (exports, _fileInputToModel, _lodash, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    availableCivilities: _dictionary.default.convertToPowerSelectOptions(_dictionary.default.civilities),
    availableStreetNumberBis: _dictionary.default.convertToPowerSelectOptions(_dictionary.default.streetNumberBis),
    availableStreetTypes: _dictionary.default.convertToPowerSelectOptions(_dictionary.default.streetType),
    availableQualities: _dictionary.default.convertToPowerSelectOptions(_dictionary.default.claimerQuality),
    showBuyerSection: false,
    attachments: [],

    validationErrorMessage: Ember.computed('model.claimerCivility', 'model.claimerStreetType', 'model.claimerQuality', 'model.mandataryCivility', 'model.mandataryStreetType', 'model.date', 'model.motivations', 'attachments.@each.type', 'hasMandatary', 'rapoForm.base64', 'model.newBuyer.civility', 'model.newBuyer.lastName', 'model.newBuyer.firstName', 'model.newBuyer.streetType', 'model.newBuyer.streetName', 'model.newBuyer.zipCode', 'model.newBuyer.city', 'model.newBuyer.country', function () {
      var missingInfo = [];
      if (_lodash.default.isEmpty(this.get('model.claimerCivility'))) {
        missingInfo.push('civilité du demandeur');
      }
      if (_lodash.default.isEmpty(this.get('model.claimerStreetType'))) {
        missingInfo.push('type de voie du demandeur');
      }
      if (_lodash.default.isEmpty(this.get('model.claimerQuality'))) {
        missingInfo.push('qualité du demandeur');
      }
      if (this.get('hasMandatary')) {
        if (_lodash.default.isEmpty(this.get('model.mandataryCivility'))) {
          missingInfo.push('civilité du mandataire');
        }
        if (_lodash.default.isEmpty(this.get('model.mandataryStreetType'))) {
          missingInfo.push('type de voie du mandataire');
        }
      }
      if (_lodash.default.isNil(this.get('model.date'))) {
        missingInfo.push('date de la demande');
      }
      if (_lodash.default.isNil(this.get('model.motivations'))) {
        missingInfo.push('motif de contestation');
      }
      if (_lodash.default.isNil(this.get('rapoForm.base64'))) {
        missingInfo.push('formulaire RAPO numérisé');
      }
      if (this.get('showBuyerSection')) {
        if (_lodash.default.isEmpty(this.get('model.newBuyer.civility'))) {
          missingInfo.push('civilité de l\'acquéreur');
        } else {
          if (this.get('model.newBuyer.civility') !== 'COM') {
            if (_lodash.default.isEmpty(this.get('model.newBuyer.lastName'))) {
              missingInfo.push('nom de l\'acquéreur');
            }
            if (_lodash.default.isEmpty(this.get('model.newBuyer.firstName'))) {
              missingInfo.push('prénom de l\'acquéreur');
            }
          } else {
            if (_lodash.default.isEmpty(this.get('model.newBuyer.lastName'))) {
              missingInfo.push('raison sociale de l\'acquéreur');
            }
          }
        }
        if (_lodash.default.isEmpty(this.get('model.newBuyer.streetType'))) {
          missingInfo.push('type de voie de l\'acquéreur');
        }
        if (_lodash.default.isEmpty(this.get('model.newBuyer.streetName'))) {
          missingInfo.push('nom de voie de l\'acquéreur');
        }
        if (_lodash.default.isEmpty(this.get('model.newBuyer.zipCode'))) {
          missingInfo.push('code postal de l\'acquéreur');
        }
        if (_lodash.default.isEmpty(this.get('model.newBuyer.city'))) {
          missingInfo.push('ville de l\'acquéreur');
        }
        if (_lodash.default.isEmpty(this.get('model.newBuyer.country'))) {
          missingInfo.push('pays de l\'acquéreur');
        }
      }

      var typeOK = true;
      this.get('attachments').forEach(function (attachment) {
        if (_lodash.default.isEmpty(attachment.type)) {
          typeOK = false;
        }
      });
      if (!typeOK) {
        missingInfo.push('type d\'un ou plusieurs justificatifs');
      }

      if (_lodash.default.isEmpty(missingInfo)) {
        // pas d'erreur de validation
        return null;
      }
      return 'Les informations suivantes sont manquantes : ' + missingInfo.join(', ') + '.';
    }),

    pdfContent: Ember.computed('rapoForm.base64', function () {
      var rapoForm = this.get('rapoForm');
      if (!_lodash.default.isNil(rapoForm) && !_lodash.default.isNil(rapoForm.base64)) {
        return 'data:application/pdf;base64,' + rapoForm.base64;
      }
      return null;
    }),

    fpsLegalId: Ember.computed('siret', 'fpsLegalIdEnd', function () {
      return this.get('siret') + this.get('fpsLegalIdEnd');
    }),

    mandataryUnchecked: Ember.observer('hasMandatary', function () {
      if (!this.get('hasMandatary') && !this.get('model.isDeleted')) {
        this.set('model.mandataryCivility', null);
        this.set('model.mandataryLastName', null);
        this.set('model.mandataryFirstName', null);
        this.set('model.mandataryStreetNumber', null);
        this.set('model.mandataryStreetNumberBis', null);
        this.set('model.mandataryStreetType', null);
        this.set('model.mandataryStreetName', null);
        this.set('model.mandataryAdditionalAddress', null);
        this.set('model.mandataryZipCode', null);
        this.set('model.mandataryCity', null);
        this.set('model.mandataryCountry', null);
        this.set('model.mandataryEmail', null);

        this.set('selectedMandataryCivility', undefined);
        this.set('selectedMandataryStreetNumberBis', undefined);
        this.set('selectedMandataryStreetType', undefined);
        this.set('selectedMandataryCountry', undefined);
      }
    }),

    claimerCivilityChanged: Ember.observer('model.claimerCivility', function () {
      if (this.get('model.claimerCivility') === 'COM') {
        this.set('model.claimerFirstName', undefined);
      }
    }),

    mandataryCivilityChanged: Ember.observer('model.mandataryCivility', function () {
      if (this.get('model.mandataryCivility') === 'COM') {
        this.set('model.mandataryFirstName', undefined);
      }
    }),

    actions: {
      selectVehicleBrand: function selectVehicleBrand(selected) {
        this.set('selectedVehicleBrand', selected);
        this.set('model.vehicleBrand', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectClaimerCivility: function selectClaimerCivility(selected) {
        this.set('selectedClaimerCivility', selected);
        this.set('model.claimerCivility', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectClaimerStreetNumberBis: function selectClaimerStreetNumberBis(selected) {
        this.set('selectedClaimerStreetNumberBis', selected);
        this.set('model.claimerStreetNumberBis', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectClaimerStreetType: function selectClaimerStreetType(selected) {
        this.set('selectedClaimerStreetType', selected);
        this.set('model.claimerStreetType', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectClaimerCountry: function selectClaimerCountry(selected) {
        this.set('selectedClaimerCountry', selected);
        this.set('model.claimerCountry', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectClaimerQuality: function selectClaimerQuality(selected) {
        this.set('selectedClaimerQuality', selected);
        this.set('model.claimerQuality', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectMandataryCivility: function selectMandataryCivility(selected) {
        this.set('selectedMandataryCivility', selected);
        this.set('model.mandataryCivility', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectMandataryStreetNumberBis: function selectMandataryStreetNumberBis(selected) {
        this.set('selectedMandataryStreetNumberBis', selected);
        this.set('model.mandataryStreetNumberBis', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectMandataryStreetType: function selectMandataryStreetType(selected) {
        this.set('selectedMandataryStreetType', selected);
        this.set('model.mandataryStreetType', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectMandataryCountry: function selectMandataryCountry(selected) {
        this.set('selectedMandataryCountry', selected);
        this.set('model.mandataryCountry', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectBuyerCivility: function selectBuyerCivility(selected) {
        this.set('selectedBuyerCivility', selected);
        this.set('model.newBuyer.civility', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectBuyerStreetNumberBis: function selectBuyerStreetNumberBis(selected) {
        this.set('selectedBuyerStreetNumberBis', selected);
        this.set('model.newBuyer.streetNumberBis', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectBuyerStreetType: function selectBuyerStreetType(selected) {
        this.set('selectedBuyerStreetType', selected);
        this.set('model.newBuyer.streetType', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectBuyerCountry: function selectBuyerCountry(selected) {
        this.set('selectedBuyerCountry', selected);
        this.set('model.newBuyer.country', _lodash.default.isNil(selected) ? null : selected.name);
      },
      selectMotivation: function selectMotivation(selected) {
        this.set('selectedMotivation', selected);
        this.set('model.motivations', _lodash.default.isNil(selected) ? null : [selected.name]);
        // le cas 13 correspond au motif de rapo cession de véhicule
        this.set('showBuyerSection', !_lodash.default.isNil(selected) && selected.name === '13');
      },
      selectAttachmentType: function selectAttachmentType(index, selected) {
        var attachment = this.get('attachments')[index];
        attachment.set('type', selected.name);
        attachment.set('typePowerSelect', selected);
      },
      setRapoDate: function setRapoDate(date) {
        this.set('model.date', date);
      },
      uploadRapoForm: function uploadRapoForm(e) {
        var files = e.target.files;

        // supprimer le(s) fichier(s) actuellement choisi(s)
        this.set('rapoForm', Ember.Object.create());

        // et s'arrêter là si aucun fichier n'est choisi
        if (_lodash.default.isNil(files) || files.length === 0) {
          return;
        }

        (0, _fileInputToModel.default)(e, this.get('rapoForm'), 'base64', 'name');
        this.set('rapoForm.type', 'RAPO_FORM');
      },
      clearAttachment: function clearAttachment(index) {
        var attachments = [].pushObjects(this.get('attachments'));
        attachments.splice(index, 1);
        this.set('attachments', attachments);
      },
      uploadAttachment: function uploadAttachment(index, e) {
        var files = e.target.files;

        var currentAttachments = this.get('attachments');
        var attachment = Ember.Object.create();
        if (_lodash.default.isNil(index)) {
          // nouvelle PJ
          currentAttachments.push(attachment);
        } else {
          // écraser la PJ existante
          currentAttachments[index] = attachment;
        }

        // y injecter les informations du fichier (s'il y a un fichier)
        if (!_lodash.default.isNil(files) && files.length !== 0) {
          (0, _fileInputToModel.default)(e, attachment, 'base64', 'name');
        }

        // mettre à jour currentAttachments en enlevant les fichiers vides
        this.set('attachments', currentAttachments.filter(function (att) {
          return !_lodash.default.isNil(att.name);
        }));

        // effacer la valeur interne du champ
        e.target.value = '';
      },
      searchFps: function searchFps() {
        var _this = this;

        this.set('isLoadingSearch', true);

        this.set('model.fullFps', null);
        this.set('model.fps', null);
        this.set('model.fpsExternalId', null);
        this.set('model.fpsLegalId', null);

        this.store.queryRecord('fps', { legalId: this.get('fpsLegalId') }).then(function (fps) {
          if (fps.get('hasRapo')) {
            _this.get('notifications').error('Ce FPS a déjà fait l\'objet d\'un RAPO.');
          } else if (fps.get('type') === 'CANCELLED') {
            _this.get('notifications').error('Ce FPS a été annulé, vous ne pouvez donc pas créer de RAPO sur ce FPS.');
          } else if (fps.get('type') !== 'INITIAL') {
            _this.get('notifications').error('Vous ne pouvez pas créer de RAPO sur ce FPS.');
          } else {
            _this.set('model.fullFps', fps);
            _this.set('model.fps', fps.get('id'));
            _this.set('model.fpsExternalId', fps.get('externalId'));
            _this.set('model.fpsLegalId', fps.get('legalId'));
          }
        }).catch(function (error) {
          if (_lodash.default.isArray(error.errors) && error.errors.length > 0 && error.errors[0].status === '404') {
            _this.get('notifications').error('Le FPS n\'a pas pu être retrouvé avec les informations indiquées.');
          } else {
            _this.get('notifications').error('Une erreur est survenue lors de la récupération de la page.');
          }
        }).finally(function () {
          return _this.set('isLoadingSearch', false);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('rapo.create-reset');
      },
      save: function save() {
        var _this2 = this;

        // on copie les informations du contrôleur qui ne sont pas dans le RAPO
        this.set('model.fpsLegalId', this.get('fpsLegalId'));
        var attachments = [this.get('rapoForm')];
        attachments.pushObjects(this.get('attachments'));
        this.set('model.attachments', attachments);

        // on définit certaines informations non remplies par l'utilisateur
        this.set('model.entryDate', new Date());
        this.set('model.sourceFormat', 'PAPER');
        this.set('model.enteredByAgent', this.get('currentUser.user'));
        this.set('model.status', 'TO_INSTRUCT');
        this.set('model.number', 'RAP' + this.get('model.fpsLegalId'));
        this.set('model.correctiveFps', null);
        this.set('model.actionEvents', []);
        this.set('model.fpsAgentAuthorizedNumber', this.get('model.fullFps.agent.authorizedNumber'));
        this.set('isLoadingCreateRapo', true);
        this.get('model').save().then(function () {
          _this2.set('model.fullFps.rapoMotivations', _this2.get('model.motivations'));

          return _this2.get('model.fullFps').save().catch(function (error) {
            var bkFullFps = _this2.get('model.fullFps');

            // Erreur : initier un rollback
            return _this2.get('model').destroyRecord().finally(function () {
              // créer un nouveau RAPO dans le cache Ember Data
              var model = _this2.get('model');
              model.copy().then(function (newModel) {
                newModel.set('fullFps', bkFullFps);
                _this2.set('model', newModel);
                _this2.store.unloadRecord(model);
              });

              // maintenant qu'on a rollback, on veut tout de même traiter l'opération comme un échec.
              throw error;
            });
          });
        }).then(function () {
          _this2.get('notifications').success('Le RAPO a été créé avec succès');
          // le setTimeout permet d'éviter une erreur "multiples updates dans le même rendu"
          setTimeout(function () {
            return _this2.transitionToRoute('rapo.create-reset');
          }, 0);
        }).catch(function () {
          _this2.get('notifications').error('Le RAPO n\'a pas pu être créé');
        }).finally(function () {
          return _this2.set('isLoadingCreateRapo', false);
        });
      }
    }
  });
});