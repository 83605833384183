define('sags-front-bo/routes/users/index', ['exports', 'sags-front-bo/mixins/access-controller', 'sweetalert'], function (exports, _accessController, _sweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service(),

    model: function model() {
      return this.store.findAll('user');
    },


    actions: {
      closeUser: function closeUser(user) {
        var _this = this;

        (0, _sweetalert.default)({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment fermer le compte de ' + user.get('fullName') + ' ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          Ember.run(function () {
            _this.store.findRecord('user', user.id, { reload: true }).then(function (user) {
              user.set('status', 'CLOSED');
              user.save().then(function () {
                _this.get('notifications').success('L\'utilisateur a été fermé avec succès');
              }).catch(function () {
                _this.get('notifications').error('L\'utilisateur n\'a pas pu être fermé');
              });
            });
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      },
      openUser: function openUser(user) {
        var _this2 = this;

        (0, _sweetalert.default)({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment initialiser le compte de ' + user.get('fullName') + ' ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          Ember.run(function () {
            _this2.store.findRecord('user', user.id, { reload: true }).then(function (user) {
              user.set('status', 'INITIALIZED');
              user.save().then(function () {
                _this2.get('notifications').success('L\'utilisateur a été initialisé avec succès');
              }).catch(function () {
                _this2.get('notifications').error('L\'utilisateur n\'a pas pu être initialisé');
              });
            });
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      }
    }
  });
});