define('sags-front-bo/components/control-reason-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fieldValue: Ember.computed('sharedVariable.controlReasons', 'item', function () {
      return this.get('sharedVariable.controlReasons.' + this.get('item.reason'));
    })
  });
});