define('sags-front-bo/routes/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (this.get('session').get('isAuthenticated')) {
        this.replaceWith('index');
      }
    }
  });
});