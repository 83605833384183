define('sags-front-bo/serializers/control', ['exports', 'sags-front-bo/serializers/application', 'ember-data/serializers/embedded-records-mixin'], function (exports, _application, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      parkingTickets: { embedded: 'always' }
    }
  });
});