define('sags-front-bo/routes/gis/frequency-management', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    model: function model() {
      return this.store.findAll('visit-frequency', { reload: true });
    },
    setupController: function setupController(controller, model) {
      controller.set('isLoading', false);

      controller.set('frequenciesAgent', model.filterBy('userType', 'AGENT'));
      controller.set('frequenciesLapi', model.filterBy('userType', 'LAPI'));
    },
    resetController: function resetController(controller) {
      controller.get('frequenciesAgent').forEach(function (item) {
        return item.rollbackAttributes();
      });
      controller.get('frequenciesLapi').forEach(function (item) {
        return item.rollbackAttributes();
      });

      controller.set('frequenciesAgent', null);
      controller.set('frequenciesLapi', null);
    }
  });
});