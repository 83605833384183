define('sags-front-bo/controllers/rapo/edition', ['exports', 'sags-front-bo/utils/dictionary', 'lodash', 'moment', 'sags-front-bo/utils/download-file'], function (exports, _dictionary, _lodash, _moment, _downloadFile) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service(),

    tableColumns: [{
      'key': 'creationDate',
      'displayName': 'Date d\'envoi',
      transform: function transform(date) {
        return (0, _moment.default)(date).format('DD/MM/YYYY');
      }
    }, {
      'key': 'filename',
      'displayName': 'Fichier'
    }, {
      'key': 'sendingType',
      'displayName': 'Type d\'envoi',
      transform: function transform(type) {
        return _dictionary.default.responseSendingType[type];
      }
    }, {
      'key': 'downloadDate',
      'displayName': 'Date de téléchargement',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return 'Jamais';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }],

    filters: {
      sendingType: {
        comparator: 'equals',
        value: '*'
      }
    },

    serverSideFilters: {
      creationDate: {
        comparator: 'dateEquals',
        value: new Date()
      }
    },

    actionIcons: [{ icon: 'download', tooltip: 'Télécharger' }],

    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller, store) {
      var creationDate = controller.get('serverSideFilters.creationDate.value');
      if (_lodash.default.isNil(creationDate)) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        controller.set('serverSideFilters.creationDate.value', today);

        // Filtre par la date d'aujourd'hui
        controller.set('content', store.query('pdf-response', {
          creationDate: today.toISOString()
        }));
      } else {
        creationDate.setHours(0, 0, 0, 0);
        controller.set('content', store.query('pdf-response', {
          creationDate: creationDate.toISOString()
        }));
      }
    },


    actions: {
      changeCreationDate: function changeCreationDate() {
        this.refreshWithServerSideFilter(this, this.store);
      },
      changeSendingType: function changeSendingType(sendingType) {
        this.set('sendingType', sendingType);
        this.set('filters.sendingType.value', _lodash.default.isNil(sendingType) ? '*' : sendingType.key);
      },
      downloadPdfResponse: function downloadPdfResponse(pdfResponse) {
        var _this = this;

        this.store.findRecord('pdf-response', pdfResponse.get('id'), { reload: true }).then(function (pdfResponseWithBase64) {
          (0, _downloadFile.default)(pdfResponseWithBase64.get('filename'), pdfResponseWithBase64.get('base64'), 'application/pdf');
        }).catch(function () {
          _this.get('notifications').error('Impossible de télécharger le fichier.');
        });
      }
    }
  });
});