define('sags-front-bo/serializers/user', ['exports', 'sags-front-bo/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      // trier la liste des villes par ordre croissant
      // de manière transparente pour le reste de l'application
      payload['user'].cities.sort();
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      // trier la liste des villes par ordre croissant
      // de manière transparente pour le reste de l'application
      payload.forEach(function (user) {
        return user.cities.sort();
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});