define('sags-front-bo/routes/observations/index', ['exports', 'sags-front-bo/utils/dictionary', 'sags-front-bo/mixins/access-controller'], function (exports, _dictionary, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('serverSideFilters.datetime.value', new Date());
      controller.set('filters.reason.value', '*');
      controller.set('filters.categoryName.value', '*');
      controller.set('filters.address.value', '');

      controller.set('category', null);
      controller.set('reason', null);
      controller.set('isCategorySelected', true);

      controller.get('refreshWithServerSideFilter')(controller, this.store);

      _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (dic) {
        controller.set('categories', _dictionary.default.convertToArray(dic.observationCategories));
      });
    }
  });
});