define('sags-front-bo/helpers/currency-formatter', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.currencyFormatter = currencyFormatter;


  // fonction permettant d'insérer "content" à la position "position" dans la chaîne "string"
  var insert = function insert(string, position, content) {
    return string.substring(0, position) + content + string.substring(position);
  };

  function currencyFormatter(params) {
    // vérifier que le paramètre est valide
    if (!_lodash.default.isNumber(params[0])) {
      return '';
    }

    // convertir le nombre POSITIF en string, avec au moins 3 chiffres (donc 2 => 002 => 0,02 €)
    var currencyString = _lodash.default.padStart(Math.abs(params[0].toString()), 3, '0');

    // virgule : l'insérer à 2 caractères de la fin
    var commaPosition = currencyString.length - 2;
    currencyString = insert(currencyString, commaPosition, ',');

    // séparateurs de miliers = espaces insécables \a0
    for (var position = commaPosition - 3; position > 0; position -= 3) {
      currencyString = insert(currencyString, position, '\xa0');
    }

    // rajouter le '-' si nombre négatif
    if (params[0] < 0) {
      currencyString = '-' + currencyString;
    }

    // renvoyer la string en ajoutant un espace insécable et '€'
    return currencyString + '\xa0€';
  }

  exports.default = Ember.Helper.helper(currencyFormatter);
});