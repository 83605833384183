define('sags-front-bo/routes/devices/create', ['exports', 'sags-front-bo/mixins/access-controller', 'lodash', 'sags-front-bo/utils/dictionary'], function (exports, _accessController, _lodash, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      model.set('type', 'PHONE');
      model.set('cities', [this.get('currentUser').get('city')]);

      controller.set('cities', this.get('currentUser.user.cities'));
      controller.set('deviceTypes', _dictionary.default.convertToPowerSelectOptions(_dictionary.default.deviceType));
      controller.set('selectedType', controller.get('deviceTypes')[0]);

      controller.set('isLoadingCreateDevice', false);
    },
    model: function model() {
      return this.store.createRecord('device');
    },


    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('devices');
      },
      save: function save() {
        var _this = this;

        this.set('controller.isLoadingCreateDevice', true);
        var device = this.get('controller.model');
        if (_lodash.default.isEmpty(device.get('cities'))) {
          this.get('notifications').error('Au moins une ville doit être sélectionnée');
          this.set('controller.isLoadingCreateDevice', false);
        } else {
          device.save().then(function () {
            _this.get('notifications').success('L\'équipement a été créé avec succès');
            _this.transitionTo('devices');
          }).catch(function () {
            _this.get('notifications').error('L\'équipement n\'a pas pu être créé');
          }).finally(function () {
            return _this.set('controller.isLoadingCreateDevice', false);
          });
        }
      },
      willTransition: function willTransition() {
        this.get('controller.model').rollbackAttributes();
      }
    }

  });
});