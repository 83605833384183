define('sags-front-bo/helpers/file-extension-validator', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isExtensionAllowed = isExtensionAllowed;


  var allowedFileExtensions = ['pdf', 'jpg', 'png', 'docx', 'doc', 'odt'];

  function isExtensionAllowed(fileExtension) {
    return _lodash.default.includes(allowedFileExtensions, fileExtension);
  }

  exports.default = Ember.Helper.helper(isExtensionAllowed);
});