define('sags-front-bo/controllers/cars/create', ['exports', 'sags-front-bo/utils/dictionary'], function (exports, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    types: _dictionary.default.convertToPowerSelectOptions(_dictionary.default.carType),
    statuses: _dictionary.default.convertToPowerSelectOptions(_dictionary.default.carStatus),

    actions: {
      selectCities: function selectCities(cities) {
        this.set('model.car.cities', [cities]);
      },
      selectType: function selectType(type) {
        this.set('selectedType', this.get('types').findBy('name', type.name));
        this.set('model.car.type', type.name);
      },
      selectStatus: function selectStatus(status) {
        this.set('selectedStatus', this.get('statuses').findBy('name', status.name));
        this.set('model.car.state', status.name);
      },
      selectDevice: function selectDevice(device) {
        this.get('model.car').set('assignedToId', device);
      }
    }
  });
});