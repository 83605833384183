define('sags-front-bo/serializers/ccsp-claim', ['exports', 'sags-front-bo/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      comments: {
        serialize: 'records',
        deserialize: 'records'
      },
      actions: {
        serialize: 'records',
        deserialize: 'records'
      }
    }
  });
});