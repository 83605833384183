define('sags-front-bo/routes/cars/create', ['exports', 'sags-front-bo/mixins/access-controller', 'lodash'], function (exports, _accessController, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {

    notifications: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    setupController: function setupController(controller, model) {

      this._super(controller, model);
      controller.set('model.car.state', 'WORKING');
      controller.set('model.car.cities', [this.get('currentUser').get('city')]);
      controller.set('cities', this.get('currentUser.user.cities'));
      var firstType = controller.get('types').get('firstObject');
      controller.set('selectedType', firstType);
      controller.set('model.car.type', firstType.name);
      controller.set('isLoadingSave', false);
    },
    model: function model() {
      return Ember.RSVP.hash({
        car: this.store.createRecord('car'),
        devices: this.store.query('device', { type: 'TABLET' })
      });
    },

    actions: {
      cancel: function cancel() {
        this.get('controller.model.car').rollbackAttributes();
        this.transitionTo('cars');
      },
      save: function save() {
        var _this = this;

        this.set('controller.isLoadingSave', true);
        var car = this.get('controller.model.car');
        if (_lodash.default.isEmpty(car.get('cities'))) {
          this.get('notifications').error('Au moins une ville doit être sélectionnée');
          this.set('controller.isLoadingSave', false);
        } else {
          car.save().then(function () {
            _this.get('notifications').success('Le véhicule a été créé avec succès');
            _this.transitionTo('cars');
          }).catch(function (error) {
            if (!_lodash.default.isEmpty(error.errors) && error.errors[0].status === '409') {
              _this.get('notifications').error('Un autre véhicule avec le même identifiant ou la même plaque existe déjà');
            } else {
              _this.get('notifications').error('Le véhicule n\'a pas pu être créé');
            }
          }).finally(function () {
            return _this.set('controller.isLoadingSave', false);
          });
        }
      },
      willTransition: function willTransition() {
        this.get('controller.model.car').rollbackAttributes();
      }
    }
  });
});