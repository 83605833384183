define('sags-front-bo/controllers/sectors/create', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    allDrawnFeatures: [],
    allLayers: [],

    actions: {
      createDrawnFeature: function createDrawnFeature(feature, drawingLayerGroup, map) {
        var _this = this;

        Ember.run.later(function () {
          var allFeatures = drawingLayerGroup.toGeoJSON().features;
          _this.set('allDrawnFeatures', allFeatures);
        }, 0);

        if (feature.layerType !== 'marker') {
          var bounds = feature.layer.getBounds();
          map.fitBounds(bounds);
          this.set('sectorChanged', true);
        }
      },
      updateOrDeleteDrawnFeature: function updateOrDeleteDrawnFeature(feature, drawingLayerGroup) {
        var _this2 = this;

        this.set('drawingLayerGroup', drawingLayerGroup);
        Ember.run.later(function () {
          var allFeaturesBefore = _this2.get('allDrawnFeatures');
          var allFeatures = drawingLayerGroup.toGeoJSON().features;

          var featuresBeforeCoordinates = allFeaturesBefore.filter(function (f) {
            return f.geometry.type === 'Polygon';
          }).map(function (f) {
            return f.geometry.coordinates[0];
          });
          var featuresCoordinates = allFeatures.filter(function (f) {
            return f.geometry.type === 'Polygon';
          }).map(function (f) {
            return f.geometry.coordinates[0];
          });

          var polygonEdited = JSON.stringify(featuresBeforeCoordinates) !== JSON.stringify(featuresCoordinates);

          if (polygonEdited) {
            _this2.set('sectorChanged', true);
          }

          _this2.set('allDrawnFeatures', allFeatures);
        }, 0);
      },


      // événement permettant d'ajouter les secteurs & points de départ existants au layer éditable
      // (le composant ember-leaflet-draw ne donnant pas de moyen simple de le faire)
      layerAdded: function layerAdded(layerEvent) {
        if (!this.get('editableLayerInitialized') && _lodash.default.isFunction(layerEvent.layer.toGeoJSON) && layerEvent.layer.toGeoJSON().type === 'FeatureCollection') {

          this.get('allLayers').forEach(function (feature) {
            return feature.addTo(layerEvent.layer);
          });
          this.set('editableLayerInitialized', true);
        }
      }
    }
  });
});