define('sags-front-bo/routes/fps/index', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),

    setupController: function setupController(controller) {
      // valeur par défaut du filtre
      controller.get('refreshWithServerSideFilter')(controller, this.store);
    }
  });
});