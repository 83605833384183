define('sags-front-bo/components/view-attachment-vao', ['exports', 'sags-front-bo/utils/converter'], function (exports, _converter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var _zoom = 1;
  var x, y;

  exports.default = Ember.Component.extend({
    pdfData: Ember.computed('attachmentBase64Vao', function () {
      return (0, _converter.base64toObjectUrl)(this.get('attachmentBase64Vao'), 'application/pdf');
    }),

    actions: {
      closeAttachmentModalVao: function closeAttachmentModalVao() {
        _zoom = 1;
        this.get('closeAttachmentModalVao')();
      },
      zoom: function zoom(event) {
        var modal = document.getElementsByClassName("modal-body")[0];
        var image = event.currentTarget;

        if (event.deltaY < 0) {
          _zoom += 0.1;
          image.style.transform = 'scale(' + _zoom + ') ';
        } else if (event.deltaY > 0 && _zoom >= 0.75) {
          _zoom -= 0.1;
          image.style.transform = 'scale(' + _zoom + ')';
        }
      }
    }
  });
});