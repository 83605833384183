define('sags-front-bo/routes/white-list-bis/delete', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('vehiclePlate', null);
      controller.set('isLoadingDelete', false);
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.set('vehiclePlate', null);
      controller.set('isLoadingDelete', false);
    }
  });
});