define('sags-front-bo/utils/pictures', ['exports', 'sags-front-bo/utils/dictionary'], function (exports, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getFormattedPicturesList;


  var wordingPictureType = _dictionary.default.convertToPowerSelectOptions(_dictionary.default.pictureType);

  // Nombre maximum de photos à afficher
  var MAX_PICTURES_TO_DISPLAY = 6;

  function getFormattedPicturesList(data) {
    var maxPicturesToDisplay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : MAX_PICTURES_TO_DISPLAY;

    data = data.filter(function (p) {
      return p.base64 && wordingPictureType.findBy('name', p.pictureType);
    });

    // Tri les photos dans cet ordre : PLATE_ZOOM > AGENT > LAPI
    data.sort(function (a, b) {
      if (a.pictureType !== 'PLATE_ZOOM' && b.pictureType === 'PLATE_ZOOM' || b.userType === "AGENT" && a.userType !== 'PLATE_ZOOM' && a.userType === 'LAPI') return 1;

      return 0;
    });

    // Retire les photos en fin de liste (moins importantes) si il y en a beaucoup
    data.length = data.length > maxPicturesToDisplay ? maxPicturesToDisplay : data.length;

    var preview = 'data:image/png;base64,';

    // Ajoute le format base 64 + la phrase décrivant de la photo 
    data.forEach(function (p) {
      p.base64 = preview + p.base64;
      p.wordingPictureType = wordingPictureType.findBy('name', p.pictureType).value;
    });

    return data;
  }
});