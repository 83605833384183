define('sags-front-bo/components/tariff-zone-element-control', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fieldValue: Ember.computed('sharedVariable.tariffZones', 'item', function () {
      if (_lodash.default.isEmpty(this.get('item.tariffZone'))) {
        return 'Inconnue';
      }

      return this.get('sharedVariable.tariffZones.' + this.get('item.tariffZone'));
    })
  });
});