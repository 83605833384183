define('sags-front-bo/models/visit-frequency', ['exports', 'ember-data', 'sags-front-bo/utils/dictionary'], function (exports, _emberData, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    publicId: _emberData.default.attr('string'),
    userType: _emberData.default.attr('string'),
    numberOfVisits: _emberData.default.attr('number'),
    frequency: _emberData.default.attr('string'),
    label: Ember.computed('publicId', 'numberOfVisits', 'frequency', function () {
      var label = '';
      label += this.get('publicId') + ' - ' + this.get('numberOfVisits') + '/' + _dictionary.default.visitFrequencies[this.get('frequency')];
      return label;
    })
  });
});