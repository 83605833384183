define('sags-front-bo/components/rapo/detail-rapo-fps', ['exports', 'lodash', 'moment', 'sags-front-bo/utils/dictionary', 'sags-front-bo/config/environment', 'sags-front-bo/helpers/currency-formatter'], function (exports, _lodash, _moment, _dictionary, _environment, _currencyFormatter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    // Définitions de la table des tickets
    tableColumnsParkingTickets: [{
      'key': 'significant',
      'displayName': 'Retenu pour FPS ?',
      transform: function transform(significant) {
        return significant ? 'Oui' : 'Non';
      }
    }, {
      'key': 'startDate',
      'displayName': 'Début',
      transform: function transform(date) {
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm');
      }
    }, {
      'key': 'endDate',
      'displayName': 'Fin',
      transform: function transform(date) {
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm');
      }
    }, {
      'key': 'urbanParkingSiteId',
      'displayName': 'Zone et/ou Secteur',
      'customComponent': 'tariff-zone-element'
    }, {
      'key': 'type',
      'displayName': 'Type',
      transform: function transform(type) {
        return _dictionary.default.ticketType[type];
      }
    }, {
      'key': 'parkingPermits',
      'displayName': 'Autorisations',
      transform: function transform(parkingPermits) {
        if (!_lodash.default.isArray(parkingPermits) || _lodash.default.isEmpty(parkingPermits)) {
          return 'Aucune';
        }
        return parkingPermits.map(function (permit) {
          return _dictionary.default.parkingPermit[permit];
        }).join(', ');
      }
    }, {
      'key': 'chargePaid',
      'displayName': 'Montant',
      transform: function transform(valueInCents) {
        return _currencyFormatter.default.compute([valueInCents]);
      }
    }],

    streetTypes: _dictionary.default.convertToArray(_dictionary.default.streetType),
    streetNumberBis: _dictionary.default.convertToArray(_dictionary.default.streetNumberBis),
    authorizations: _dictionary.default.convertToArray(_dictionary.default.parkingPermit),
    types: _dictionary.default.convertToArray(_dictionary.default.ticketType),

    canBookThenInstruct: Ember.computed('rapo.status', 'isEstablishedBy', function () {
      return this.get('rapo.status') === 'TO_INSTRUCT' && !this.get('isEstablishedBy');
    }),

    canWithdrawBooking: Ember.computed('rapo.status', 'isAgentLockedBy', 'isSupervisor', function () {
      return this.get('rapo.status') === 'INSTRUCTING' && (this.get('isAgentLockedBy') || this.get('isSupervisor'));
    }),

    canReInstruct: Ember.computed('rapo.status', 'isInstructor', function () {
      return this.get('rapo.status') === 'TO_REINSTRUCT' && this.get('isInstructor');
    }),

    canComplete: Ember.computed('rapo.status', function () {
      return this.get('rapo.status') === 'TO_COMPLETE';
    }),

    isModifyCorrectiveFpsAvailable: Ember.computed('modifyPlate', 'modifyPlateCountry', 'modifyBrand', 'modifyStreetType', 'modifyStreetName', 'modifyTariffZone', function () {
      return !_lodash.default.isEmpty(this.get('modifyPlate')) && !_lodash.default.isEmpty(this.get('modifyPlateCountry')) && !_lodash.default.isEmpty(this.get('modifyBrand')) && !_lodash.default.isEmpty(this.get('modifyStreetType')) && !_lodash.default.isEmpty(this.get('modifyStreetName')) && !_lodash.default.isNil(this.get('modifyTariffZone'));
    }),

    isDisableCauseOfStatus: Ember.computed('rapo.status', 'isAgentLockedBy', 'isInstructor', function () {
      return !((this.get('rapo.status') === 'INSTRUCTING' || this.get('rapo.status') === 'REINSTRUCTING') && (this.get('isAgentLockedBy') || this.get('isInstructor')));
    }),

    isNewTicketModalValidateDisabled: Ember.computed('isLoadingModifyCorrectiveFps', 'ticketStartDate', 'ticketEndDate', 'ticketZone', 'ticketType', 'ticketAmount', 'ticketLegalId', function () {
      return this.get('isLoadingModifyCorrectiveFps') || _lodash.default.isNil(this.get('ticketStartDate')) || _lodash.default.isNil(this.get('ticketEndDate')) || _lodash.default.isNil(this.get('ticketZone')) || _lodash.default.isNil(this.get('ticketType')) || _lodash.default.isEmpty(this.get('ticketAmount')) || this.get('ticketAmount').match(/^[0-9]+((\.|,)[0-9]{1,2})?$/) === null || this.get('ticketType.key') === 'FINE' && (_lodash.default.isNil(this.get('ticketLegalId')) || this.get('ticketLegalId').length !== 26);
    }),

    correctiveFpsHasBeenLoaded: Ember.observer('loadedCorrectiveFps', function () {
      this.send('updateModifyCorrectiveFpsWithFps', this.get('rapo.correctiveFps'));
    }),

    actions: {
      showNewTicketModal: function showNewTicketModal() {
        this.set('isNewTicketModalVisible', true);
      },
      showAttachment: function showAttachment(isImage, img, attachment) {
        this.set('attachmentShown', attachment);
        this.set('attachmentBase64', img);
        this.set('isAttachmentModalVisible', true);
        this.set('isAttachmentImage', isImage);
      },
      closeAttachmentModal: function closeAttachmentModal() {
        this.set('attachmentShown', null);
        this.set('attachmentBase64', null);
        this.set('isAttachmentModalVisible', false);
      },
      changePlateCountry: function changePlateCountry(country) {
        this.set('modifyPlateCountry', country);
      },
      changeBrand: function changeBrand(brand) {
        this.set('modifyBrand', brand);
      },
      changeTariffZone: function changeTariffZone(zone) {
        this.set('modifyTariffZone', zone);
      },
      changeResidentialSector: function changeResidentialSector(sector) {
        this.set('modifyResidentialSector', sector);
      },
      changeStreetNumberBis: function changeStreetNumberBis(streetNumberBis) {
        this.set('modifyStreetNumberBis', streetNumberBis);
      },
      changeStreetType: function changeStreetType(streetType) {
        this.set('modifyStreetType', streetType);
      },
      showFpsPictures: function showFpsPictures() {
        this.set('isFpsPicturesVisible', true);
      },
      changeTicketZone: function changeTicketZone(zone) {
        this.set('ticketZone', zone);
      },
      changeTicketSector: function changeTicketSector(sector) {
        this.set('ticketSector', sector);
      },
      changeTicketType: function changeTicketType(type) {
        var oldType = this.get('ticketType.key');
        this.set('ticketType', type);

        // si changement de type depuis ou vers 'FPS', on vide le champ 'zone'
        if (oldType === 'FINE' && type.key !== 'FINE' || oldType !== 'FINE' && type.key === 'FINE') {
          this.set('ticketZone', null);
        }
      },
      changeTicketAuthorization: function changeTicketAuthorization(authorization) {
        this.set('ticketAuthorization', authorization);
      },
      closeNewTicketModal: function closeNewTicketModal() {
        this.set('isNewTicketModalVisible', false);
        this.set('ticketStartDate', null);
        this.set('ticketEndDate', null);
        this.set('ticketZone', null);
        this.set('ticketSector', null);
        this.set('ticketType', null);
        this.set('ticketAuthorization', null);
        this.set('ticketAmount', null);
      },
      createNewParkingTicket: function createNewParkingTicket() {
        this.set('isLoadingModifyCorrectiveFps', true);
        var newParkingTicket = Ember.Object.create({
          startDate: this.get('ticketStartDate'),
          endDate: this.get('ticketEndDate'),
          type: this.get('ticketType.key'),
          urbanParkingSiteId: _lodash.default.isNil(this.get('ticketSector')) ? [this.get('ticketZone.key')] : [this.get('ticketZone.key'), this.get('ticketSector.key')],
          chargePaid: Math.round(parseFloat(this.get('ticketAmount').replace(/,/, '.')) * 100),
          parkingPermits: _lodash.default.isNil(this.get('ticketAuthorization')) ? [] : [this.get('ticketAuthorization.key')],
          significant: true,
          vehiclePlate: this.get('rapo.fullFps.vehiclePlate'),
          fineLegalId: this.get('ticketType') === 'FINE' ? this.get('ticketLegalId') : null
        });

        this.send('closeNewTicketModal');

        if (_lodash.default.isNil(this.get('rapo.correctiveFps'))) {
          this.set('rapo.correctiveFps', {});
        }
        // enregistrer le ticket dans le FPS rectificatif
        this.set('rapo.correctiveFps.significantParkingTickets', [newParkingTicket]);

        // et mettre à jour le FPS rectificatif
        this.send('modifyCorrectiveFps');
      },


      // Mettre à jour le FPS rectificatif en cours d'édition à partir du FPS donné
      updateModifyCorrectiveFpsWithFps: function updateModifyCorrectiveFpsWithFps(fps) {
        // Dans le cas où une instruction est en cours et que le FPS rectificatif est vide,
        // on le pré-remplit avec les informations du FPS initial.
        if (_lodash.default.isNil(fps) && ['INSTRUCTING', 'REINSTRUCTING'].includes(this.get('rapo.status'))) {
          fps = this.get('rapo.fullFps');
        }

        var findByKey = function findByKey(options, key) {
          var keyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'key';

          var results = options.filterBy(keyName, key);
          if (!_lodash.default.isEmpty(results)) {
            return results[0];
          }
          results = options.filterBy('value', key);
          if (!_lodash.default.isEmpty(results) && results.length === 1) {
            return results[0];
          }
          return null;
        };

        if (_lodash.default.isNil(fps) || !_lodash.default.isFunction(fps.get)) {
          fps = Ember.Object.create(fps);
        }

        this.set('modifyPlate', fps.get('vehiclePlate'));
        this.set('modifyPlateCountry', findByKey(this.get('countries'), fps.get('vehiclePlateCountry')));
        this.set('modifyBrand', findByKey(this.get('brands'), fps.get('vehicleBrand'), 'name'));
        this.set('modifyModel', fps.get('vehicleModel'));
        this.set('modifyTariffZone', findByKey(this.get('zonesFps'), fps.get('tariffZone')));
        this.set('modifyResidentialSector', findByKey(this.get('sectors'), fps.get('residentialSector')));
        this.set('modifyStreetNumber', fps.get('streetNumber'));
        this.set('modifyStreetNumberBis', findByKey(this.get('streetNumberBis'), fps.get('streetNumberBis')));
        this.set('modifyStreetType', findByKey(this.get('streetTypes'), fps.get('streetType')));
        this.set('modifyStreetName', fps.get('streetName'));
      },


      // Réinitialisation du FPS rectificatif
      reinitCorrectiveFps: function reinitCorrectiveFps() {
        var _this = this;

        this.set('isLoadingReinitCorrectiveFps', true);
        this.set('rapo.correctiveFps', null);

        var url = _environment.default.APP.host + '/rapo/api/rapos/' + this.get('rapo.id') + '/corrective-fps';
        this.get('ajax').request(url, {
          method: 'DELETE',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          }
        }).then(function () {
          _this.send('updateModifyCorrectiveFpsWithFps', null);
        }).finally(function () {
          _this.set('isLoadingReinitCorrectiveFps', false);
        });
      },
      modifyCorrectiveFps: function modifyCorrectiveFps() {
        var _this2 = this;

        this.set('isLoadingModifyCorrectiveFps', true);
        // On copie les informations temporaires dans le RAPO
        if (_lodash.default.isNil(this.get('rapo.correctiveFps'))) {
          this.set('rapo.correctiveFps', {});
        }
        this.set('rapo.correctiveFps.vehiclePlate', this.get('modifyPlate'));
        this.set('rapo.correctiveFps.vehiclePlateCountry', this.get('modifyPlateCountry.key'));
        this.set('rapo.correctiveFps.vehicleBrand', this.get('modifyBrand.name'));
        this.set('rapo.correctiveFps.vehicleModel', this.get('modifyModel'));
        this.set('rapo.correctiveFps.tariffZone', this.get('modifyTariffZone.key'));
        this.set('rapo.correctiveFps.residentialSector', this.get('modifyResidentialSector.key'));
        this.set('rapo.correctiveFps.streetNumber', this.get('modifyStreetNumber'));
        this.set('rapo.correctiveFps.streetNumberBis', this.get('modifyStreetNumberBis.value'));
        this.set('rapo.correctiveFps.streetType', this.get('modifyStreetType.value'));
        this.set('rapo.correctiveFps.streetName', this.get('modifyStreetName'));
        this.set('rapo.correctiveFps.creationDate', this.get('rapo.fullFps.creationDate'));
        var indexNumber = undefined;
        this.get('currentUser.user.userCities').forEach(function (userCity) {
          if (userCity.city.name === _this2.get('currentUser.city')) {
            indexNumber = userCity.indexNumber;
          }
        });
        var authorizedNumber = this.get('currentUser.user.authorizedNumber');
        this.set('rapo.correctiveFps.agent', {
          identification: this.get('currentUser.user.identification'),
          name: this.get('currentUser.user.fullName'),
          // ces champs peuvent être vides : l'agent qui instruit le RAPO peut ne pas être assermenté.
          // ils sont obligatoires mais ont peu d'importance en réalité (on ne crée pas de FPS)
          authorizedNumber: _lodash.default.isEmpty(authorizedNumber) ? 'none' : authorizedNumber,
          // l'indexNumber doit être sur 3 caractères
          indexNumber: _lodash.default.isEmpty(indexNumber) ? 'non' : indexNumber
        });

        // s'il n'y a pas de tableau de parkingTickets initialisés, copier ceux du FPS initial
        if (!_lodash.default.isArray(this.get('rapo.correctiveFps.significantParkingTickets'))) {
          this.set('rapo.correctiveFps.significantParkingTickets', this.get('rapo.fullFps.significantParkingTickets'));
        }

        // TODO que faire de ce champ ??
        this.set('rapo.correctiveFps.status', 'SENT');

        // demander le nouveau montant au serveur de FPS
        this.get('ajax').request(_environment.default.APP.host + '/control/api/fps/corrective-amount', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          data: this.get('rapo.correctiveFps'),
          contentType: 'application/json'
        }).then(function (fpsAmount) {
          _this2.set('rapo.correctiveFps.amount', fpsAmount.fpsAmount);
          _this2.set('rapo.correctiveFps.validityDate', _this2.get('rapo.fullFps.validityDate'));
          _this2.set('rapo.correctiveFps.deductionTotal', fpsAmount.deductionTotal);

          // enregistrer le nouveau FPS rectificatif sur le serveur de FPS
          return _this2.get('ajax').request(_environment.default.APP.host + '/rapo/api/rapos/' + _this2.get('rapo.id') + '/treatment-fps', {
            method: 'PUT',
            headers: {
              Authorization: 'Bearer ' + _this2.get('session.session.authenticated.id_token'),
              City: _this2.get('currentUser.city')
            },
            data: { correctiveFps: _this2.get('rapo.correctiveFps') },
            contentType: 'application/json'
          });
        }).then(function (rapoTreamentFps) {
          // synchroniser le modèle local avec la base.
          _this2.set('rapo.correctiveFps', rapoTreamentFps.correctiveFps);
          _this2.send('updateModifyCorrectiveFpsWithFps', rapoTreamentFps.correctiveFps);
          _this2.get('notifications').success('FPS rectificatif mis à jour');
        }).catch(function () {
          _this2.get('notifications').error('Impossible de mettre à jour le FPS rectificatif');
        }).finally(function () {
          _this2.set('isLoadingModifyCorrectiveFps', false);
        });
      },


      // Réservation de l'instruction du RAPO
      instructRapo: function instructRapo() {
        this.set('isLoadingRapo', true);
        this.startInstruction('INSTRUCTING');
      },


      // Déréservation de l'instruction du RAPO
      removeReservation: function removeReservation() {
        this.set('isLoadingRapo', true);
        this.saveRapo('STOP_INSTRUCTION', 'TO_INSTRUCT');
      },


      // Réinstruire le RAPO
      reinstructRapo: function reinstructRapo() {
        this.set('isLoadingRapo', true);
        this.set('rapo.workflowProcessId', null);

        this.startInstruction('REINSTRUCTING');
      }
    }
  });
});