define('sags-front-bo/components/power-select-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('selectedItem', this.get('sharedVariable.powerSelects.' + this.get('columnName')).filter(function (option) {
        return option.key === _this.get('value');
      })[0]);
    },


    actions: {
      selectItem: function selectItem(item) {
        this.set('item.' + this.get('columnName'), item.key);
      }
    }
  });
});