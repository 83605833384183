define('sags-front-bo/routes/controls/index', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/dictionary'], function (exports, _accessController, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    setupController: function setupController(controller) {
      // valeur par défaut du filtre
      controller.set('serverSideFilters.startDatetime.value', new Date());
      controller.set('serverSideFilters.endDatetime.value', new Date());
      controller.get('refreshWithServerSideFilter')(controller, this.store);

      _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (reasons) {
        // fusionner les deux dans fpsReasons
        var dic = reasons.fpsReasons;
        Object.keys(reasons.controlIssues).forEach(function (key) {
          return dic[key] = reasons.controlIssues[key];
        });
        controller.set('linkComponentConfig.controlReasons', dic);
        controller.set('linkComponentConfig.tariffZones', reasons.tariffZones);
        controller.set('possibleTariffZones', _dictionary.default.convertToArray(reasons.tariffZones, [{ key: '*', value: 'Tous' }]));
      });
    },
    resetController: function resetController(controller) {
      controller.set('isControlPhotosVisible', false);
      controller.set('controlPictures', undefined);
      controller.set('mainControlPicture', undefined);
    }
  });
});