define('sags-front-bo/helpers/files-in-array-for-type', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.filesInArrayForType = filesInArrayForType;
  function filesInArrayForType(params) {
    var array = params[0];
    var type = params[1];
    var placeholder = params[2];

    if (!_lodash.default.isEmpty(array)) {
      var list = array.filter(function (file) {
        return file.type === type;
      }).map(function (file) {
        return file.name;
      }).join(', ');

      if (_lodash.default.isEmpty(list)) {
        return placeholder;
      }

      return list;
    }

    return placeholder;
  }

  exports.default = Ember.Helper.helper(filesInArrayForType);
});