define('sags-front-bo/serializers/rapo', ['exports', 'sags-front-bo/serializers/application', 'lodash'], function (exports, _application, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      // inclure explicitement le champ fullFps même s'il n'est pas dans le modèle
      // (il est utilisé pour générer la réponse RAPO)
      if (!_lodash.default.isNil(snapshot.record.get('fullFps')) && _lodash.default.isFunction(snapshot.record.get('fullFps').serialize)) {
        json.fullFps = snapshot.record.get('fullFps').serialize();
      }

      return json;
    },


    attrs: {
      actionEvents: {
        serialize: 'records',
        deserialize: 'records'
      },

      newBuyer: {
        embedded: 'always'
      }
    }
  });
});