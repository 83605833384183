define('sags-front-bo/services/current-user', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    loginController: Ember.inject.controller('login'),

    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        // charger l'utilisateur connecté
        return this.get('store').queryRecord('user', { me: true }).then(function (user) {
          _this.set('user', user);

          // on sélectionne la ville qui a été choisie précédemment (localStorage).
          if (_lodash.default.isNil(_this.get('city'))) {
            var userCities = user.get('cities');
            if (_lodash.default.isNil(localStorage.city) || !userCities.includes(localStorage.city)) {
              // l'utilisateur n'avait pas choisi de ville ou n'a pas accès à la dernière ville choisie.
              // ==> par défaut on prend la première ville de la liste
              localStorage.city = userCities[0];
            }

            _this.set('city', localStorage.city);
          }
        });
      }

      return Ember.RSVP.resolve();
    }
  });
});