define('sags-front-bo/routes/rapo/create-reset', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    renderTemplate: function renderTemplate() {
      var _this = this;

      setTimeout(function () {
        return _this.transitionTo('rapo.create');
      }, 0);
    }
  });
});