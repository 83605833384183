define('sags-front-bo/components/show-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isShowing: false,
    title: '',
    actions: {
      toggle: function toggle() {
        this.toggleProperty('isShowing');
      }
    }
  });
});