define('sags-front-bo/routes/rules/index', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/dictionary', 'sweetalert', 'lodash', 'moment'], function (exports, _accessController, _dictionary, _sweetalert, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),

    model: function model() {
      return this.store.findAll('aggregated-section', { reload: true });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('aggregatedSection', null);
      controller.set('isAggregatedSectionClicked', false);
      // On récupère les zones tarifaires/secteurs du serveur
      _dictionary.default.fetchSettingDictionaries(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        controller.set('tariffZones', _dictionary.default.convertToArray(settings.tariffZones));
        controller.set('sectors', _dictionary.default.convertToArray(settings.residentialSectors));
      });

      this.store.findAll('visit-frequency', { reload: true }).then(function (visitFrequencies) {
        controller.set('agentFrequencies', visitFrequencies.filterBy('userType', 'AGENT').sortBy('publicId'));
        controller.set('lapiFrequencies', visitFrequencies.filterBy('userType', 'LAPI').sortBy('publicId'));
      });

      controller.set('ruleTypes', _dictionary.default.convertToPowerSelectOptions(_dictionary.default.ruleType));
      controller.set('daysOfWeek', _dictionary.default.convertToPowerSelectOptions(_dictionary.default.dayOfWeek));

      controller.set('createdRule', this.store.createRecord('rule'));

      controller.set('weeksOfMonth', Array.from({ length: 5 }, function (v, k) {
        return k + 1;
      }));
      controller.set('daysOfMonth', Array.from({ length: 31 }, function (v, k) {
        return k + 1;
      }));
    },
    resetAttributes: function resetAttributes() {
      this.set('controller.createdRule', this.store.createRecord('rule'));
      this.set('controller.selectedDayOfMonth1', null);
      this.set('controller.selectedDayOfMonth2', null);
      this.set('controller.selectedType', null);
      this.set('controller.selectedDayOfWeek', null);
      this.set('controller.isDayOfWeekEmpty', true);
      this.set('controller.isWeekOfMonthEmpty', true);
    },


    actions: {
      deleteRule: function deleteRule(rule) {
        var _this = this;

        if (!_lodash.default.isNil(rule)) {
          (0, _sweetalert.default)({
            title: 'Confirmation',
            text: 'Voulez-vous vraiment supprimer cette règle ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler',
            target: '#app-container'
          }).then(function () {
            Ember.run(function () {
              rule.destroyRecord().then(function () {
                _this.get('notifications').success('La règle a été supprimée avec succès');
              }).catch(function () {
                rule.rollbackAttributes();
                _this.get('notifications').error('La règle n\'a pas pu être supprimée');
              });
            });
          }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
        }
      },
      save: function save() {
        var _this2 = this;

        this.set('controller.createdRule.aggregatedSectionId', this.get('controller.aggregatedSection'));
        var rule = this.get('controller.createdRule');
        this.set('controller.isLoadingSave', true);

        if (rule.get('beginDate') > rule.get('endDate')) {
          this.get('notifications').error('La date de fin doit être supérieure à la date de début');
          this.set('controller.isLoadingSave', false);
        } else if (rule.get('beginTime') > rule.get('endTime')) {
          this.get('notifications').error('L\'heure de fin doit être supérieure à l\'heure de début');
          this.set('controller.isLoadingSave', false);
        } else if (this.get('controller.selectedDayOfMonth1') > this.get('controller.selectedDayOfMonth2')) {
          this.get('notifications').error('Jours du mois invalides');
          this.set('controller.isLoadingSave', false);
        } else {
          if (rule.get('beginTime')) {
            rule.set('beginTime', (0, _moment.default)(rule.get('beginTime')).format('HH:mm'));
          }
          if (rule.get('endTime')) {
            rule.set('endTime', (0, _moment.default)(rule.get('endTime')).format('HH:mm'));
          }
          if (!_lodash.default.isNil(this.get('controller.selectedDayOfMonth1'))) {
            rule.set('dayOfMonth', ('0' + this.get('controller.selectedDayOfMonth1')).slice(-2) + '-' + ('0' + this.get('controller.selectedDayOfMonth2')).slice(-2));
          }
          rule.save().then(function () {
            _this2.get('notifications').success('La règle a été créée avec succès');
            _this2.resetAttributes();
          }).catch(function () {
            _this2.get('notifications').error('La règle n\'a pas pu être créée');
          }).finally(function () {
            return _this2.set('controller.isLoadingSave', false);
          });
        }
      },
      cancel: function cancel() {
        this.resetAttributes();
      },
      willTransition: function willTransition() {
        this.resetAttributes();
      }
    }
  });
});