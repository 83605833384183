define('sags-front-bo/controllers/ccsp-claim/detail', ['exports', 'sags-front-bo/config/environment', 'sags-front-bo/utils/download-file', 'lodash', 'sags-front-bo/utils/dictionary', 'moment'], function (exports, _environment, _downloadFile, _lodash, _dictionary, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    isNotAllowedToEditTags: Ember.computed('currentUser', function () {
      return this.get('currentUser.user.role') === 'CITY' || this.get('currentUser.user.role') === 'CITY_CCSP';
    }),

    actions: {
      updateActionEvents: function updateActionEvents(actionEvent) {
        var fileType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        this.get('model.ccspClaim.actions').pushObject(actionEvent);
      },
      submitCcspClaim: function submitCcspClaim() {
        var _this = this;

        if (!_lodash.default.isEmpty(this.get('defenseMemoryAttachment'))) {
          var medFileAttachment = this.get('defenseMemoryAttachment')[0];
          this.set('isLoadingPdfResponse', true);
          // Soumission du recours CCSP
          this.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/' + this.get('model.ccspClaim.id') + '/submit', {
            method: 'PUT',
            headers: {
              Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
              City: this.get('currentUser.city')
            }
          }).then(function () {
            _this.get('notifications').success('Le statut du recours CCSP a été mis à jour avec succès.');
            _this.get('model.ccspClaim').set('status', "SUBMITTED");
            // Soumission OK, téléchargement du fichier Mémoire en défense
            _this.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/' + medFileAttachment.ccspClaim + '/attachments?fileName=' + medFileAttachment.internalFileName, {
              method: 'GET',
              headers: {
                Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
                City: _this.get('currentUser.city')
              }
            }).then(function (file) {
              (0, _downloadFile.default)(file.fileName, file.base64, 'application/pdf');
              _this.get('notifications').success('Le recours CCSP a été soumis avec succès.');
              // Téléchargement OK, ouverture d'un nouvel onglet avec le site de CCSP (implique d'accorder le droit au navigateur d'ouvrir un nouvel onglet)
              window.open(_this.get('ccspWebSiteUrl'));
              var submissionAction = _this.get('store').createRecord('ccsp-claim-action-event', {
                'action': 'SUBMISSION',
                'dateTime': (0, _moment.default)(),
                'agent': _this.get('currentUser.user'),
                'ccspClaim': _this.get('model.ccspClaim')
              });
              _this.send('updateActionEvents', submissionAction);
              var medFileDownloadAction = _this.get('store').createRecord('ccsp-claim-action-event', {
                'action': 'FILE_DOWNLOAD',
                'dateTime': (0, _moment.default)(),
                'agent': _this.get('currentUser.user'),
                'ccspClaim': _this.get('model.ccspClaim'),
                'fileName': medFileAttachment.name
              });
              _this.send('updateActionEvents', medFileDownloadAction, 'mémoire en défense');
            }).finally(function () {
              return _this.set('isLoadingPdfResponse', false);
            });
          }).catch(function () {
            _this.get('notifications').error('Une erreur de traitement s\'est produite.');
          });
        } else {
          this.get('notifications').error('Aucun fichier de type Mémoire en défense n\'a été chargé, veuillez le charger avant de soumettre le recours CCSP.');
        }
      }
    }
  });
});