define('sags-front-bo/components/draw-control', ['exports', 'ember-leaflet-draw/components/draw-control'], function (exports, _drawControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _drawControl.default;
    }
  });
});