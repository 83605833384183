define('sags-front-bo/routes/password-reset/finish', ['exports', 'lodash', 'sags-front-bo/config/environment'], function (exports, _lodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      // s'il n'y a pas de paramètre 'key', rediriger vers la page de connexion
      if (_lodash.default.isNil(controller.get('key'))) {
        this.transitionTo('login');
      }
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.set('password', null);
      controller.set('repeatPassword', null);
      controller.set('loading', false);
      controller.set('loadingError', false);
      controller.set('differentPasswords', false);
    },
    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      setTimeout(function () {
        // après le rendu, prendre le champ repeat-password et empêcher le copier-coller
        Ember.$('#repeat-password').bind('cut copy paste', function (e) {
          e.preventDefault();
        });
      }, 0);
    },


    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('controller.loadingError', false);
        this.set('controller.differentPasswords', false);

        if (this.get('controller.password') !== this.get('controller.repeatPassword')) {
          this.set('controller.differentPasswords', true);
        } else {
          this.set('controller.loading', true);
          this.get('ajax').request(_environment.default.APP.host + '/api/account/reset_password/finish', {
            method: 'POST',
            contentType: 'application/json',
            data: {
              key: this.get('controller.key'),
              newPassword: this.get('controller.password')
            },
            dataType: 'text'
          }).then(function () {
            _this.get('notifications').success('Votre mot de passe a été réinitialisé.');
            _this.transitionTo('login');
          }).catch(function () {
            _this.set('controller.loadingError', true);
          }).finally(function () {
            _this.set('controller.loading', false);
          });
        }
      }
    }
  });
});