define('sags-front-bo/controllers/rules/index', ['exports', 'moment', 'sags-front-bo/utils/dictionary', 'lodash'], function (exports, _moment, _dictionary, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    isAggregatedSectionClicked: false,
    isEdit: false,
    aggregatedSection: null,
    isDayOfWeekEmpty: true,
    isWeekOfMonthEmpty: true,
    isSaveDisabled: Ember.computed('isLoadingSave', 'createdRule.ruleType', 'createdRule.beginDate', 'createdRule.agentVisitFrequency', 'createdRule.lapiVisitFrequency', 'selectedDayOfMonth1', 'selectedDayOfMonth2', function () {
      return this.get('isLoadingSave') || _lodash.default.isNil(this.get('createdRule.ruleType')) || _lodash.default.isNil(this.get('createdRule.beginDate')) || _lodash.default.isNil(this.get('selectedDayOfMonth1')) && !_lodash.default.isNil(this.get('selectedDayOfMonth2')) || _lodash.default.isNil(this.get('selectedDayOfMonth2')) && !_lodash.default.isNil(this.get('selectedDayOfMonth1')) || this.get('createdRule.ruleType') === 'OBLIGATION' && (_lodash.default.isNil(this.get('createdRule').belongsTo('agentVisitFrequency').id()) || _lodash.default.isNil(this.get('createdRule').belongsTo('lapiVisitFrequency').id()));
    }),
    tableColumns: [{
      'key': 'ruleType',
      'displayName': 'Règle',
      transform: function transform(ruleType) {
        return _dictionary.default.ruleType[ruleType];
      }
    }, {
      'key': 'beginDate',
      'displayName': 'Date de début',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm');
      }
    }, {
      'key': 'endDate',
      'displayName': 'Date de fin',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm');
      }
    }, {
      'key': 'beginTime',
      'displayName': 'Heure de début',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date, ['HH:mm:ss', 'HH:mm']).format('HH:mm');
      }
    }, {
      'key': 'endTime',
      'displayName': 'Heure de fin',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date, ['HH:mm:ss', 'HH:mm']).format('HH:mm');
      }
    }, {
      'key': 'dayOfWeek',
      'displayName': 'Jour de semaine',
      transform: function transform(dayOfWeek) {
        return _dictionary.default.dayOfWeek[dayOfWeek];
      }
    }, {
      'key': 'weekOfMonth',
      'displayName': 'Semaine du mois'
    }, {
      'key': 'dayOfMonth',
      'displayName': 'Jour du mois'
    }, {
      'key': 'agentVisitFrequency.label',
      'displayName': 'Priorité agent'
    }, {
      'key': 'lapiVisitFrequency.label',
      'displayName': 'Priorité LAPI'
    }],

    actionIcons: [{ icon: 'trash', tooltip: 'Supprimer' }],

    actions: {
      setClickedAggregatedSection: function setClickedAggregatedSection(aggregatedSection) {
        this.set('isAggregatedSectionClicked', true);
        this.set('aggregatedSection', aggregatedSection);
      },
      selectLapiFrequency: function selectLapiFrequency(lapiFrequency) {
        this.set('createdRule.lapiVisitFrequency', lapiFrequency);
      },
      selectAgentFrequency: function selectAgentFrequency(agentFrequency) {
        this.set('createdRule.agentVisitFrequency', agentFrequency);
      },
      selectType: function selectType(type) {
        this.set('selectedType', this.get('ruleTypes').findBy('name', type.name));
        this.set('createdRule.ruleType', type.name);
        if (this.get('createdRule.ruleType') === 'OBLIGATION') {
          this.set('createdRule.beginTime', null);
          this.set('createdRule.endTime', null);
        } else {
          this.set('createdRule.agentVisitFrequency', null);
          this.set('createdRule.lapiVisitFrequency', null);
        }
      },
      selectDayOfWeek: function selectDayOfWeek(dayOfWeek) {
        if (_lodash.default.isNil(dayOfWeek)) {
          this.set('selectedDayOfWeek', null);
          this.set('createdRule.dayOfWeek', null);
          this.set('isDayOfWeekEmpty', true);
        } else {
          this.set('selectedDayOfWeek', this.get('daysOfWeek').findBy('name', dayOfWeek.name));
          this.set('createdRule.dayOfWeek', dayOfWeek.name);
          this.set('isDayOfWeekEmpty', false);
          // vider les jours du mois car la semaine du mois est renseignée
          this.set('selectedDayOfMonth1', null);
          this.set('selectedDayOfMonth2', null);
        }
      },
      selectWeekOfMonth: function selectWeekOfMonth(weekOfMonth) {
        if (_lodash.default.isNil(weekOfMonth)) {
          this.set('createdRule.weekOfMonth', null);
          this.set('isWeekOfMonthEmpty', true);
        } else {
          this.set('createdRule.weekOfMonth', weekOfMonth);
          this.set('isWeekOfMonthEmpty', false);
          // vider les jours du mois car la semaine du mois est renseignée
          this.set('selectedDayOfMonth1', null);
          this.set('selectedDayOfMonth2', null);
        }
      },
      selectDayOfMonth1: function selectDayOfMonth1(dayOfMonth) {
        this.set('selectedDayOfMonth1', dayOfMonth);
      },
      selectDayOfMonth2: function selectDayOfMonth2(dayOfMonth) {
        this.set('selectedDayOfMonth2', dayOfMonth);
      }
    }
  });
});