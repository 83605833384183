define('sags-front-bo/utils/download-file', ['exports', 'sags-front-bo/utils/converter'], function (exports, _converter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (filename, b64Data) {
    var contentType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var sliceSize = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 512;

    var url = (0, _converter.base64toObjectUrl)(b64Data, contentType, sliceSize);

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = filename;
    a.click();

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
    }, 100);
  };
});