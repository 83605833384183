define('sags-front-bo/routes/cars/index', ['exports', 'sags-front-bo/mixins/access-controller', 'lodash', 'sweetalert'], function (exports, _accessController, _lodash, _sweetalert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        cars: this.store.findAll('car'),
        devices: this.store.query('device', { type: 'TABLET', assignedToCar: true })
      });
    },

    actions: {
      deleteCar: function deleteCar(car) {
        var _this = this;

        (0, _sweetalert.default)({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment supprimer le v\xE9hicule "' + car.get('plate') + '" ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: 'Annuler',
          target: '#app-container'
        }).then(function () {
          car.destroyRecord().then(function () {
            _this.get('notifications').success('Le véhicule a été supprimé avec succès');
          }).catch(function () {
            _this.get('notifications').error('Le véhicule n\'a pas pu être supprimé');
          });
        }).catch(function () {/* aucun traitement, mais on fait disparaître l'erreur dans la console */});
      },
      changeStatusRepairing: function changeStatusRepairing(car) {
        var _this2 = this;

        if (!_lodash.default.isNil(car)) {
          car.set('state', 'REPAIRING');
          car.save().then(function () {
            _this2.get('notifications').success('Le véhicule a été mis en réparation');
          }).catch(function () {
            car.set('state', 'WORKING');
            _this2.get('notifications').error('Le véhicule n\'a pas pu être mis en réparation');
          });
        }
      },
      changeStatusWorking: function changeStatusWorking(car) {
        var _this3 = this;

        if (!_lodash.default.isNil(car)) {
          car.set('state', 'WORKING');
          car.save().then(function () {
            _this3.get('notifications').success('Le véhicule a été mis en service');
          }).catch(function () {
            car.set('state', 'REPAIRING');
            _this3.get('notifications').error('Le véhicule n\'a pas pu être mis en service');
          });
        }
      }
    }
  });
});