define("sags-front-bo/utils/gis", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function contains(polygon, point) {
    var c = void 0,
        i = void 0,
        l = void 0,
        j = void 0;
    for (c = false, i = -1, l = polygon.length, j = l - 1; ++i < l; j = i) {
      (polygon[i][1] <= point[1] && point[1] < polygon[j][1] || polygon[j][1] <= point[1] && point[1] < polygon[i][1]) && point[0] < (polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1]) / (polygon[j][1] - polygon[i][1]) + polygon[i][0] && (c = !c);
    }return c;
  }

  exports.contains = contains;
});