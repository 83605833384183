define('sags-front-bo/routes/white-list-bis/control-mode', ['exports', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/config/environment', 'sags-front-bo/utils/dictionary'], function (exports, _accessController, _environment, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {

    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return _dictionary.default.fetchSettingsForWhiteListBis(this.get('ajax'), this.get('session'), this.get('currentUser')).then(function (settings) {
        return Ember.RSVP.hash({
          controlMode: settings.controlMode
        });
      }).then(function (data) {
        _this.set('globalErrorMessage', null);
        return data;
      }).catch(function (reason) {
        return _this.set('globalErrorMessage', reason);
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controller.set('selectedMode', model.controlMode.toString());
    }
  });
});