define('sags-front-bo/routes/teams/team/edit', ['exports', 'sags-front-bo/mixins/access-controller', 'lodash'], function (exports, _accessController, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service('notifications'),
    currentUser: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);
      controller.set('editTeam', true);

      // réinitialiser le filtre "agents non rattachés" (pour que les agents apparaissent à droite)
      controller.set('withoutTeam', false);

      // (re-)vider la liste des agents sélectionnés (corrige un test d'acceptance)
      controller.set('selectedAgents', []);

      // attention, findAll ne fonctionne pas parce que le 'then' est exécuté avant que la liste entière soit chargée
      this.store.query('user', {}).then(function (users) {

        // s'assurer de charger aussi les équipes
        _this.store.findAll('team');

        var allUsers = users.map(function (user) {
          if (model.get('agents').filter(function (agent) {
            return agent.get('id') === user.get('id');
          }).length > 0 || user.get('id') === model.get('manager.id')) {
            user.set('isChecked', true);
            if (user.get('identification') === model.get('manager.identification')) {
              controller.set('selectedManager', user);
            }
          }
          return user;
        });

        controller.set('agents', allUsers);
        controller.send('addAgentsToList');
        controller.send('removeAgentsFromList');
        controller.set('originalSelectedAgents', [].pushObjects(controller.get('selectedAgents')));

        // Indicateur d'exécution de requête
        controller.set('isLoadingSave', false);
      });
    },
    resetController: function resetController(controller) {
      controller.set('selectedAgents', []);
      controller.set('selectedManager', null);
      controller.get('agents').forEach(function (agent) {
        return agent.set('isChecked', false);
      });
      controller.set('originalSelectedAgents', undefined);
    },


    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('teams');
      },
      save: function save() {
        var _this2 = this;

        this.set('controller.isLoadingSave', true);
        // on duplique la liste des agents choisis pour l'enregistrement (le manager est retiré de cette liste)
        var selectedAgents = [].pushObjects(this.controller.get('selectedAgents'));

        var model = this.get('controller.model');
        var selectedManager = this.controller.get('selectedManager');
        var selectedSupervisor = model.get('supervisor');

        var unselectedAgents = [].pushObjects(this.controller.get('originalSelectedAgents'));
        unselectedAgents.removeObjects(selectedAgents);

        if (_lodash.default.isEmpty(selectedAgents)) {
          this.get('notifications').error('Vous devez choisir au moins un agent !');
          this.set('controller.isLoadingSave', false);
        } else if (_lodash.default.isNil(selectedManager)) {
          this.get('notifications').error('Vous devez choisir un chef d\'équipe !');
          this.set('controller.isLoadingSave', false);
        } else if (_lodash.default.isNil(selectedSupervisor.get('identification'))) {
          this.get('notifications').error('Vous devez choisir un superviseur !');
          this.set('controller.isLoadingSave', false);
        } else {
          model.set('supervisor', selectedSupervisor);
          model.set('manager', selectedManager);

          // Supprimer le manager dans la list des agents de l'équipe.
          _lodash.default.remove(selectedAgents, function (agent) {
            return agent.get('identification') === selectedManager.get('identification');
          });

          model.set('agents', selectedAgents);

          Ember.run(function () {
            model.save().then(function (team) {
              // mettre à jour les rôles, les superviseurs et l'équipe de tous les agents
              var promises = selectedAgents.map(function (agent) {
                agent.set('role', 'AGENT');
                agent.set('supervisor', selectedSupervisor);
                agent.set('team', team);
                return agent.save();
              });

              // supprimer de l'équipe les agents / chef d'équipe qui ne sont plus sélectionnés
              unselectedAgents.forEach(function (agent) {
                agent.set('role', 'AGENT');
                agent.set('team', null);
                promises.push(agent.save());
              });

              selectedManager.set('role', 'MANAGER');
              selectedManager.set('supervisor', selectedSupervisor);
              selectedManager.set('team', team);
              promises.push(selectedManager.save());

              // chaque modification d'agent = une requête = une promise
              // la mise à jour est terminée = toutes les promesses sont résolues
              return Ember.RSVP.all(promises);
            }).then(function () {
              _this2.get('notifications').success('L\'équipe a été modifiée avec succès');

              _this2.transitionTo('teams');
            }).catch(function () {
              _this2.get('notifications').error('L\'équipe n\'a pas pu être modifiée');
            }).finally(function () {
              return _this2.set('controller.isLoadingSave', false);
            });
          });
        }
      },
      willTransition: function willTransition() {
        this.get('controller.model').rollbackAttributes();
      }
    }

  });
});