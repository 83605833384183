define('sags-front-bo/routes/ccsp-claim/index', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('content', controller.get('store').query('ccsp-claim', {}));
    }
  });
});