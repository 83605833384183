define('sags-front-bo/controllers/users/create', ['exports', 'sags-front-bo/utils/dictionary', 'sags-front-bo/utils/file-input-to-model'], function (exports, _dictionary, _fileInputToModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var createUserRoles = _dictionary.default.convertToPowerSelectOptions(_dictionary.default.createUserRoles);
  var createUserRolesNonAdmin = _dictionary.default.convertToPowerSelectOptions(_dictionary.default.createUserRolesNonAdmin);

  var fields = ['lastName', 'firstName', 'phone', 'email', 'voIP', 'photo', 'drivingLicense', 'authorizedNumber', 'humanResourceId', 'contractedWorkingHoursPerWeek', 'supervisor'];

  exports.default = Ember.Controller.extend({

    currentUser: Ember.inject.service(''),

    roles: Ember.computed('currentUser.user.role', function () {
      return this.get('currentUser.user.role') === 'ADMINISTRATOR' ? createUserRoles : createUserRolesNonAdmin;
    }),

    selectedRole: createUserRoles[0],

    actions: {
      onFileChanged: function onFileChanged(event) {
        (0, _fileInputToModel.default)(event, this.get('model'), 'photoBase64', 'photo');
      },
      selectRole: function selectRole(role) {
        var _this = this;

        this.set('selectedRole', createUserRoles.findBy('name', role.name));
        this.get('model').set('role', role.name);

        Ember.run.later(function () {
          // une fois la page mise à jour, vider les champs (devenus) invisibles
          fields.forEach(function (field) {
            var fieldElement = field.dasherize();
            if (Ember.$('#' + fieldElement).length === 0) {
              _this.set('model.' + field, undefined);
            }
          });
        });
      },
      selectSupervisor: function selectSupervisor(supervisor) {
        this.get('model').set('supervisor', supervisor);
      },
      selectCities: function selectCities(cities) {
        this.get('model').set('cities', cities);
      },
      selectCity: function selectCity(city) {
        var tab = [];
        tab.push(city);
        this.get('model').set('cities', tab);
      }
    }
  });
});