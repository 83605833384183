define('sags-front-bo/routes/users/create', ['exports', 'sags-front-bo/mixins/access-controller', 'lodash'], function (exports, _accessController, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service('notifications'),
    templateName: 'users/user/edit',
    currentUser: Ember.inject.service(),

    setupController: function setupController(controller, model) {

      this._super(controller, model);

      controller.set('editUser', false);
      // Afficher le rôle agent par défaut
      controller.get('model').set('role', 'AGENT');
      controller.set('supervisors', this.store.query('user', { role: 'SUPERVISOR' }));
      controller.get('model').set('cities', [this.get('currentUser').get('city')]);
      controller.set('cities', this.get('currentUser').get('user').get('cities'));
      controller.set('isLoadingSave', false);
    },
    resetController: function resetController(controller) {
      controller.set('selectedRole', controller.get('roles').get('firstObject'));
    },
    model: function model() {
      return this.store.createRecord('user');
    },


    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('users');
      },
      save: function save() {
        var _this = this;

        this.set('controller.isLoadingSave', true);
        var user = this.get('controller.model');

        var alphanumericPattern = new RegExp('^[a-zA-Z0-9]+$');
        if (!_lodash.default.isEmpty(user.get('authorizedNumber')) & !alphanumericPattern.test(user.get('authorizedNumber'))) {
          this.get('notifications').error('Le numéro d\'assermentation doit être alphanumérique');
        } else if (_lodash.default.isEmpty(user.get('cities'))) {
          this.get('notifications').error('Au moins une ville doit être sélectionnée');
        } else {
          var supervisor = user.get('supervisor');

          // texte vide => undefined (évite des problèmes de contrainte de validation)
          ['phone', 'email', 'voIP', 'photo', 'authorizedNumber', 'humanResourceId', 'contractedWorkingHoursPerWeek'].forEach(function (field) {
            // les nombres sont considérés "vides" par Lodash...
            if (_lodash.default.isEmpty(user.get(field)) && !_lodash.default.isNumber(user.get(field))) {
              user.set(field, undefined);
            }
          });

          if (supervisor) {
            user.set('supervisor', supervisor);
          }
          user.save().then(function () {
            _this.get('notifications').success('L\'utilisateur a été créé avec succès');
            _this.transitionTo('users');
          }).catch(function () {
            _this.get('notifications').error('L\'utilisateur n\'a pas pu être créé');
          }).finally(function () {
            return _this.set('controller.isLoadingSave', false);
          });
        }
      },
      willTransition: function willTransition() {
        this.get('controller.model').rollbackAttributes();
      }
    }
  });
});