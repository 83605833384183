define('sags-front-bo/controllers/fps/index', ['exports', 'moment', 'sags-front-bo/utils/dictionary', 'sags-front-bo/helpers/currency-formatter', 'lodash'], function (exports, _moment, _dictionary, _currencyFormatter, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    tableColumns: [{
      'key': 'legalId',
      'displayName': 'Numéro',
      'customComponent': 'link-to-detail'
    }, {
      'key': 'type',
      'displayName': 'Statut',
      transform: function transform(type) {
        return _dictionary.default.fpsType[type];
      }
    }, {
      'key': 'vehiclePlate',
      'displayName': 'Immatriculation'
    }, {
      'key': 'amount',
      'displayName': 'Montant',
      transform: function transform(valueInCents) {
        return _currencyFormatter.default.compute([valueInCents]);
      }
    }, {
      'key': 'creationDate',
      'displayName': 'Date d’émission',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'validityDate',
      'displayName': 'Valide jusqu’au',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }, {
      'key': 'reducedDate',
      'displayName': 'Date de validité du prix réduit',
      transform: function transform(date) {
        if (_lodash.default.isNil(date)) {
          return '';
        }
        return (0, _moment.default)(date).format('DD/MM/YYYY HH:mm:ss');
      }
    }],
    filters: {
      legalId: {
        comparator: 'contains',
        value: ''
      },
      vehiclePlate: {
        comparator: 'contains',
        value: ''
      }
    },
    serverSideFilters: {
      creationDate: {
        comparator: 'dateEquals',
        value: new Date()
      }
    },
    possibleFpsTypes: _dictionary.default.convertToArray(_dictionary.default.fpsType, [{ key: '*', value: 'Tous' }]),
    possibleRapoStatuses: _dictionary.default.convertToArray(_dictionary.default.rapoStatus, [{ key: '*', value: 'Tous' }]),
    possibleFpsStatuses: _dictionary.default.convertToArray(_dictionary.default.fpsStatus, [{ key: '*', value: 'Tous' }]),
    linkComponentConfig: {
      targetRoute: 'fps.detail',
      targetItem: 'externalId'
    },

    refreshWithServerSideFilter: function refreshWithServerSideFilter(controller, store) {
      var creationDate = controller.get('serverSideFilters.creationDate.value');
      if (_lodash.default.isNil(creationDate)) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        // Remplir le champ "Date de création" avec la date du jour
        controller.set('serverSideFilters.creationDate.value', today);

        // Filtre par la date d'aujourd'hui
        controller.set('content', store.query('fps', {
          creationDate: today.toISOString()
        }));
      } else {
        creationDate.setHours(0, 0, 0, 0);
        var query = store.query('fps', {
          creationDate: creationDate.toISOString()
        });

        controller.set('content', query);
      }
    },

    actions: {
      setCreationDate: function setCreationDate() {
        this.refreshWithServerSideFilter(this, this.store);
      }
    }
  });
});