define('sags-front-bo/controllers/ccsp-claim/create', ['exports', 'sags-front-bo/utils/dictionary', 'lodash', 'moment', 'sags-front-bo/config/environment'], function (exports, _dictionary, _lodash, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    ccspClaimTags: _dictionary.default.convertToPowerSelectOptions(_dictionary.default.ccspClaimTags),
    queryParams: ['legalId'],
    legalId: null,

    validationErrorMessage: Ember.computed('tag', 'model.claimerName', 'model.ccspClaimRecourseDate', 'model.transmissionDateToCity', 'model.transmissionDateFromCity', 'transmissionDateFromCity', 'fpsLegalIdEnd', function () {
      var missingInfo = [];
      if (_lodash.default.isEmpty(this.get('tag'))) {
        missingInfo.push('Motif');
      }
      if (_lodash.default.size(this.get('fpsLegalIdEnd')) < 12) {
        missingInfo.push('Numéro de FPS');
      }
      if (_lodash.default.isEmpty(this.get('model.claimerName'))) {
        missingInfo.push('Nom');
      }
      if (_lodash.default.isNil(this.get('model.ccspClaimRecourseDate'))) {
        missingInfo.push('Date de saisie');
      }
      if (_lodash.default.isNil(this.get('model.transmissionDateToCity'))) {
        missingInfo.push('Date de transmission du recours à la ville');
      }
      if (_lodash.default.isNil(this.get('model.transmissionDateFromCity'))) {
        missingInfo.push('Date de transmission du recours par la ville');
      }
      if (_lodash.default.isEmpty(missingInfo)) {
        // pas d'erreur de validation
        return null;
      }
      return 'Les informations suivantes sont manquantes : ' + missingInfo.join(', ') + '.';
    }),

    medLimitProductionDateObserver: Ember.observer('model.transmissionDateToCity', function () {
      if (!_lodash.default.isNil(this.get('model.transmissionDateToCity'))) {
        // Contournement : pour un soucis de non envoi de champs medLimitProductionDate lorsqu'on utilise directment moment.add(...).format(), le champ est envoyé à null à partir du front
        var medLimitProductionDate = new Date();
        var transmissionDateToCityPlusOneMonth = (0, _moment.default)(this.get('model.transmissionDateToCity')).add(1, 'M');
        medLimitProductionDate.setFullYear(transmissionDateToCityPlusOneMonth.year());
        medLimitProductionDate.setMonth(transmissionDateToCityPlusOneMonth.month());
        medLimitProductionDate.setDate(transmissionDateToCityPlusOneMonth.date());
        this.set('model.medLimitProductionDate', medLimitProductionDate);
      } else {
        this.set('model.medLimitProductionDate', null);
      }
    }),
    actions: {
      selectTag: function selectTag(tag) {
        this.set('tag', tag);
      },
      save: function save() {
        var _this = this;

        this.set('model.fpsLegalId', this.get('siret') + '' + this.get('fpsLegalIdEnd'));
        this.set('model.tag', this.get('tag.name'));
        this.get('model').save().then(function (_ref) {
          var id = _ref.id;

          _this.get('notifications').success('Le recours a été créé avec succès');
          _this.set('tag', undefined);
          _this.get('store').queryRecord('fps', { legalId: _this.get('model.fpsLegalId') }).then(function (fps) {
            _this.get('store').queryRecord('rapo', { fpsExternalId: fps.get('externalId') }).then(function (rapoDTO) {
              if (rapoDTO) {
                var url = _environment.default.APP.host + '/rapo/api/rapos/' + rapoDTO.get('id') + '/ccsp-claim-created';
                _this.get('ajax').request(url, {
                  method: 'PUT',
                  headers: {
                    Authorization: 'Bearer ' + _this.get('session.session.authenticated.id_token'),
                    City: _this.get('currentUser.city')
                  }
                }).then(function () {
                  _this.get('notifications').success('Le RAPO associé au FPS a été mis à jour');
                }).catch(function () {
                  _this.get('notifications').error('Le RAPO associé au FPS n\'a pas pu être mis à jour');
                });
              }
            }).catch(function (error) {
              if (error.errors[0].status !== '404') {
                // 404 n'est pas une erreur car un FPS peut ne pas avoir de RAPO
                _this.get('notifications').error('Une erreur s\'est produite lors de la récupération du RAPO associé au FPS');
              }
            });
          });
          setTimeout(function () {
            return _this.transitionToRoute('ccsp-claim.detail', id);
          }, 0);
        }).catch(function (error) {
          if (error.errors[0].message === 'error.fps.not.found') {
            _this.get('notifications').error('le FPS n\'a pas pu être retrouvé avec le numéro indiqué');
          } else if (error.errors[0].message === 'error.fps.claim.type.is.regulatory') {
            _this.get('notifications').error('Le FPS possède déjà un recours CCSP');
          } else {
            _this.get('notifications').error('Une erreur est survenue pendant le traitement');
          }
        });
      },
      cancel: function cancel() {
        this.set('tag', undefined);
        this.transitionToRoute('ccsp-claim.create-reset');
      }
    }
  });
});