define('sags-front-bo/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service(),
    sideMenu: [{
      icon: 'id-badge',
      title: 'Effectifs',
      options: [{
        target: 'users',
        name: 'Gestion des utilisateurs',
        icon: 'user-o'
      }, {
        target: 'teams',
        name: 'Gestion des équipes',
        icon: 'users'
      }, {
        target: 'teams.new-planning',
        name: 'Planning',
        icon: 'calendar-o'
      }, {
        target: 'users.tour',
        name: 'Tournées',
        icon: 'road'
      }]
    }, {
      icon: 'car',
      title: 'Contrôles',
      options: [{
        target: 'controls.activities',
        name: 'Suivi de l\'activité',
        icon: 'tachometer'
      }, {
        target: 'controls',
        name: 'Liste des contrôles',
        icon: 'list'
      }, {
        target: 'controls.vao',
        name: 'Traitement VAO',
        icon: 'desktop'
      }, {
        target: 'fps',
        name: 'Liste des FPS',
        icon: 'list'
      }, {
        target: 'controls.annulation',
        name: 'Annulation FPS',
        icon: 'list'
      }, {
        target: 'observations',
        name: 'Liste des observations',
        icon: 'list'
      }, {
        target: 'controls.vehicle-plates',
        name: 'Plaques',
        icon: 'id-card'
      }, {
        target: 'controls.white-list-edition',
        name: 'Liste Blanche',
        icon: 'list'
      }]
    }, {
      title: 'Mode contrôle',
      icon: 'gear',
      hasSubmenu: true,
      options: [{
        target: 'white-list-bis.control-mode',
        name: 'Choix du mode de contrôle',
        icon: 'toggle-off'
      }, {
        name: 'Liste sous surveillance 1',
        icon: 'list',
        target: 'white-list-bis.liste_sous_surveillance',
        options: [{
          target: 'black-list.delete',
          name: 'Retirer une plaque',
          icon: 'trash'
        }, {
          target: 'black-list.create',
          name: 'Nouvelle plaque',
          icon: 'plus'
        }, {
          target: 'black-list.list',
          name: 'Import/export d\'observation',
          icon: 'download'
        }, {
          target: 'black-list.purge',
          name: 'Suppression',
          icon: 'trash'
        }]
      }, {
        name: 'Mode surveillance 2',
        icon: 'list',
        target: 'white-list-bis.mode_surveillance',
        options: [{
          target: 'white-list-bis.auto-mode',
          name: 'Ajout auto plaque contrôlée',
          icon: 'toggle-off'
        }, {
          target: 'white-list-bis.delete',
          name: 'Retirer une plaque',
          icon: 'trash'
        }, {
          target: 'white-list-bis.create',
          name: 'Ajouter une plaque',
          icon: 'plus'
        }, {
          target: 'white-list-bis.list',
          name: 'Import/export d\'observation',
          icon: 'download'
        }, {
          target: 'white-list-bis.purge',
          name: 'Suppression',
          icon: 'trash'
        }, {
          target: 'white-list-bis.nb-plate-characters',
          name: 'Troncage plaque',
          icon: 'crop'
        }]
      }]
    }, {
      icon: 'flag',
      title: 'RAPO',
      options: [{
        target: 'rapo',
        name: 'Gestion des RAPO',
        icon: 'search'
      }, {
        target: 'rapo.create',
        name: 'Nouveau RAPO',
        icon: 'plus'
      }, {
        target: 'rapo.instruction',
        name: 'Instruction RAPO',
        icon: 'edit'
      }, {
        target: 'rapo.edition',
        name: 'Éditique',
        icon: 'envelope'
      }, {
        target: 'rapo.rattrap',
        name: 'Rattrapage Rapo',
        icon: 'edit'
      }, {
        target: 'rapo.synchro',
        name: 'Synchronisation RAPO',
        icon: 'edit'
      }]
    }, {
      icon: 'balance-scale',
      title: 'CCSP',
      options: [{
        target: 'ccsp-claim',
        name: 'Gestion des Recours CCSP',
        icon: 'search'
      }, {
        target: 'ccsp-claim.create',
        name: 'Nouveau recours CCSP',
        icon: 'plus'
      }]
    }, {
      icon: 'suitcase',
      title: 'Équipements',
      options: [{
        target: 'devices',
        name: 'Gestion des terminaux',
        icon: 'tablet'
      }, {
        target: 'cars',
        name: 'Gestion des véhicules',
        icon: 'car'
      }]
    }, {
      icon: 'globe',
      title: 'SIG',
      options: [{
        target: 'gis.file-management',
        name: 'Gestion des données',
        icon: 'database'
      }, {
        target: 'gis.frequency-management',
        name: 'Définition des priorités',
        icon: 'sort-amount-desc'
      }, {
        target: 'sectors.create',
        name: 'Définition des secteurs',
        icon: 'map-o'
      }, {
        target: 'sectors.assignment',
        name: 'Affectation des secteurs',
        icon: 'map-marker'
      }, {
        target: 'rules',
        name: 'Modification des règles de passage',
        icon: 'edit'
      }]
    }],

    actions: {
      logout: function logout() {
        delete localStorage.city;
        this.get('session').invalidate();
      },
      cityChanged: function cityChanged(value) {
        this.get('currentUser').set('city', value);
        localStorage.city = value;

        this.transitionToRoute('reset', {
          queryParams: {
            originalRoute: this.get('currentRouteName')
          }
        });
      }
    }
  });
});