define('sags-front-bo/routes/teams/new-planning', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      this.transitionTo('teams.planning');
    }
  });
});