define('sags-front-bo/routes/black-list/create', ['exports', 'lodash', 'sags-front-bo/mixins/access-controller'], function (exports, _lodash, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service('notifications'),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isLoadingCreate', false);
    },
    model: function model() {
      return this.store.createRecord('black-list');
    },


    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.refresh();
      },
      save: function save() {
        var _this = this;

        var blackListEntry = this.get('controller.model');
        var vehiclePlate = blackListEntry.get('vehiclePlate');
        if (!blackListEntry.get('code') || !blackListEntry.get('type') || !vehiclePlate) {
          this.get('notifications').error('Veuillez renseigner tous les champs obligatoires');
          return;
        }
        this.set('controller.isLoadingCreate', true);
        blackListEntry.save().then(function () {
          _this.get('notifications').success('La plaque a été ajoutée avec succès');
          _this.refresh();
        }).catch(function (error) {
          if (_lodash.default.isArray(error.errors) && error.errors.length > 0 && error.errors[0].status === '400') {
            _this.get('notifications').error('La plaque est invalide ou existe déjà dans la liste de surveillance');
          } else {
            _this.get('notifications').error('La plaque n\'a pas pu être ajoutée');
          }
        }).finally(function () {
          return _this.set('controller.isLoadingCreate', false);
        });
      },
      willTransition: function willTransition() {
        this.get('controller.model').rollbackAttributes();
      }
    }

  });
});