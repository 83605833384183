define('sags-front-bo/routes/teams/team', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),

    model: function model(params) {
      return this.store.queryRecord('team', { identification: params.id });
    },


    serialize: function serialize(model) {
      return {
        id: model.get('identification')
      };
    }
  });
});