define('sags-front-bo/routes/password-reset/init', ['exports', 'sags-front-bo/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),

    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.set('identification', null);
      controller.set('email', null);
      controller.set('loading', false);
      controller.set('loadingError', false);
    },


    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('controller.loading', true);
        this.set('controller.loadingError', false);

        this.get('ajax').request(_environment.default.APP.host + '/api/account/reset_password/init', {
          method: 'POST',
          contentType: 'application/json',
          data: {
            email: this.get('controller.email'),
            identification: this.get('controller.identification')
          },
          dataType: 'text'
        }).then(function () {
          _this.get('notifications').success('Un e-mail de réinitialisation du mot de passe vous a été envoyé.');
          _this.transitionTo('login');
        }).catch(function () {
          _this.set('controller.loadingError', true);
        }).finally(function () {
          _this.set('controller.loading', false);
        });
      }
    }
  });
});