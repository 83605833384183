define('sags-front-bo/models/observation', ['exports', 'ember-data', 'sags-front-bo/utils/dictionary', 'lodash'], function (exports, _emberData, _dictionary, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    datetime: _emberData.default.attr('date'),
    reason: _emberData.default.attr('string'),
    reasonName: _emberData.default.attr('string'),
    categoryName: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number'),
    streetNumber: _emberData.default.attr('string'),
    streetNumberBis: _emberData.default.attr('string'),
    streetType: _emberData.default.attr('string'),
    streetName: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    agentIdentification: _emberData.default.attr('string'),
    picture: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),

    address: Ember.computed('streetNumber', 'streetNumberBis', 'streetType', 'streetName', function () {
      var address = '';
      if (!_lodash.default.isNil(this.get('streetNumber'))) {
        address = this.get('streetNumber');
      }
      if (!_lodash.default.isNil(this.get('streetNumberBis'))) {
        address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetNumberBis[this.get('streetNumberBis')];
      }
      if (!_lodash.default.isNil(this.get('streetType'))) {
        address += (_lodash.default.isEmpty(address) ? '' : ' ') + _dictionary.default.streetType[this.get('streetType')];
      }
      if (!_lodash.default.isNil(this.get('streetName'))) {
        address += (_lodash.default.isEmpty(address) ? '' : ' ') + this.get('streetName');
      }
      return address;
    })
  });
});