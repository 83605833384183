define('sags-front-bo/routes/users/tour', ['exports', 'sags-front-bo/mixins/access-controller'], function (exports, _accessController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accessController.default, {
    currentUser: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        teams: this.get('currentUser.user.role') === 'SUPERVISOR' ? this.store.query('team', { supervisorId: this.get('currentUser.user.id') }) : this.store.findAll('team'),
        team: null,
        agents: null,
        agent: null,
        startDatetime: new Date(),
        tours: [],
        tour: null,
        mode: null
      }).then(function (data) {
        _this.set('globalErrorMessage', null);
        return data;
      }).catch(function (reason) {
        return _this.set('globalErrorMessage', reason);
      });
    }
  });
});