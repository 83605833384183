define('sags-front-bo/models/ccsp-claim-action-event', ['exports', 'ember-data', 'moment', 'sags-front-bo/utils/dictionary', 'lodash'], function (exports, _emberData, _moment, _dictionary, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    ccspClaim: _emberData.default.belongsTo('ccsp-claim'),
    agent: _emberData.default.belongsTo('user'),
    dateTime: _emberData.default.attr('string'),
    action: _emberData.default.attr('string'),
    fileName: _emberData.default.attr('string'),
    formattedDate: Ember.computed('dateTime', function () {
      return (0, _moment.default)(this.get('dateTime')).format('DD/MM/YYYY HH:mm');
    }),
    actionLabel: Ember.computed('action', 'fileName', 'fileType', function () {
      if (!_lodash.default.isNil(this.get('fileName'))) {
        return _dictionary.default.ccspClaimEvents[this.get('action')] + ' ' + _lodash.default.lowerFirst(_dictionary.default.ccspClaimAttachmentTypes[this.get('fileType')]) + ' : ' + this.get('fileName');
      } else {
        return _dictionary.default.ccspClaimEvents[this.get('action')];
      }
    })
  });
});