define('sags-front-bo/components/tariff-zone-element', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fieldValue: Ember.computed('sharedVariable.tariffZones', 'item', function () {
      var _this = this;

      if (_lodash.default.isEmpty(this.get('item.urbanParkingSiteId'))) {
        return 'Inconnue';
      }

      return this.get('item.urbanParkingSiteId').map(function (site) {
        return _this.get('sharedVariable.tariffZones.' + site);
      }).filter(function (site) {
        return site !== null;
      }).join(', ');
    })
  });
});