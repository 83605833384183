define('sags-front-bo/helpers/one-of', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.oneOf = oneOf;


  /**
   * Vérifie si le 1er paramètre correspond à l'un des paramètres suivants.
   * Exemple : {{one-of variable 'un' 'deux'}} vaut true si variable === 'un' ou variable === 'deux'.
   * @param {Array} params 1er paramètre = élément à comparer, paramètres suivants = liste des valeurs acceptables
   */
  function oneOf(params) {
    return _lodash.default.includes(params, params[0], 1);
  }

  exports.default = Ember.Helper.helper(oneOf);
});