define('sags-front-bo/helpers/is-nil', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isNil = isNil;
  function isNil(params) {
    return _lodash.default.isNil(params[0]);
  }

  exports.default = Ember.Helper.helper(isNil);
});