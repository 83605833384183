define('sags-front-bo/controllers/devices/create', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      selectCities: function selectCities(cities) {
        this.set('model.cities', cities);
      },
      selectType: function selectType(type) {
        this.set('selectedType', this.get('deviceTypes').findBy('name', type.name));
        this.set('model.type', type.name);
      }
    }
  });
});