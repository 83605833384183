define('sags-front-bo/components/sector-power-select-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      selectSector: function selectSector(sector) {
        this.set('item.sector', sector);
      }
    }
  });
});