define('sags-front-bo/serializers/city', ['exports', 'sags-front-bo/serializers/application', 'lodash'], function (exports, _application, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'name',

    // ['Annemasse', 'Tours'] => [{name: 'Annemasse'}, {name: 'Tours'}]
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var modelizedItems = payload.map(function (city) {
        return _lodash.default.isString(city) ? { name: city } : city;
      });
      return this._super(store, primaryModelClass, modelizedItems, id, requestType);
    }
  });
});