define('sags-front-bo/components/ccsp-claim/comments', ['exports', 'moment', 'sags-front-bo/config/environment', 'lodash'], function (exports, _moment, _environment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),

    columnsNames: [{
      'key': 'formattedDate',
      'displayName': 'Date'
    }, {
      'key': 'agent.fullName',
      'displayName': 'Acteur'
    }, {
      'key': 'comment',
      'displayName': 'commentaire'
    }],

    actions: {
      postComment: function postComment() {
        var _this = this;

        if (!_lodash.default.isEmpty(_lodash.default.trim(this.get('newCommentContent')))) {
          var commentDate = (0, _moment.default)();
          this.get('ajax').request(_environment.default.APP.host + '/control/api/ccsp-claims/comment', {
            method: 'POST',
            contentType: 'application/json',
            headers: {
              Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
              City: this.get('currentUser.city')
            },
            data: {
              ccspClaimId: this.get('ccspClaim.id'),
              agent: this.get('currentUser.user.id'),
              comment: this.get('newCommentContent'),
              date: commentDate
            }
          }).then(function () {
            var comment = _this.get('store').createRecord('ccsp-claim-comment', {
              'comment': _this.get('newCommentContent'),
              'date': commentDate,
              'agent': _this.get('currentUser.user'),
              'ccspClaim': _this.get('ccspClaim')
            });
            _this.get('ccspClaim.comments').pushObject(comment);
            var action = _this.get('store').createRecord('ccsp-claim-action-event', {
              'action': 'COMMENT',
              'dateTime': commentDate,
              'agent': _this.get('currentUser.user'),
              'ccspClaim': _this.get('ccspClaim')
            });
            _this.get('updateActionEvents')(action);
            _this.set('newCommentContent', null);
            _this.get('notifications').success('Le commentaire a été ajouté avec succès');
          }).catch(function () {
            _this.get('notifications').error('Une erreur est survenue');
          });
        } else {
          this.get('notifications').error('Le commentaire ne peut pas être vide');
        }
      }
    }
  });
});