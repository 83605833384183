define('sags-front-bo/controllers/reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['originalRoute'],
    originalRoute: null
  });
});