define('sags-front-bo/routes/application', ['exports', 'sags-front-bo/config/environment', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _environment, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),

    // charge l'utilisateur s'il est déjà connecté à son arrivée
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionInvalidated: function sessionInvalidated() {
      if (this.get('session.skipRedirectOnInvalidation')) {
        this.set('session.skipRedirectOnInvalidation', false);
      } else {
        this._super.apply(this, arguments);
      }
    },


    // charge l'utilisateur s'il vient de se connecter
    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this;

      // on s'assure d'avoir chargé l'utilisateur connecté AVANT de faire le reste
      // (charger les utilisateurs de la page /users par exemple)
      var originalAction = this._super;
      this._loadCurrentUser().then(function () {
        originalAction.apply(_this);
      });
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this2 = this;

      return this.get('currentUser').load().then(function () {
        if (_this2.get('currentUser.user.status') === 'INITIALIZED') {
          _this2.get('ajax').request(_environment.default.APP.host + '/api/account/reset_password/mandatoryReset', {
            method: 'POST',
            contentType: 'application/json',
            headers: {
              Authorization: 'Bearer ' + _this2.get('session.session.authenticated.id_token')
            }
          }).then(function (response) {
            var session = _this2.get('session');
            session.set('skipRedirectOnInvalidation', true);
            session.invalidate().then(function () {
              _this2.transitionTo('password-reset.finish', { queryParams: { key: response.key } });
            });
          });
        }
      }).catch(function () {
        delete localStorage.city;
        _this2.get('session').invalidate();
      });
    }
  });
});