define('sags-front-bo/components/export-selector-onselect', ['exports', 'ember-cli-data-export/components/export-selector-onselect'], function (exports, _exportSelectorOnselect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _exportSelectorOnselect.default;
    }
  });
});