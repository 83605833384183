define('sags-front-bo/components/rapo/global-infos-rapo', ['exports', 'lodash', 'sags-front-bo/config/environment', 'sags-front-bo/utils/dictionary', 'sags-front-bo/utils/file-input-to-model-array'], function (exports, _lodash, _environment, _dictionary, _fileInputToModelArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),

    civilities: _dictionary.default.convertToArray(_dictionary.default.civilities, [{ key: '', value: 'Sélectionner...' }]),
    streetNumbersBisForBuyerForm: _dictionary.default.convertToArray(_dictionary.default.streetNumberBis, [{ key: '', value: 'Sélectionner...' }]),
    streetTypesForBuyerForm: _dictionary.default.convertToArray(_dictionary.default.streetType, [{ key: '', value: 'Sélectionner...' }]),

    isLoadingCompletion: false,

    rapo: Ember.computed.alias('model.rapo'),

    showBuyerForm: Ember.computed('hasBuyer', 'inInstruction', function () {
      return this.get('hasBuyer') || this.get('inInstruction');
    }),

    actions: {
      uploadAttachment: function uploadAttachment(e) {
        var files = e.target.files;
        var type = e.target.id;

        // ligne magique : à revoir si on a le temps
        this.set('rapo.missingAttachments', this.get('rapo.missingAttachments').filter(function (item) {
          return item.type !== type;
        }));

        // et s'arrêter là si aucun fichier n'est choisi
        if (_lodash.default.isNil(files) || files.length === 0) {
          return;
        }

        var error = false;

        // vider le champ input en cas d'erreur, sinon lire le fichier
        if (error) {
          e.target.value = '';
        } else {
          (0, _fileInputToModelArray.default)(e, this.get('rapo.missingAttachments'), type);
        }
      },
      uploadAllTheMissingAttachments: function uploadAllTheMissingAttachments() {
        var _this = this;

        this.set('isLoadingCompletion', true);

        this.get('ajax').request(_environment.default.APP.host + '/rapo/api/rapos/' + this.get('rapo.number') + '/complete?agentId=' + this.get('currentUser.user').get('id'), {
          method: 'PUT',
          contentType: 'application/json',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          data: this.get('rapo.missingAttachments')
        }).then(function () {
          _this.set('rapo.status', 'TO_REINSTRUCT');
          _this.set('showCompletionForm', false);
          _this.get('notifications').success('Le dossier a bien été complété avec les pièces-jointes associées');
        }).catch(function () {
          _this.get('notifications').error('Erreur lors la complétion du dossier, veuillez réessayer plus tard');
        }).finally(function () {
          _this.set('isLoadingCompletion', false);
        });
      }
    }
  });
});