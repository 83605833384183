define('sags-front-bo/controllers/teams/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    tableColumns: [{
      'key': 'identification',
      'displayName': 'Nom de l\'équipe',
      'customComponent': 'link-to-detail'
    }, {
      'key': 'supervisor.fullName',
      'displayName': 'Superviseur'
    }, {
      'key': 'manager.fullName',
      'displayName': 'Chef d\'équipe'
    }],
    // filtres actifs sur la liste
    filters: {

      identification: {
        comparator: 'contains',
        value: ''
      },
      manager_identification: {
        comparator: 'equals',
        value: '*'
      },
      supervisor_identification: {
        comparator: 'equals',
        value: '*'
      }
    },

    managers: Ember.computed('agents', 'agents.@each', function () {
      var listManagers = this.get('agents').filterBy('role', 'MANAGER').map(function (agent) {
        return { key: agent.get('identification'), value: agent.get('fullName') };
      });
      listManagers.unshiftObject({ key: '*', value: 'Tous' });
      return listManagers;
    }),

    supervisors: Ember.computed('agents', 'agents.@each', function () {
      var listSupervisors = this.get('agents').filterBy('role', 'SUPERVISOR').map(function (agent) {
        return { key: agent.get('identification'), value: agent.get('fullName') };
      });
      listSupervisors.unshiftObject({ key: '*', value: 'Tous' });
      return listSupervisors;
    }),

    actionIcons: [{ 'icon': 'trash', 'tooltip': 'Supprimer' }],

    linkComponentConfig: {
      targetRoute: 'teams.team.edit'
    }

  });
});