define('sags-front-bo/routes/users/user/edit', ['exports', 'moment', 'sags-front-bo/config/environment', 'sags-front-bo/mixins/access-controller', 'sags-front-bo/utils/dictionary', 'lodash'], function (exports, _moment, _environment, _accessController, _dictionary, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var userRoles = _dictionary.default.convertToPowerSelectOptions(_dictionary.default.userRoles);

  exports.default = Ember.Route.extend(_accessController.default, {
    notifications: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      controller.set('isEditAgent', false);
      controller.set('isControlPresent', false);
      // initialisation du titre de la page de modification d'un utilisateur en fonction de son role
      controller.set('editTitle', 'Modification de l\'utilisateur ' + model.get('identification') + ' (' + _dictionary.default.userRoles[model.get('role')] + ')');

      if (model.get('role') === 'AGENT' | model.get('role') === 'MANAGER') {
        controller.set('isEditAgent', true);
        var today = new Date();
        controller.set('startDatetime', today);
        controller.set('endDatetime', today);
        Ember.run.next(this, function () {
          this.send('getStatistics', today.setHours(0, 0, 0, 0), today.setHours(23, 59, 59, 59));
        });

        // Récupérer la dernière position de l'agent (dans GIS)
        this.store.queryRecord('agent', { identification: model.get('identification') }).then(function (gisAgent) {
          if (gisAgent) {
            controller.set('isAgentPresent', true);
            controller.set('latitude', gisAgent.get('latitude'));
            controller.set('longitude', gisAgent.get('longitude'));
            controller.set('positionDate', gisAgent.get('positionDate'));
          }
        });
      }

      controller.set('editUser', true);
      controller.set('currentStatus', _dictionary.default.userStatuses[model.get('status')]);
      controller.set('selectedRole', userRoles.findBy('name', model.get('role')));
      controller.set('supervisors', this.store.query('user', { role: 'SUPERVISOR' }));
      controller.set('cities', this.get('currentUser.user.cities'));
      model.get('userCities').forEach(function (userCity) {
        if (userCity.city.name === _this.get('currentUser.city')) {
          controller.set('indexNumber', userCity.indexNumber);
        }
      });

      controller.set('isLoadingSave', false);
      controller.set('isLoadingInitUser', false);
      controller.set('isLoadingBlockUser', false);
      controller.set('isLoadingCloseUser', false);

      // régler les options de ChartJS
      this.set('controller.barOptions', {
        responsive: true,
        autoSkip: false,
        scales: {
          xAxes: [{
            barPercentage: 0.7
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function callback(value) {
                if (Math.floor(value) === value) {
                  return value;
                }
              }
            }
          }]
        }
      });
    },
    resetController: function resetController(controller) {
      controller.set('selectedRole', userRoles.findBy('name', this.get('model.role')));
    },


    actions: {
      getStatistics: function getStatistics(startDate, endDate) {
        var _this2 = this;

        startDate = (0, _moment.default)(startDate).toDate();
        endDate = (0, _moment.default)(endDate).toDate();
        var url = _environment.default.APP.host + '/control/api/statistics/controls?identification=' + this.get('controller.model.identification') + '&startDatetime=' + startDate.toISOString() + '&endDatetime=' + endDate.toISOString();
        this.get('ajax').request(url, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + this.get('session.session.authenticated.id_token'),
            City: this.get('currentUser.city')
          },
          contentType: 'application/json'
        }).then(function (data) {
          if (data) {
            var statisticsData = Ember.computed('data', function () {
              return {
                labels: data.mapBy('date').map(function (date) {
                  return (0, _moment.default)(date).format('DD/MM/YYYY');
                }),
                datasets: [{
                  label: 'Nombre de contrôles',
                  data: data.mapBy('nbrControl'),
                  backgroundColor: '#006666'
                }, {
                  label: 'Nombre de FPS',
                  data: data.mapBy('nbrFps'),
                  backgroundColor: '#ff9933'
                }]
              };
            });
            _this2.set('controller.statisticsData', statisticsData);
          }
        });
      },
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('users');
      },
      initUser: function initUser() {
        var _this3 = this;

        this.set('controller.isLoadingInitUser', true);
        var user = this.get('controller.model');
        user.rollbackAttributes();
        user.set('status', 'INITIALIZED');
        user.save().then(function () {
          _this3.get('notifications').success('L\'utilisateur a été initialisé avec succès');
          _this3.transitionTo('users');
        }).catch(function () {
          _this3.get('notifications').error('L\'utilisateur n\'a pas pu être initialisé');
        }).finally(function () {
          return _this3.set('controller.isLoadingInitUser', false);
        });
      },
      blockUser: function blockUser() {
        var _this4 = this;

        this.set('controller.isLoadingBlockUser', true);
        var user = this.get('controller.model');
        user.rollbackAttributes();
        user.set('status', 'BLOCKED');
        user.save().then(function () {
          _this4.get('notifications').success('L\'utilisateur a été bloqué avec succès');
          _this4.transitionTo('users');
        }).catch(function () {
          _this4.get('notifications').error('L\'utilisateur n\'a pas pu être bloqué');
        }).finally(function () {
          return _this4.set('controller.isLoadingBlockUser', false);
        });
      },
      closeUser: function closeUser() {
        var _this5 = this;

        this.set('controller.isLoadingCloseUser', true);
        var user = this.get('controller.model');
        user.rollbackAttributes();
        user.set('status', 'CLOSED');
        user.save().then(function () {
          _this5.get('notifications').success('L\'utilisateur a été fermé avec succès');
          _this5.transitionTo('users');
        }).catch(function () {
          _this5.get('notifications').error('L\'utilisateur n\'a pas pu être fermé');
        }).finally(function () {
          return _this5.set('controller.isLoadingCloseUser', false);
        });
      },
      save: function save() {
        var _this6 = this;

        this.set('controller.isLoadingSave', true);
        var user = this.get('controller.model');
        var alphanumericPattern = new RegExp('^[a-zA-Z0-9]+$');
        if (!_lodash.default.isEmpty(user.get('authorizedNumber')) & !alphanumericPattern.test(user.get('authorizedNumber'))) {
          this.get('notifications').error('Le numéro d\'assermentation doit être alphanumérique');
          this.set('controller.isLoadingSave', false);
        } else if (_lodash.default.isEmpty(user.get('cities'))) {
          this.get('notifications').error('Au moins une ville doit être sélectionnée');
          this.set('controller.isLoadingSave', false);
        } else {
          var supervisor = user.get('supervisor');
          // texte vide => undefined (évite des problèmes de contrainte de validation)
          ['phone', 'email', 'voIP', 'photo', 'authorizedNumber', 'humanResourceId', 'contractedWorkingHoursPerWeek'].forEach(function (field) {
            // les nombres sont considérés "vides" par Lodash...
            if (_lodash.default.isEmpty(user.get(field)) && !_lodash.default.isNumber(user.get(field))) {
              user.set(field, undefined);
            }
          });
          if (supervisor) {
            user.set('supervisor', supervisor);
          }
          user.save().then(function () {
            _this6.get('notifications').success('L\'utilisateur a été modifié avec succès');
            _this6.transitionTo('users');
          }).catch(function () {
            _this6.get('notifications').error('L\'utilisateur n\'a pas pu être modifié');
          }).finally(function () {
            return _this6.set('controller.isLoadingSave', false);
          });
        }
      },
      willTransition: function willTransition() {
        this.get('controller.model').rollbackAttributes();
      }
    }
  });
});