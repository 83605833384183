define('sags-front-bo/adapters/vehicle-brand', ['exports', 'sags-front-bo/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'control/api/public',
    pathForType: function pathForType() {
      return 'vehicleBrands';
    }
  });
});