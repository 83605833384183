define('sags-front-bo/controllers/users/index', ['exports', 'sags-front-bo/utils/dictionary'], function (exports, _dictionary) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    tableColumns: [{
      'key': 'identification',
      'displayName': 'Matricule',
      'customComponent': 'link-to-detail'
    }, {
      'key': 'firstName',
      'displayName': 'Prénom'
    }, {
      'key': 'lastName',
      'displayName': 'Nom'
    }, {
      'key': 'status',
      'displayName': 'Statut',
      transform: function transform(statusId) {
        return _dictionary.default.userStatuses[statusId];
      }
    }, {
      'key': 'role',
      'displayName': 'Rôle',
      transform: function transform(roleId) {
        return _dictionary.default.userRoles[roleId];
      }
    }],
    // filtres actifs sur la liste
    filters: {
      identification: {
        comparator: 'contains',
        value: ''
      },
      lastName: {
        comparator: 'contains',
        value: ''
      },
      firstName: {
        comparator: 'contains',
        value: ''
      },
      status: {
        comparator: 'equals',
        value: '*'
      },
      role: {
        comparator: 'equals',
        value: '*'
      },
      manager: {
        comparator: 'equals',
        value: '*'
      }
    },

    userRoles: _dictionary.default.convertToArray(_dictionary.default.sagsUserRoles, [{ key: '*', value: 'Tous' }]),

    userStatuses: _dictionary.default.convertToArray(_dictionary.default.userStatuses, [{ key: '*', value: 'Tous' }]),

    actionIcons: [{ icon: 'close', tooltip: 'Fermer' }, { icon: 'unlock', tooltip: 'Initialiser' }],

    ButtonVisibility: function ButtonVisibility(one, elementId) {
      one.set('isIcon0Visible_' + elementId, Ember.computed('status', function () {
        return one.get('status') !== 'CLOSED';
      }));

      one.set('isIcon1Visible_' + elementId, Ember.computed('status', function () {
        return one.get('status') === 'CLOSED';
      }));
    },


    linkComponentConfig: {
      targetRoute: 'users.user.edit'
    }
  });
});