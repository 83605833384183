define('sags-front-bo/helpers/control-access', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.controlAccess = controlAccess;


  // Droits d'accès aux différentes routes. L'ordre importe peu, c'est la règle la plus précise qui sera utilisée
  // (par exemple, pour rapo.edition.index, ce sera la règle 'rapo.edition' qui sera utilisée, et non 'rapo'.)
  // Les rôles listés ici sont ceux qui ont le droit d'accéder à la page.
  // Toute route non listée ici est refusée pour tous les rôles.
  var authorizedProfiles = {
    // 'tous les rôles SAGS': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'AGENT_BO', 'AGENT', 'MANAGER', 'RAPO_MANAGER', 'CITY']

    // tous les rôles ont accès à la page racine, même ceux qui ne sont pas censés être utilisés, pour éviter une boucle infinie
    'index': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'AGENT_BO', 'AGENT', 'MANAGER', 'RAPO_MANAGER', 'CITY', 'ADMIN', 'TECHNICAL', 'LAPI', 'ANONYMOUS', 'AGENT_VAO', 'CITY_CCSP', 'CITY_VISU'],

    // Gestion des utilisateurs
    'users': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR'],
    // Gestion des équipes
    'teams': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'RAPO_MANAGER'],
    // Planning
    'teams.planning': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'RAPO_MANAGER'],
    'teams.new-planning': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'RAPO_MANAGER'],
    // Tournées
    'users.tour': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'CITY'],

    // Suivi de l'activité
    'controls.activities': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'CITY_VISU'],
    // Liste des contrôles
    'controls': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'AGENT_BO', 'AGENT', 'MANAGER', 'RAPO_MANAGER', 'CITY', 'CITY_VISU'],
    // Traitement VAO
    'controls.vao': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'AGENT_BO', 'AGENT_VAO'],
    // Liste des FPS
    'fps': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'AGENT_BO', 'AGENT', 'MANAGER', 'RAPO_MANAGER', 'CITY', 'CITY_VISU'],
    // Annulation FPS
    'controls.annulation': ['ADMINISTRATOR'],
    // Liste des observations
    'observations': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'RAPO_MANAGER', 'CITY_VISU'],
    // Plaques d'immatriculation
    'controls.vehicle-plates': ['ADMINISTRATOR', 'SUPERVISOR', 'CITY_VISU'],
    // Administration de la liste sous surveillance
    'black-list.create': ['ADMINISTRATOR'],
    'black-list.delete': ['ADMINISTRATOR'],
    'black-list.list': ['ADMINISTRATOR'],
    'black-list.purge': ['ADMINISTRATOR'],
    'controls.white-list-edition': ['ADMINISTRATOR'],
    'white-list-bis.liste_sous_surveillance': ['ADMINISTRATOR'],
    'white-list-bis.mode_surveillance': ['ADMINISTRATOR'],
    // Administration du mode surveillance 2
    'white-list-bis.nb-plate-characters': ['ADMINISTRATOR'],
    'white-list-bis.control-mode': ['ADMINISTRATOR'],
    'white-list-bis.purge': ['ADMINISTRATOR'],
    'white-list-bis.delete': ['ADMINISTRATOR'],
    'white-list-bis.create': ['ADMINISTRATOR'],
    'white-list-bis.list': ['ADMINISTRATOR'],
    'white-list-bis.auto-mode': ['ADMINISTRATOR'],

    // Saisie d'un RAPO
    'rapo.create': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'AGENT_BO', 'RAPO_MANAGER'],
    'rapo.synchro': ['ADMINISTRATOR', 'AGENT_BO', 'RAPO_MANAGER'],
    'rapo.create-reset': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR', 'AGENT_BO', 'RAPO_MANAGER'],
    // Gestion des RAPO
    'rapo': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'AGENT_BO', 'RAPO_MANAGER', 'CITY', 'CITY_VISU'],
    // Instruction du RAPO le plus ancien
    'rapo.instruction': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'AGENT_BO', 'RAPO_MANAGER'],
    // Editique
    'rapo.edition': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'AGENT_BO', 'RAPO_MANAGER', 'CITY'],
    // Rattrapage d'un RAPO
    'rapo.rattrap': ['ADMINISTRATOR', 'RAPO_MANAGER'],

    // Recherche d'un recours CCSP
    'ccsp-claim': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'AGENT_BO', 'RAPO_MANAGER', 'CITY', 'CITY_CCSP', 'CITY_VISU'],
    // Création d'un recours CCSP
    'ccsp-claim.create': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'AGENT_BO', 'RAPO_MANAGER'],
    // Détail d'un recours CCSP
    'ccsp-claim.detail': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'AGENT_BO', 'RAPO_MANAGER', 'CITY', 'CITY_CCSP', 'CITY_VISU'],

    // Gestion des terminaux
    'devices': ['ADMINISTRATOR'],
    // Gestion des véhicules
    'cars': ['ADMINISTRATOR'],

    // Gestion des données SIG + Définition des priorités
    'gis': ['ADMINISTRATOR'],
    // Définition des secteurs
    'sectors.create': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR'],
    // Affectation des secteurs
    'sectors.assignment': ['ADMINISTRATOR', 'TERRITORY_MANAGER', 'SUPERVISOR'],
    // Modification des règles de passage
    'rules': ['ADMINISTRATOR', 'CITY_VISU']
  };

  //Gestion des droits en fonction de la ville
  var authorizedCity = {
    'white-list-bis.control-mode': ['MARSEILLE'],
    'black-list.purge': ['MARSEILLE'],
    'white-list-bis.mode_surveillance': ['MARSEILLE'],
    'rapo.synchro': ['ANNEMASSE', 'CHERBOURG', 'MARSEILLE', 'MONTMORENCY', 'MORZINE', 'TASSIN']
  };

  // Ce helper renvoie true si l'usager a le droit d'accéder à la route, false sinon.
  // params[0] = la route
  // params[1] = currentUser
  // Ce helper est utilisé comme base pour le AccessControllerMixin.
  function controlAccess(params) {
    // en test, pas de contrôle d'accès (sauf que c'est le contrôle d'accès qui est testé) pour simplifier
    if (Ember.testing && !localStorage.enableAccessControlDuringTests) {
      return true;
    }

    var routeToCheck = params[0];
    var currentUser = params[1];

    var userRole = currentUser.get('user.role');
    var currentCity = currentUser.get('city').toUpperCase();

    // on récupère le nom des routes qui commencent comme la route à vérifier, et on la trie
    // de sorte à avoir la plus longue en premier
    // (s'il y a une règle sur 'rapo' et sur 'rapo.create' on veut considérer celle sur 'rapo.create')
    var matchingRoutes = Object.keys(authorizedProfiles).filter(function (routeName) {
      return routeToCheck.startsWith(routeName);
    }).sort(function (route1, route2) {
      return route2.length - route1.length;
    });

    var matchingCityRoutes = Object.keys(authorizedCity).filter(function (routeName) {
      return routeToCheck.startsWith(routeName);
    }).sort(function (route1, route2) {
      return route2.length - route1.length;
    });

    if (_lodash.default.isEmpty(matchingRoutes) || !(!authorizedCity[matchingCityRoutes[0]] || authorizedCity[matchingCityRoutes[0]] && authorizedCity[matchingCityRoutes[0]].includes(currentCity)) || !authorizedProfiles[matchingRoutes[0]].includes(userRole)) {
      // Cette route a des règles, et l'utilisateur actuel n'a pas le droit d'y accéder.
      // rediriger vers la page d'accueil
      return false;
    }

    return true;
  }

  exports.default = Ember.Helper.helper(controlAccess);
});